import {put, takeEvery, select} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import get from 'get-value';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';
import {ASE_TICKET_RESET} from '../../actions/ase/actionAseTicket';

export function* handleTicketError(action) {
    const stateCorrelationId = get(yield select(state => state.aseTicket), `correlationId`, {default: undefined});
    const {correlationId} = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('/ase'));
    yield put({type: ASE_TICKET_RESET});
}

function* aseTicketSaga() {
    yield takeEvery(aseWebsocketCommands.ASE_TICKET_UPDATE_ERROR, handleTicketError);
    yield takeEvery(aseWebsocketCommands.ASE_TICKET_CREATE_ERROR, handleTicketError);
    yield takeEvery(aseWebsocketCommands.ASE_TICKET_SEND_ERROR, handleTicketError);
}

export default aseTicketSaga;
