import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    REPAIR_METHOD: {
        id: 'ase.issueOrigin.option.repairMethod',
        defaultMessage: 'Repair Method'
    },
    LABOR_TIME: {
        id: 'ase.issueOrigin.option.laborTime',
        defaultMessage: 'Labor Time'
    }
});

export default messages;
