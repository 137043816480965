import {
    ADMIN_POPUP_FETCH_PENDING,
    ADMIN_POPUP_SAVE_PENDING,
} from '../../actions/admin/actionAdminPopUp';
import get from 'get-value';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const adminPopUp = (state = {
    isLoading: false,
    popUp: {},
}, action) => {
    switch (action.type) {
        case ADMIN_POPUP_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true, popUp: {}});
        }
        case ADMIN_POPUP_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true, popUp: {}});
        }
        case aseWebsocketCommands.ASE_POPUP_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                popUp: get(action, 'payload.popup', {default: {}}),
                correlationId
            });
        }
        case aseWebsocketCommands.ASE_POPUP_ERROR:
        case aseWebsocketCommands.ASE_POPUP_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, popUp: {}, correlationId});
        }
        default: {
            return state;
        }
    }
};
