import React from 'react';
import {withRouter} from 'react-router-dom';
import {domains} from '../../constants/Utils';
import {
    ADMINISTRATION_PATH,
    ASE_PATH,
    GENERICS_PATH,
} from '../../routes/paths';

export const Domain = withRouter(({component: Component, ...props}) => {
    let domain;
    if (props.location.pathname.startsWith(ASE_PATH) || props.location.pathname.startsWith(`${ADMINISTRATION_PATH}${ASE_PATH}`)) {
        domain = domains.ASE;
    }
    return <Component domain={domain} {...props}/>;
});
