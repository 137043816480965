import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const adminEscalationSchema = (state = {
    correlationId: null,
    isEscalationSchemaLoading: false,
    isEscalationSchemaCreating: false,
    escalationSchemaSubmittedAndShouldRedirect: false,
    initWithOldData: false,
    created: false,
    EscalationSchema: {},
    EscalationSchemaOptions: [],
    availableName: true,
}, action) => {
    switch (action.type) {
        case aseWebsocketCommands.ASE_ESCALATION_SCHEMA_REDIRECT_RESET: {
            return Object.assign({}, state, {
                escalationSchemaSubmittedAndShouldRedirect: false,
            });
        }
        case aseWebsocketCommands.ASE_ESCALATION_SCHEMA_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isEscalationSchemaLoading: false,
                isEscalationSchemaCreating: false,
                escalationSchemaSubmittedAndShouldRedirect: false,
                initWithOldData: false,
                created: false,
                EscalationSchema: {},
                availableName: true
            });
        }
        case aseWebsocketCommands.ASE_ESCALATION_SCHEMA_UPDATE_PENDING:
        case aseWebsocketCommands.ASE_ESCALATION_SCHEMA_CREATE_PENDING: {
            return Object.assign({}, state, {
                isEscalationSchemaCreating: true,
                isEscalationSchemaLoading: true,
                escalationSchemaSubmittedAndShouldRedirect: true,
            });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_ACCEPTED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isEscalationSchemaCreating: false,
                created: true,
                isEscalationSchemaLoading: true,
                correlationId
            });
        }

        case aseWebsocketCommands.ASE_ESCALATION_SCHEMA_SEND: {
            const { correlationId, escalationSchema, error } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isEscalationSchemaLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isEscalationSchemaLoading: false,
                correlationId,
                escalationSchema
            });
        }

        case aseWebsocketCommands.ASE_ESCALATION_SCHEMA_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isEscalationSchemaCreating: false,
                isEscalationSchemaLoading: false,
                correlationId,
            });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isEscalationSchemaCreating: false,
                created: false,
                isEscalationSchemaLoading: false,
                escalationSchemaSubmittedAndShouldRedirect: false,
                initWithOldData: true,
                correlationId,
            });
        }

        case aseWebsocketCommands.ASE_ESCALATION_SCHEMA_DETAIL_FETCH_PENDING: {
            return Object.assign({}, state, { isEscalationSchemaLoading: true });
        }

        case aseWebsocketCommands.ASE_ADMIN_SOLUTION_GROUPS_SEND: {
            const { solutionGroups } = action.payload;
            return Object.assign({}, state, { EscalationSchemaOptions: solutionGroups });
        }

        case aseWebsocketCommands.ASE_ESCALATION_SCHEMA_SAVE_NEW_ERROR:
        case aseWebsocketCommands.ASE_ESCALATION_SCHEMA_SAVE_UPDATE_ERROR: {
            return Object.assign({}, state, { isEscalationSchemaLoading: false });
        }

        default:
            return state;
    }
};
