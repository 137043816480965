import {domains} from '../../constants/Utils';
import {validateDealerRoles} from '../../api/validation/role';
import {errorAlert} from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';


export const ADMIN_DEFAULT_DEALER_ROLE_FETCH_PENDING = 'ADMIN_DEFAULT_DEALER_ROLE_FETCH_PENDING';
export const ADMIN_DEFAULT_DEALER_ROLE_SELECT_GROUP = 'ADMIN_DEFAULT_DEALER_ROLE_SELECT_GROUP';
export const SELECTED_DEFAULT_DEALER_ROLES = 'SELECTED_DEFAULT_DEALER_ROLES';
export const DEFAULT_DEALER_ROLES_CHANGED = 'DEFAULT_DEALER_ROLES_CHANGED';
export const INITIAL_DEFAULT_DEALER_ROLES = 'INITIAL_DEFAULT_DEALER_ROLES';
export const DEFAULT_DEALER_ROLES_SAVE_PENDING = 'DEFAULT_DEALER_ROLES_SAVE_PENDING';
export const DEFAULT_DEALER_ROLES_FETCH_SUCCESS = 'DEFAULT_DEALER_ROLES_FETCH_SUCCESS';
export const DEFAULT_ROLES_ERROR = 'DEFAULT_ROLES_ERROR';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_DEFAULT_DEALER_ROLE_SELECT_GROUP,
        payload: countryGroup
    });


export const saveDefaultDealerRoleData = (group, domain, dealerData) => dispatch => {
    const {error} = validateDealerRoles(domain, dealerData);

    if (error) {
        dispatch({type: DEFAULT_ROLES_ERROR});
        dispatch(errorAlert(alertMessages.DEFAULT_DEALER_ROLES_SEND_ERROR, [error.toString()]));
    } else {
        switch (domain) {
            case domains.ASE: {
                return dispatch({
                    type: DEFAULT_DEALER_ROLES_SAVE_PENDING,
                    aseWebsocket: true,
                    command: aseWebsocketCommands.ASE_DEFAULT_DEALER_ROLE_SET,
                    payload: {group: group, role: {dealer: {formsPermission: dealerData}}}
                });
            }
        }
    }
};

export const fetchDefaultDealerRoleData = (domain, group) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: ADMIN_DEFAULT_DEALER_ROLE_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_DEFAULT_DEALER_ROLE_GET,
                payload: {group}
            });
        }
    }
};

export const setSelectedDefaultDealerRoles = formTypeOptions => ({
    type: SELECTED_DEFAULT_DEALER_ROLES,
    payload: formTypeOptions
});

export const setDefaultDealerRolesChanged = formTypesChanged => ({
    type: DEFAULT_DEALER_ROLES_CHANGED,
    payload: formTypesChanged
});

export const setInitialDefaultDealerRoles = formTypeOptions => ({
    type: INITIAL_DEFAULT_DEALER_ROLES,
    payload: formTypeOptions
});
