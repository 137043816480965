import {
    IPN_VALIDATION_REGEX,
    COMMON_INPUT_VALIDATION_REGEX,
    FILE_MAX_SIZE,
    NOT_SELECTED_OPTION,
    DATE_VALIDATION_REGEX,
    DIGIT_STRING_VALIDATION_REGEX,
    LIC_PROD_FIELD_MAX_LENGTH,
    LIC_ORD_FIELD_MAX_LENGTH,
    LIC_OAN_FIELD_MAX_LENGTH,
    LIC_LIG_FIELD_MAX_LENGTH,
    LIC_IAN_FIELD_MAX_LENGTH,
    VIN_FIELD_LENGTH,
    BIN_FIELD_LENGTH,
    ALPHANUMERIC_VALIDATION_REGEX,
} from '../../constants/validationConstants';
import get from 'get-value';

export const required = (value, allValues) => {
    return (value || typeof value === 'number' || get(allValues, 'omitValidation', false) ? undefined : 'REQUIRED');
}

export const validPartNoLength = value =>
    value && value.length !== 10 ? 'MUST_BE_10_CHARS' : undefined;

export const validLicProdLength = value =>
    value && value.length !== LIC_PROD_FIELD_MAX_LENGTH ? 'INVALID_LENGTH' : undefined;

export const validLicOrdLength = value =>
    value && value.length !== LIC_ORD_FIELD_MAX_LENGTH ? 'INVALID_LENGTH' : undefined;

export const validLicOAnLength = value =>
    value && value.length !== LIC_OAN_FIELD_MAX_LENGTH ? 'INVALID_LENGTH' : undefined;

export const validLicLigLength = value =>
    value && value.length !== LIC_LIG_FIELD_MAX_LENGTH ? 'INVALID_LENGTH' : undefined;

export const validLicIAnLength = value =>
    value && value.length !== LIC_IAN_FIELD_MAX_LENGTH ? 'INVALID_LENGTH' : undefined;

export const validVinLength = value =>
    value && value.length !== VIN_FIELD_LENGTH ? 'MUST_BE_17_CHARS' : undefined;

export const validBinLength = value =>
    value && value.length !== BIN_FIELD_LENGTH ? 'MUST_BE_17_CHARS' : undefined;

export const validVehicleTypeLength = value =>
    value && value.length !== 4 ? 'MUST_BE_4_CHARS' : undefined;

export const validDealerNumberShortLength = value =>
    value && value.length !== 5 ? 'MUST_BE_5_CHARS' : undefined;

export const validDicLength = value =>
    value && value.length !== 6 ? 'MUST_BE_6_CHARS' : undefined;

export const validSevenCharLength = value =>
    value && value.length !== 7 ? 'MUST_BE_7_CHARS' : undefined;

export const validSixToTenCharLength = value =>
    value && value.length < 6 ? 'MUST_BE_6_TO_10_CHARS' : undefined;

export const validSixCharLength = value =>
    value && value.length !== 6 ? 'MUST_BE_6_CHARS' : undefined;

export const validFiveCharLength = value =>
    value && value.length !== 5 ? 'MUST_BE_5_CHARS' : undefined;

export const validFourCharLength = value =>
    value && value.length !== 4 ? 'MUST_BE_4_CHARS' : undefined;

export const validThreeCharLength = value =>
    value && value.length !== 3 ? 'MUST_BE_3_CHARS' : undefined;

export const validTwoCharLength = value =>
    value && value.length !== 2 ? 'MUST_BE_2_CHARS' : undefined;

export const validBetweenFiveAndEightCharLength = value =>
    value && (value.length < 5 || value.length > 8) ? 'MUST_BE_5_TO_8_CHARS' : undefined;

export const selectRequired = value =>
    (!value || value === NOT_SELECTED_OPTION ? 'REQUIRED' : undefined);

export const positiveNumber = value =>
    (value !== null && value !== undefined && value.length && ((value && isNaN(Number(value))) || value < 1) ? 'POSITIVE_NUMBER' : undefined);

export const positiveNumberWithZero = value =>
    (value !== null && ((value && isNaN(Number(value))) || value < 0) ? 'POSITIVE_NUMBER' : undefined);

export const minLength = min =>
    value => value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const validIPN = value =>
    (value && typeof value === 'string' && IPN_VALIDATION_REGEX.test(value)) ? undefined : 'INVALID_IPN';

export const validDate = value =>
    (value && !DATE_VALIDATION_REGEX.test(value)) ? 'INVALID_DATE' : undefined;

export const validDigitString = value =>
    (value && !DIGIT_STRING_VALIDATION_REGEX.test(value)) ? 'DIGITS_ONLY' : undefined;

export const validInput = (value = '') => ((!COMMON_INPUT_VALIDATION_REGEX.test(value)) && value.length !== 0) ? 'INVALID_INPUT' : undefined;

export const validAlphanumericInput = (value = '') => ((!ALPHANUMERIC_VALIDATION_REGEX.test(value)) && value.length !== 0) ? 'ALPHANUMERIC' : undefined;

export const validTicketFiles = fileList => {
    if (fileList) {
        if (fileList.length > 20)
            return 'MAXIMUM_FILES';
        if (fileList.length !== 0) {
            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].size > FILE_MAX_SIZE) {
                    return 'MAXIMUM_FILE_SIZE';
                }
            }
        }
    }
};
export const validMandatoryTicketFiles = fileList => {
    if (fileList) {
        if (fileList.length > 20)
            return 'MAXIMUM_FILES';
        if (fileList.length !== 0) {
            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].size > FILE_MAX_SIZE) {
                    return 'MAXIMUM_FILE_SIZE';
                }
            }
        }
    } else {
        return 'REQUIRED';
    }
};

export const validateCreateDic = (vinNumber, orders, destinationCode, dealerNumber, dealerName, group, repairOrderNo, carLocation, regNumber, isMultisourcingCountry) => {
    const warnings = [];
    if (vinNumber) {
        if (vinNumber.length < 17) {
            warnings.push('VIN_INVALID_LENGTH')
        }
    } else {
        warnings.push('VIN_REQUIRED');
    }
    if (!destinationCode) {
        warnings.push('DESTINATION_CODE_REQUIRED');
    }
    if (!group && group !== 'FR') {
        warnings.push('GROUP_REQUIRED');
    }
    if (!dealerName && group !== 'FR') {
        warnings.push('DEALER_NAME_REQUIRED');
    }
    if (!dealerNumber && group !== 'FR') {
        warnings.push('DEALER_NUMBER_REQUIRED');
    }
    if (!repairOrderNo && group === 'FR') {
        warnings.push('REPAIR_ORDER_NUMBER_REQUIRED');
    }
    if (!carLocation && group === 'FR') {
        warnings.push('CAR_LOCATION_REQUIRED');
    }
    if (!regNumber && group !== 'FR') {
        warnings.push('REGISTRATION_NUMBER_REQUIRED');
    }
    if (!orders || orders.length === 0) {
        warnings.push('ORDERS_REQUIRED');
    } else {
        orders.forEach(order => {
                if (!isMultisourcingCountry) {
                    if (!order.mprLine) {
                        warnings.push('EMPTY_MPR');
                    } else if (order.mprLine.length !== 14 || !DIGIT_STRING_VALIDATION_REGEX.test(order.mprLine)) {
                        warnings.push('INVALID_MPR');
                    }
                } else {
                    if (order.mprLine && (order.mprLine.length !== 14 || !DIGIT_STRING_VALIDATION_REGEX.test(order.mprLine))) {
                        warnings.push('INVALID_MPR');
                    }
                }
            }
        )
    }
    return warnings;
}

export const validateUpdateDic = (vinNumber, orders, dicNumber, isMultisourcingCountry) => {
    const warnings = [];
    if (vinNumber) {
        if (vinNumber.length < 17) {
            warnings.push('VIN_INVALID_LENGTH')
        }
    } else {
        warnings.push('VIN_REQUIRED');
    }
    if (!dicNumber) {
        warnings.push('DIC_NUMBER_REQUIRED');
    }
    if (!orders || orders.length === 0) {
        warnings.push('ORDERS_REQUIRED');
    } else {
        orders.forEach(order => {
                if (!isMultisourcingCountry) {
                    if (!order.mprLine) {
                        warnings.push('EMPTY_MPR');
                    } else if (order.mprLine.length !== 14 || !DIGIT_STRING_VALIDATION_REGEX.test(order.mprLine)) {
                        warnings.push('INVALID_MPR');
                    }
                } else {
                    if (order.mprLine && (order.mprLine.length !== 14 || !DIGIT_STRING_VALIDATION_REGEX.test(order.mprLine))) {
                        warnings.push('INVALID_MPR');
                    }
                }
            }
        )
    }
    return warnings;
}



