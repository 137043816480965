import React from 'react';
import get from 'get-value';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {
    FORM_FIELD_DOC_SOLUTION_GROUP,
    FORM_FIELD_EDITOR_IPN,
    FORM_FIELD_FORMTYPE,
    FORM_FIELD_FROM,
    FORM_FIELD_PFX,
    FORM_FIELD_SFX,
    FORM_FIELD_STATUS,
    FORM_FIELD_TO, FORM_LABEL_EDITOR_IPN,
    FORM_LABEL_FORM_TYPE,
    FORM_LABEL_FROM,
    FORM_LABEL_SOLUTION_GROUP,
    FORM_LABEL_STATUS,
    FORM_LABEL_TICKET_NUMBER,
    FORM_LABEL_TO,
    MY_CONST
} from '../../../../constants/formConstants';
import InputSelectFieldWithValidation from '../../../common/InputSelectFieldWithValidation';
import DatePickerComponent from '../../../common/datePicker/DatePickerComponent';
import InputFieldTicketNumber from '../../../common/InputFieldTicketNumber';
import {Field} from 'redux-form';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {domains, getCurrentFormTypes, getFormStates} from '../../../../constants/Utils';
import fulltextSearchMessages from '../../../../intl/afterSales/fulltextSearchMessages';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {handleFetchSearchClientIdsError} from '../../../../actions/admin/actionAdminClientIds';
import {injectIntl} from 'react-intl';
import formStatesMessages from '../../../../intl/common/formStatesMessages';
import {moduleRoles} from '../../../../utils/roles';
import {uniqBy} from 'lodash';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const TicketSection = ({lastEditors, roles}) => {

    const getSolutionGroups = () => {
        let sgOptions = [];

        if (moduleRoles.isSGEditor(roles, domains.ASE) && (get(roles, 'ASE.sgEditor.sgPermission.length') > 0)){
            const sgEditorOptions = (get(roles, 'ASE.sgEditor.sgPermission') || []).map(sg => ({
                uuid: sg.id,
                presetName: sg.name
            }))
            sgOptions = sgOptions.concat(sgEditorOptions);
        }
        if (moduleRoles.isSGAdmin(roles, domains.ASE) && (get(roles, 'ASE.sgAdmin.sgPermission.length') > 0)){
            const sgAdminOptions = (get(roles, 'ASE.sgAdmin.sgPermission') || []).map(sg => ({
                uuid: sg.id,
                presetName: sg.name
            }))
            sgOptions = sgOptions.concat(sgAdminOptions);
        }
        return uniqBy(sgOptions, (e => (e.uuid)));
    }

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...fulltextSearchMessages.TICKET_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputFieldTicketNumber label={fulltextSearchMessages[FORM_LABEL_TICKET_NUMBER]}
                                                fields={[FORM_FIELD_PFX, FORM_FIELD_SFX]}
                                                validation/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_FORM_TYPE]}
                                                        field={FORM_FIELD_FORMTYPE}
                                                        intlMessages={formTypeMessages}
                                                        options={getCurrentFormTypes(domains.ASE)}/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_SOLUTION_GROUP]}
                                                        field={FORM_FIELD_DOC_SOLUTION_GROUP}
                                                        customOptionsMsg={getSolutionGroups()}/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_STATUS]}
                                                        field={FORM_FIELD_STATUS}
                                                        options={getFormStates(domains.ASE)}
                                                        intlMessages={formStatesMessages}
                                                        />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={fulltextSearchMessages[FORM_LABEL_FROM]}
                               name={FORM_FIELD_FROM}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={fulltextSearchMessages[FORM_LABEL_TO]}
                               name={FORM_FIELD_TO}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_EDITOR_IPN]}
                                                        field={FORM_FIELD_EDITOR_IPN}
                                                        options={lastEditors}/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state) => ({
    roles: state.profile.userDetail.roles,
    lastEditors: state.aseFullTextSearchPage.lastEditors,
});

export default connect(mapStateToProps, {handleFetchSearchClientIdsError})(injectIntl(TicketSection));
