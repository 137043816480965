import {
    ASE_POPUP_FETCH_COMMAND,
    ASE_POPUP_MODAL_CONFIRM,
    ASE_POPUP_MODAL_OPEN
} from '../../actions/ase/actionAsePopUpModal';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';
import get from 'get-value';

export const asePopUpModal = (state = {
    isOpen: false,
    isConfirm: false,
    isLoading: false,
    popUp: {},
}, action) => {
    switch (action.type) {
        case ASE_POPUP_FETCH_COMMAND: {
            return Object.assign({}, state, {isLoading: true});
        }
        case aseWebsocketCommands.ASE_POPUP_SEND: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                popUp: get(action, 'payload.popup', {default: {}}),
                correlationId
            });
        }
        case aseWebsocketCommands.ASE_POPUP_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, popUp: {}, correlationId});
        }
        case ASE_POPUP_MODAL_OPEN: {
            return Object.assign({}, state, {isOpen: true});
        }
        case ASE_POPUP_MODAL_CONFIRM: {
            return Object.assign({}, state, {isOpen: false, isConfirm: true});
        }
        default:
            return state;
    }
};

