import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    ADMINISTRATION_ASE_DEALER_NOTIFICATION_PATH,
    ADMINISTRATION_ASE_MAIN_PAGE_PATH,
    ADMINISTRATION_ASE_RECURRENT_PROBLEM_PATH,
    ADMINISTRATION_ASE_WORKING_HOUR_PATH,
    ADMINISTRATION_ASE_TICKET_SETTING_PATH,
    ADMINISTRATION_ASE_ADMIN_PATH,
    ADMINISTRATION_ASE_DEALER_PATH,
    ADMINISTRATION_ASE_EDITOR_PATH,
    ADMINISTRATION_ASE_DEFAULT_COUNTRY_ROLE_PATH,
    ADMINISTRATION_ASE_GROUP_NEW_PATH,
    ADMINISTRATION_ASE_SOLUTION_GROUP_USER_PATH,
    ADMINISTRATION_ASE_ESCALATION_SCHEMA_NEW_PATH,
    ADMINISTRATION_ASE_ESCALATION_SCHEMA_LIST_PATH,
    ADMINISTRATION_ASE_ANSWERING_SCHEMA_NEW_PATH,
    ADMINISTRATION_ASE_ANSWERING_SCHEMA_LIST_PATH,
    ADMINISTRATION_ASE_SOLUTION_GROUP_LIST_PATH, ADMINISTRATION_CLIENT_ID_PATH
} from '../../../../routes/paths';
import {moduleRoles} from '../../../../utils/roles';
import {domains} from '../../../../constants/Utils';
import menuMessages from '../../../../intl/layout/menuMessages';

export const getAdministrationMenuAdminHtl = roles => {
    const isAdminOf = (domain) => moduleRoles.isAdmin(roles, domain);
    const isPowerUserOf = (domain) => moduleRoles.isPowerUser(roles, domain);
    const isSGAdminOf = (domain) => moduleRoles.isSGAdmin(roles, domain);

    const ASE = domains.ASE;

    const result = [];
    if (isAdminOf(ASE) || isPowerUserOf(ASE) || isSGAdminOf(ASE)) {
        let content = [];
        if (isAdminOf(ASE)) {
            content = [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_ASE_NOTIFICATION}/>,
                    to: ADMINISTRATION_ASE_DEALER_NOTIFICATION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_ASE_MAIN_PAGE_ALERTS}/>,
                    to: ADMINISTRATION_ASE_MAIN_PAGE_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_ASE_RECURRENT_PROBLEMS}/>,
                    to: ADMINISTRATION_ASE_RECURRENT_PROBLEM_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_ASE_WORKING_HOURS}/>,
                    to: ADMINISTRATION_ASE_WORKING_HOUR_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_ASE_TICKET_SETTING}/>,
                    to: ADMINISTRATION_ASE_TICKET_SETTING_PATH
                }
            ];
        }
        const authorizationManagementAseSubmenu = buildAuthorizationManagementAseSubmenu(roles);
        if (authorizationManagementAseSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_ASE_AUTH_MANAGEMENT}/>,
                    content: authorizationManagementAseSubmenu
                }
            );
        }
        const solutionGroupManagementAseSubmenu = buildSolutionGroupManagementAseSubmenu(roles);
        if (solutionGroupManagementAseSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_ASE_MULTI_LEVEL_ESCALATION_SCHEMA}/>,
                    content: solutionGroupManagementAseSubmenu
                }
            );
        }
        result.push({
            label: <FormattedMessage {...menuMessages.ADMIN_ASE}/>,
            content
        });
    }

    if (isAdminOf(ASE)) {
        result.push({
            label: <FormattedMessage {...menuMessages.ADMIN_COMMON_SETTING}/>,
            content: [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_COMMON_SETTING_CLIENT_ID}/>,
                    to: ADMINISTRATION_CLIENT_ID_PATH
                },
            ]
        });
    }

    return result;
};

const buildAuthorizationManagementAseSubmenu = roles => {
    const result = [];

    if (moduleRoles.isPowerUser(roles, domains.ASE)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_AUTH_MANAGEMENT_ADMIN}/>,
                to: ADMINISTRATION_ASE_ADMIN_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles, domains.ASE)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_AUTH_MANAGEMENT_DEALER}/>,
                to: ADMINISTRATION_ASE_DEALER_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_AUTH_MANAGEMENT_EDITOR}/>,
                to: ADMINISTRATION_ASE_EDITOR_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_DEFAULT_COUNTRY_ROLE}/>,
                to: ADMINISTRATION_ASE_DEFAULT_COUNTRY_ROLE_PATH
            }
        );
    }

    return result;
};

const buildSolutionGroupManagementAseSubmenu = roles => {
    const result = [];

    const aseDomain = domains.ASE;

    if (moduleRoles.isPowerUser(roles, aseDomain)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_SOLUTION_GROUP_NEW}/>,
                to: ADMINISTRATION_ASE_GROUP_NEW_PATH
            }
        );
    }
    if (moduleRoles.isPowerUser(roles, aseDomain) || moduleRoles.isSGAdmin(roles, aseDomain)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_SOLUTION_GROUP_LIST}/>,
                to: ADMINISTRATION_ASE_SOLUTION_GROUP_LIST_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_SOLUTION_GROUP_USER_MANAGEMENT}/>,
                to: ADMINISTRATION_ASE_SOLUTION_GROUP_USER_PATH
            }
        );
    }
    if (moduleRoles.isPowerUser(roles, aseDomain)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_ESCALATION_SCHEMA_NEW}/>,
                to: ADMINISTRATION_ASE_ESCALATION_SCHEMA_NEW_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_ESCALATION_SCHEMA_LIST}/>,
                to: ADMINISTRATION_ASE_ESCALATION_SCHEMA_LIST_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_ANSWERING_SCHEMA_NEW}/>,
                to: ADMINISTRATION_ASE_ANSWERING_SCHEMA_NEW_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_ASE_ANSWERING_SCHEMA_LIST}/>,
                to: ADMINISTRATION_ASE_ANSWERING_SCHEMA_LIST_PATH
            }
        );

    }

    return result;
};


