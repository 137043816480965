import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import moment from 'moment';
import Loader from '../../Loader';
import {
    ASE_EXPORT_FORM_FIELD_CHARSET,
    ASE_EXPORT_FORM_FIELD_TYPE,
    XLS
} from '../../../constants/exportConstant';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {
    createAseExportInternal,
    downloadExportInternal,
    fetchAseExportInternal
} from '../../../actions/ase/actionAseInternalTicketExport';
import InternalTicketExportAseForm from './InternalTicketExportAseForm';

class InternalTicketExportAsePage extends Component {
    componentDidMount() {
        this.props.fetchAseExportInternal();
    }

    handleSubmit = (values) => {
        const temp = JSON.parse(JSON.stringify((({isAssignedTo, hasBeenAssignedTo, requestType, status, engineType, gearboxType, nodId, repairMethod, rootCause, docUpdateNeeded, vehicleFamily}) => {
            return ({
                isAssignedTo,
                hasBeenAssignedTo,
                requestType,
                status,
                engineType,
                gearboxType,
                nodId,
                repairMethod,
                rootCause,
                docUpdateNeeded,
                vehicleFamily: vehicleFamily ? vehicleFamily.trim() : undefined,
            });
        })(values)));

        const exportFilter = {};
        exportFilter.filter = [];
        for (let key in temp) {
            if (temp.hasOwnProperty(key)) {
                if (key === 'lastEditor') {
                    temp[key].split(' ').forEach(element => exportFilter.filter.push({id: key, value: element}));

                } else {
                    exportFilter.filter.push({id: key, value: temp[key]})
                }
            }
        }

        if (values.from) {
            exportFilter.from = moment(values.from).add(12, 'hours').toISOString().substr(0, 10);
        }
        if (values.to) {
            exportFilter.to = moment(values.to).add(12, 'hours').toISOString().substr(0, 10);
        }
        exportFilter.type = values.type;
        if (!exportFilter.type) {
            exportFilter.type = XLS;
        }
        exportFilter.charset = values.charset;
        this.props.createAseExportInternal(exportFilter);
    };

    downloadExport = () => {
        this.props.downloadExportInternal(this.props.exportRecord);
    };

    render() {
        const {isLoadingExport, charSets} = this.props;

        if ( isLoadingExport) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage id="ase.internal.export.header" defaultMessage="Export"/>}/>
                <InternalTicketExportAseForm onSubmit={this.handleSubmit}
                               form={'internalTicketAseExportForm'}
                               downloadExport={this.downloadExport}
                               initialValues={{
                                   [ASE_EXPORT_FORM_FIELD_TYPE]: XLS,
                                   [ASE_EXPORT_FORM_FIELD_CHARSET]: charSets[0]
                               }}
                />
            </div>
        );
    }
}

InternalTicketExportAsePage.propTypes = {
    isLoadingExport: PropTypes.bool.isRequired,
    exportRecord: PropTypes.object.isRequired,
    fetchAseExportInternal: PropTypes.func.isRequired,
    createAseExportInternal: PropTypes.func.isRequired,
    downloadExportInternal: PropTypes.func.isRequired,
    charSets: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    isLoadingExport: state.aseExportInternalTickets.isLoadingExport,
    exportRecord: state.aseExportInternalTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
    roles: state.profile.userDetail.roles,
});

export default checkRoles(connect(mapStateToProps, {
    fetchAseExportInternal,
    createAseExportInternal,
    downloadExportInternal,
})(injectIntl(InternalTicketExportAsePage)), ['AS_SG_EDITOR']);
