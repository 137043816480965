
export const ADMIN_PRESET_RESPONSE_SELECT_GROUP = 'ADMIN_PRESET_RESPONSE_SELECT_GROUP';
export const ADMIN_PRESET_RESPONSE_FETCH = 'ADMIN_PRESET_RESPONSE_FETCH';
export const ADMIN_PRESET_RESPONSE_ADD = 'ADMIN_PRESET_RESPONSE_ADD';
export const ADMIN_PRESET_RESPONSE_EDIT = 'ADMIN_PRESET_RESPONSE_EDIT';
export const ADMIN_PRESET_RESPONSE_UPDATE = 'ADMIN_PRESET_RESPONSE_UPDATE';
export const ADMIN_PRESET_RESPONSE_MOVE = 'ADMIN_PRESET_RESPONSE_MOVE';
export const ADMIN_PRESET_RESPONSE_SWITCH = 'ADMIN_PRESET_RESPONSE_SWITCH';
export const ADMIN_PRESET_RESPONSE_REMOVE = 'ADMIN_PRESET_RESPONSE_REMOVE';

// export const storeSelectedCountryGroup = countryGroup => dispatch =>
//     dispatch({
//         type: ADMIN_PRESET_RESPONSE_SELECT_GROUP,
//         payload: countryGroup
//     });
//
// export const editPresetResponse = index => dispatch => {
//     dispatch({
//         type: ADMIN_PRESET_RESPONSE_EDIT,
//         payload: {index}
//     });
// };



