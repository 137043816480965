import React, {Component} from 'react';
import {connect} from 'react-redux';
import Loader from '../../Loader';
import {
    ASE_INTERNAL_TICKET_FORM,
} from '../../../constants/formConstants';
import PropTypes from 'prop-types';
import {formatInternalTicketNumber} from '../../../utils/utils';
import {editRights} from '../../../constants/aseInternalTicketRights';
import {FormattedMessage} from 'react-intl';
import {initLoadedAseInternalTicket} from '../Utils';
import {createReqFields, editReqFields} from '../../../constants/aseInternalTicketRequiredFields';
import formMessages from '../../../intl/afterSales/formMessages';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import checkRoles from '../../common/roleChecker/RoleChecker';
import InternalTicketMasterForm from './forms/InternalTicketMasterForm';

class EditLoadInternalTicketDataPage extends Component {
    componentDidMount() {
        const {ticketData} = this.props;
    }

    render() {
        const {
            ticketData,
            clientIds,
            handleSubmit,
            formType,
        } = this.props;
        if (clientIds.isLoading) {
            return <Loader/>;
        }

        const requiredFields = editReqFields[editReqFields[ticketData.ticket.group] ? ticketData.ticket.group : 'DEFAULT']['SG_EDITOR'][formType];
        const initializationValue = initLoadedAseInternalTicket(ticketData);
        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatInternalTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === ASE_INTERNAL_TICKET_FORM &&
                    <InternalTicketMasterForm form={formType}
                                                 initialValues={initializationValue}
                                                 userRights={editRights['AS_SG_EDITOR'][ticketData.ticket.status]}
                                                 enableReinitialize={true}
                                                 handleSubmit={handleSubmit}
                                                 reqFields={requiredFields}
                    />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ticketData: state.aseInternalTicket,
    clientIds: state.adminClientIds,
});

EditLoadInternalTicketDataPage.propTypes = {
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
};

export default checkRoles(connect(mapStateToProps, {

})(EditLoadInternalTicketDataPage), ['AS_SG_EDITOR']);

