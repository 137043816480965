import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    SAVE: {
        id: 'common.button.save',
        defaultMessage: 'Save'
    },
    SAVE_AND_SEND: {
        id: 'common.button.saveAndSend',
        defaultMessage: 'Save & Send'
    },
    ACCEPT: {
        id: 'common.button.accept',
        defaultMessage: 'Accept'
    },
    RESET: {
        id: 'common.button.reset',
        defaultMessage: 'Reset'
    },
    CANCEL: {
        id: 'common.button.cancel',
        defaultMessage: 'Cancel'
    },
    BACK: {
        id: 'common.button.back',
        defaultMessage: 'Back'
    },
    FIRST: {
        id: 'common.button.first',
        defaultMessage: 'First'
    },
    PREVIOUS: {
        id: 'common.button.previous',
        defaultMessage: 'Previous'
    },
    NEXT: {
        id: 'common.button.next',
        defaultMessage: 'Next'
    },
    LAST: {
        id: 'common.button.last',
        defaultMessage: 'Last'
    },
    CLOSE: {
        id: 'common.button.close',
        defaultMessage: 'Close'
    },
    SWITCH_TO_PVI: {
        id: 'common.button.switchToVorPvi',
        defaultMessage: 'Switch to VOR/PVI'
    },
    FORWARD_TO_NPDC: {
        id: 'common.button.forwardToNPDC',
        defaultMessage: 'Forward to NPDC'
    },
    FORWARD_TO_DEALER: {
        id: 'common.button.forwardToDealer',
        defaultMessage: 'Answer to Dealer'
    },
    MARK_AS_READ: {
        id: 'common.button.markAsRead',
        defaultMessage: 'Mark as Read'
    },
    ADD_REPLY_COMMENTS: {
        id: 'common.button.addReplyComments',
        defaultMessage: 'Add reply/comments'
    },
    CREATE_INTERNAL_TICKET: {
        id: 'common.button.createInternalTicket',
        defaultMessage: 'Create Internal Ticket'
    },
    TAKE_BACK: {
        id: 'common.button.takeBack',
        defaultMessage: 'Take back'
    },
    ADD: {
        id: 'common.button.add',
        defaultMessage: 'Add'
    },
    LOAD: {
        id: 'common.button.load',
        defaultMessage: 'Load'
    },
    REOPEN: {
        id: 'common.button.reopen',
        defaultMessage: 'Reopen'
    },
    BVM_LOAD: {
        id: 'common.button.bvmLoad',
        defaultMessage: 'Load Data'
    },
    SEARCH: {
        id: 'common.button.search',
        defaultMessage: 'Search'
    },
    EXPORT: {
        id: 'common.button.export',
        defaultMessage: 'Export'
    },
    ALL: {
        id: 'common.button.all',
        defaultMessage: 'All'
    },
    CLEAR_ATTACHMENTS: {
        id: 'common.button.clearAttachments',
        defaultMessage: 'Remove attachment'
    },
    NO: {
        id: 'common.button.no',
        defaultMessage: 'No'
    },
    YES: {
        id: 'common.button.yes',
        defaultMessage: 'Yes'
    },
    DOWNLOAD: {
        id: 'common.button.download',
        defaultMessage: 'Download'
    },
    EDIT: {
        id: 'common.button.edit',
        defaultMessage: 'Edit'
    },
    ESCALATE: {
        id: 'common.button.escalate',
        defaultMessage: 'Escalate to'
    },
    ANSWER : {
        id: 'common.button.answer',
        defaultMessage: 'Answer to'
    },
    TRANSFER_TO: {
        id: 'common.button.transferTo',
        defaultMessage: 'Transfer to'
    },
    TRANSFER_TO_REQUESTOR: {
        id: 'common.button.transferToRequestor',
        defaultMessage: 'Answer to Requestor'
    },
});

export default messages;
