import {
    ASE_CREATE_TICKET,
    ASE_GET_TICKET,
    ASE_UPDATE_TICKET
} from '../../constants/websocketCommands';

export const ASE_TICKET_RESET = 'ASE_TICKET_RESET';
export const ASE_TICKET_CREATE = 'ASE_TICKET_CREATE';
export const ASE_TICKET_UPDATE = 'ASE_TICKET_UPDATE';
export const ASE_TICKET_SUBMIT = 'ASE_TICKET_SUBMIT';

export const updateAseTicket = (data) => dispatch => {
    return dispatch({
        type: ASE_TICKET_UPDATE,
        aseWebsocket: true,
        command: ASE_UPDATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const saveAseTicket = (data) => dispatch => {
    return dispatch({
        type: ASE_TICKET_CREATE,
        aseWebsocket: true,
        command: ASE_CREATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const fetchAseTicket = (uuid) => dispatch => {
    return dispatch({
        type: ASE_GET_TICKET,
        aseWebsocket: true,
        command: ASE_GET_TICKET,
        payload: {
            ticket: {
                uuid: uuid,
            }
        }
    });
};

export const resetAseTicket = () => dispatch => {
    return dispatch({
        type: ASE_TICKET_RESET,
    });
};

export const submitBy = (data) => dispatch => {
    return dispatch({
        type: ASE_TICKET_SUBMIT,
        payload: {
            ...data
        }
    })
}
