import React from 'react';
import styled from 'styled-components';
import {Field, reduxForm, formValueSelector} from 'redux-form'
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {BoldPrimaryButton, SecondaryButton} from '../../../common/Button';
import mainPageAlertMessages from '../../../../intl/admin/mainPageAlertMessages';
import buttonMessages from '../../../../intl/common/buttonMessages';
import {connect} from 'react-redux';
import {TextareaFieldWithValidation} from '../../../common/TextareaFieldWithValidation';

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

const MessageForm = ({handleSubmit, pristine, reset, valid, submitting, intl: {formatMessage}, popUpDisabled}) => (
    <div>
        <form onSubmit={handleSubmit}>
            <div>
                <TextareaFieldWithValidation field="textPopUp"
                                             placeholder={formatMessage(mainPageAlertMessages.PLACEHOLDER_POP_UP_MESSAGE)}
                                             isDisabled={!popUpDisabled}
                                             isRequired={popUpDisabled}
                                             rows={6}/>
            </div>
            <div className="row pt-2">
                <div className="col">
                    <BoldPrimaryButton type="submit"
                                       disabled={pristine || submitting || !valid}
                                       className="btn float-right">
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                    <Button type="button"
                            disabled={pristine || submitting}
                            className="btn float-right"
                            onClick={reset}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </Button>
                </div>
            </div>
        </form>
    </div>
);

const mapStateToProps = (state, props) => ({
    popUpDisabled: formValueSelector(props.form)(state, 'disabled'),
});

MessageForm.propTypes = {
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default reduxForm({
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, {})(MessageForm)));
