import {
    ASE_ATTACHMENTS_SAVED_ERROR,
    ASE_GET_TICKET,
    ASE_TICKET_ATTACHMENTS_SAVED,
    ASE_TICKET_CREATED,
    ASE_TICKET_LOCKED,
    ASE_TICKET_LOCKED_ERROR,
    ASE_TICKET_LOCKED_FATAL,
    ASE_TICKET_SEND,
    ASE_TICKET_UPDATED,
} from '../../constants/websocketCommands';
import {
    ASE_TICKET_CREATE,
    ASE_TICKET_RESET, ASE_TICKET_SUBMIT,
    ASE_TICKET_UPDATE
} from '../../actions/ase/actionAseTicket';
import {ASE_ATTACHMENTS_UPLOADING_ERROR} from '../../actions/ase/actionAseAttachments';
import {ASE_RESET_REDIRECT, ASE_SET_LOCK_TICKET} from '../../actions/ase/actionAseLockTicket';

export const aseTicket = (state = {
    correlationId: null,
    isTicketLoading: false,
    isTicketCreating: false,
    created: false,
    ticket: {},
    vehicleDetail: {},
    problemDescription: {},
    logs: [],
    messages: [],
    attachments: [],
    newAttachments: [],
    submittedBy: null,
}, action) => {
    switch (action.type) {
        case ASE_TICKET_SUBMIT: {
            const { submittedBy } = action.payload;
            return Object.assign({}, state, {
                submittedBy
            });
        }
        case ASE_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLoading: false,
                isTicketCreating: false,
                created: false,
                ticket: {},
                vehicleDetail: {},
                problemDescription: {},
                documentationUpdate: {},
                logs: [],
                attachments: [],
                newAttachments: [],
                submittedBy: null,
            });
        }
        case ASE_TICKET_UPDATE:
        case ASE_TICKET_CREATE: {
            const {newAttachments} = action.payload;
            return Object.assign({}, state, {
                newAttachments,
                isTicketCreating: true,
                created: false,
                isTicketLoading: true,
                vehicleDetail: {},
                problemDescription: {},
                messages: [],
                logs: [],
                attachments: [],
            });
        }
        //command accepted
        case ASE_TICKET_CREATED:
        case ASE_TICKET_UPDATED:
        case ASE_GET_TICKET: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                created: true,
                isTicketLoading: true,
                correlationId
            });
        }
        case ASE_TICKET_SEND: {
            const {ticket, correlationId, vehicleDetail, problemDescription, logs, attachments, error, messages, solutionGroupInfo, editorsNotes, internalTicketReferences, documentationUpdate} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, {isTicketLoading: false, correlationId});
            }
            return Object.assign({}, state, {
                isTicketLoading: false,
                correlationId,
                ticket,
                vehicleDetail,
                problemDescription,
                documentationUpdate,
                logs,
                attachments,
                messages,
                solutionGroupInfo,
                editorsNotes,
                internalTicketReferences,
            });
        }
        //att done + error
        case ASE_ATTACHMENTS_UPLOADING_ERROR:
        case ASE_ATTACHMENTS_SAVED_ERROR: {
            return Object.assign({}, state, {
                newAttachments: [],
            });
        }
        case ASE_TICKET_ATTACHMENTS_SAVED: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketLoading: true,
                correlationId,
                newAttachments: [],
            });
        }
        default:
            return state;
    }
};

export const aseLockTicketCheck = (state = {
    correlationId: null,
    isTicketLocking: false,
    redirect: false,
}, action) => {
    switch (action.type) {
        case ASE_TICKET_LOCKED_ERROR:
        case ASE_TICKET_LOCKED_FATAL: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketLocking: false,
                redirect: true,
            });
        }
        case ASE_RESET_REDIRECT: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case ASE_TICKET_LOCKED: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case ASE_SET_LOCK_TICKET: {
            const {uuid} = action.payload;
            return Object.assign({}, state, {
                isTicketLocking: true,
                redirect: false,
                uuid,
            });
        }
        default:
            return state;
    }
};
