import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const ASE_RECURRENT_PROBLEM_PENDING = 'ASE_RECURRENT_PROBLEM_PENDING';
export const ASE_RECURRENT_PROBLEM_SELECT_GROUP = 'ASE_RECURRENT_PROBLEM_SELECT_GROUP';

export const fetchAseRecurrentProblems = (domain, group) => dispatch => {
    return dispatch({
        type: ASE_RECURRENT_PROBLEM_PENDING,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_RECURRENT_PROBLEMS_GET,
        payload: {group}
    });
};

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ASE_RECURRENT_PROBLEM_SELECT_GROUP,
        payload: countryGroup
    });
