import React from 'react';
import formMessages from '../../../../../../intl/afterSales/formMessages';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {ASE_INTERNAL_TICKET} from '../../../../../../routes/paths';
import {getFormInitialValues} from 'redux-form';
import {connect} from 'react-redux';
import get from 'get-value';
import internalTicketRequestTypeMessages
    from '../../../../../../intl/afterSales/internalTicketRequestTypeMessages';
import {formatInternalTicketNumber} from '../../../../../../utils/utils';


const StyledDiv = styled.div`
  border-radius: 5px;
    margin-top: 10px;
`;

const InternalTicketReferences = ({initialValues}) => {

    const references = get(initialValues, 'internalTicketReferences') || [];

    return (
            <StyledDiv className="border border-secondary bg-white overflow-hidden">
               <div>
                    <div className="border-bottom border-bottom-secondary"
                         style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                        <label className="col-form-label pl-0 font-weight-bold ml-3">
                            <FormattedMessage {...formMessages.INTERNAL_TICKET_REFERENCES}/>
                        </label>
                    </div>
                   {references.map((ref, index) => {
                           const ticketNumber = formatInternalTicketNumber(ref.pfxNumber,ref.sfxNumber);
                           const link = `${ASE_INTERNAL_TICKET}/view/${ref.uuid}`;
                           const requestTypeMessage = internalTicketRequestTypeMessages[ref.typeOfRequest];
                           return <div key={index} className="row m-0 align-items-stretch">
                               <div style={{backgroundColor: '#f8f9fa'}}
                                    className="col-sm-12 col-lg-3 flex-column border-bottom">
                                   <Link to={link}>{ticketNumber}</Link>
                               </div>
                               <div className="col border-bottom" style={{whiteSpace: 'pre-wrap'}}>
                                   {requestTypeMessage && <FormattedMessage {...requestTypeMessage}/> }
                               </div>
                           </div>;
                       }
                   )}
                </div>
            </StyledDiv>
    )
}

// InternalTicketReferences.propTypes = {
//     readOnly: PropTypes.bool,
//     userRights: PropTypes.object.isRequired,
//     reqFields: PropTypes.array.isRequired,
// };

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
});

export default injectIntl(connect(mapStateToProps)(InternalTicketReferences));
