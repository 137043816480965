import get from 'get-value';
import formTypeMessages from '../intl/common/formTypeMessages';
import {domains, getInternalFormTypes} from '../constants/Utils';

export const initLoadedAnsweringSchema = (domain, answeringSchemaData, formatMessage) => {
    let formattedFormType = {}
    const formType = get(answeringSchemaData, 'answeringSchema.formType');
    if (formType){
        const internal = isInternal(domain, formType)
        const messages = formTypeMessages;
        const label = formatMessage(messages[formType]);
        formattedFormType = {
            label: internal ? label + ' (Internal)' : label,
            value: formType
        };
    }

    let formattedAnsweringGroups = []
    if(get(answeringSchemaData, "answeringSchema.answeringSolutionGroups")){
        formattedAnsweringGroups = get(answeringSchemaData, "answeringSchema.answeringSolutionGroups").map(sg => ({
            label: sg.name,
            value: sg.id
        }))
    }

    return {
        answeringSchemaInfoSection: {
            name: {label: get(answeringSchemaData, "answeringSchema.name"), value: get(answeringSchemaData, "answeringSchema.solutionGroupId")},
            formType: formattedFormType,
        },
        answeringSchemaAnsweringSection: {
            answeringGroups: formattedAnsweringGroups
        }
    }
};

export const initNewAnsweringSchema = () => {
    return {
        answeringSchemaInfoSection: {
            name: '',
            formType: '',
        },
        answeringSchemaAnsweringSection: {
            answeringGroups: [],
        }
    };
};

export const transformNewAnsweringSchema = (domain, values) => {
    const answeringGroups = values.answeringSchemaAnsweringSection.answeringGroups;

    const formattedAnsweringGroups = answeringGroups.map(sg => ({
        name: sg.label,
        id: sg.value
    }));

    const data = values.answeringSchemaInfoSection.formType.map(formType => ({
        id: values.answeringSchemaInfoSection.name.value,
        name: values.answeringSchemaInfoSection.name.label,
        answeringSchema: {
            formType: formType.value,
            ticketType: resolveTicketTypeBy(domain, formType.value),
            answeringSolutionGroups: formattedAnsweringGroups,
        }
    }));

    return data;
};

export const transformUpdateAnsweringSchema = (domain, {newValues}) => {

    let formattedAnsweringGroups = []
    if(get(newValues, "answeringSchemaAnsweringSection.answeringGroups")){
        formattedAnsweringGroups = get(newValues, "answeringSchemaAnsweringSection.answeringGroups").map(sg => ({
            name: sg.label,
            id: sg.value
        }))
    }

    const formType = newValues.answeringSchemaInfoSection.formType.value;

    const data = {
        id: newValues.answeringSchemaInfoSection.name.value,
        name: newValues.answeringSchemaInfoSection.name.label,
        answeringSchema: {
            formType: newValues.answeringSchemaInfoSection.formType.value,
            ticketType: resolveTicketTypeBy(domain, formType),
            answeringSolutionGroups: formattedAnsweringGroups,
        }
    };

    return data;
};

export const resolveTicketTypeBy = (domain, formType) => {
    return getInternalFormTypes(domain).includes(formType) ? 'internal' : 'dealer';
}

export const isInternal = (domain, formType) => resolveTicketTypeBy(domain, formType) === 'internal' && (domain !== domains.ASE);
