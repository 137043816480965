import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {injectIntl} from 'react-intl';
import internalFulltextSearchMessages from '../../../../intl/afterSales/internalFulltextSearchMessages';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import {
    FORM_FIELD_ISSUE_ORIGIN,
    FORM_FIELD_NOD_ID,
    FORM_FIELD_PART_REFERENCE_ASSOCIATED,
    FORM_FIELD_DOCUMENT_ID,
    FORM_LABEL_ISSUE_ORIGIN,
    FORM_LABEL_NOD_ID,
    FORM_LABEL_PART_REFERENCE_ASSOCIATED,
    FORM_LABEL_DOCUMENT_ID,
} from '../../../../constants/formConstants';
import BorderRadius from '../../../common/styled/BorderRadius';
import InputSelectFieldWithValidation from '../../../common/InputSelectFieldWithValidation';
import {issueOrigin} from '../../../../constants/Utils';
import internalTicketIssueOriginMessages from '../../../../intl/afterSales/internalTicketIssueOriginMessages';

const ProblemDescriptionInternalSearchSection = ({domain, intl}, ) => {

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...internalFulltextSearchMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <BorderRadius size={5} className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_ISSUE_ORIGIN]}
                                                        field={FORM_FIELD_ISSUE_ORIGIN}
                                                        options={issueOrigin}
                                                        intlMessages={internalTicketIssueOriginMessages}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_PART_REFERENCE_ASSOCIATED]}
                                                  field={FORM_FIELD_PART_REFERENCE_ASSOCIATED}
                                                  maxLength={10}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_NOD_ID]}
                                                  field={FORM_FIELD_NOD_ID}
                                                  maxLength={20}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_DOCUMENT_ID]}
                                                  field={FORM_FIELD_DOCUMENT_ID}
                                                  maxLength={40}
                                                  isSmall
                        />
                    </div>
                </div>
            </BorderRadius>
        </>
    )
};

const mapStateToProps = (state) => ({
    roles: state.profile.userDetail.roles,
});

const mapDispatchToProps = (dispatch, props) => ({
});


export default  connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProblemDescriptionInternalSearchSection));
