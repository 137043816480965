import {domains} from '../../constants/Utils';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const ADMIN_NOTIFICATION_FETCH_PENDING = 'ADMIN_NOTIFICATION_FETCH_PENDING';
export const ADMIN_NOTIFICATION_UPDATE = 'ADMIN_NOTIFICATION_UPDATE';
export const ADMIN_NOTIFICATION_SELECT_GROUP = 'ADMIN_NOTIFICATION_SELECT_GROUP';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_NOTIFICATION_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchNotificationData = (domain, group) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: ADMIN_NOTIFICATION_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_NOTIFICATIONS_GET,
                payload: {group}
            });
        }
    }
};

export const updateNotificationData = (domain, group, {subject, body}) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: ADMIN_NOTIFICATION_UPDATE,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_NOTIFICATIONS_SET,
                payload: {notification: {group, subject, body}}
            });
        }
    }
};

