import get from 'get-value';
import {domains, getCurrentFormTypes} from '../constants/Utils.js';
import {countryGroups, getFormTypes} from '../constants/Utils';

function hasAtLeastOneRoleSet(roles, allowedValues) {
    return Object.keys(roles)
        .filter(role => roles[role] === true && allowedValues.includes(role))
        .length > 0;
}

function hasAtLeastOneSolutionGroupSet(roles) {
    return Object.keys(roles).length > 0;
}

function getRoles(roles) {
    return Object.keys(roles)
        .map(role => ({group: role, value: roles[role]}))
        .filter(item => item.value === true)
        .sort((option1, option2) => option1.group.localeCompare(option2.group));
}

export const moduleRoles = {
    isPowerUser(roles, domain) {
        if (domain) {
            return get(roles, `${domain}.powerUser`, {default: false});
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.powerUser`, {default: false}))
                .find(isPowerUser => isPowerUser);
        }
    },
    isPowerUserAse(roles) {
        return this.isPowerUser(roles, domains.ASE);
    },

    isAdmin(roles, domain) {
        if (domain) {
            return hasAtLeastOneRoleSet(
                get(roles, `${domain}.admin.groupPermission`, {default: {}}),
                Object.values(countryGroups)
            );
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.admin.groupPermission`, {default: {}}))
                .find(roles => hasAtLeastOneRoleSet(roles, Object.values(countryGroups)));
        }
    },
    isSGAdmin(roles, domain) {
        if (domain) {
            return get(roles, `${domain}.sgAdmin.sgPermission`, {default: false});
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.sgAdmin.sgPermission`, {default: {}}))
                .find(roles => hasAtLeastOneSolutionGroupSet(roles));
        }
    },
    isSGEditor(roles, domain) {
        if (domain) {
            return get(roles, `${domain}.sgEditor.sgPermission`, {default: false});
        }
    },
    isSGEditorAse(roles) {
        return this.isSGEditor(roles, domains.ASE);
    },
    isSGEditorAseInSG(roles, sgId) {
        return (get(roles, `${domains.ASE}.sgEditor.sgPermission`, {default: []}) || []).some(sg => sg.id === sgId);
    },
    isSGEditorLVL23(roles, domain) {
        if (domain) {
            const solutionGroups = get(roles, `${domain}.sgEditor.sgPermission`, {default: []});
            return solutionGroups.some(sg => sg.groups.length === 0);
        }
    },
    isSGEditorLVL1only(roles, domain) {
        if (domain) {
            const solutionGroups = get(roles, `${domain}.sgEditor.sgPermission`);
            if (solutionGroups) {
                return solutionGroups.every(sg => sg.groups.length !== 0);
            }
        }
        return false;
    },
    isSGEditorLVL1onlyAse(roles) {
        return this.isSGEditorLVL1only(roles, domains.ASE);
    },
    isSGEditorLVL23Ase(roles) {
        return this.isSGEditorLVL23(roles, domains.ASE);
    },
    isSGAdminAse(roles) {
        return this.isSGAdmin(roles, domains.ASE);
    },
    isEditor(roles, domain) {
        if (domain) {
            return hasAtLeastOneRoleSet(
                get(roles, `${domain}.editor.groupPermission`, {default: {}}),
                Object.values(countryGroups)
            );
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.editor.groupPermission`, {default: {}}))
                .find(roles => hasAtLeastOneRoleSet(roles, Object.values(countryGroups)));
        }
    },
    isSGDefaultEditorForGroup(roles, domain, group) {
        if (domain) {
            const solutionGroups = get(roles, `${domain}.sgEditor.sgPermission`, {default: []});
            return solutionGroups.find(sg => (get(sg, 'groups', {default: []}) || []).includes(group));
        }
    },
    isEditorAse(roles) {
        return this.isEditor(roles, domains.ASE);
    },
    isDealer(roles, domain) {
        return hasAtLeastOneRoleSet(
                get(roles, `${domain}.dealer.formsPermission`, {default: {}}),
                getCurrentFormTypes(domain)
        );
    },
    isDealerAse(roles) {
        return this.isDealer(roles, domains.ASE);
    },
    getAdminGroups(domain, roles) {
        return getRoles(get(roles, `${domain}.admin.groupPermission`, {default: {}}));
    },
    getDealerForms(domain, roles) {
        return getRoles(get(roles, `${domain}.dealer.formsPermission`, {default: {}}));
    },
    getEditorGroups(domain, roles) {
        return getRoles(get(roles, `${domain}.editor.groupPermission`, {default: {}}));
    },
    getSGEditorGroups(domain, roles) {
        return get(roles, `${domain}.sgEditor.sgPermission`, {default: {}});
    },
    isAdminAse(roles) {
        return this.isAdmin(roles, domains.ASE);
    },
};
