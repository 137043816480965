import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import styled from 'styled-components';
import get from 'get-value';
import {cloneDeep, isEqual} from 'lodash';
import Loader from '../Loader';
import MainMessage from './MainMessage';
import StatisticsTable from './StatisticsTable';
import formTypeMessages from '../../intl/common/formTypeMessages';
import {domains} from '../../constants/Utils';
import {moduleRoles} from '../../utils/roles';
import mainPageMessages from '../../intl/home/mainPageMessages';
import {fetchMainMessages} from '../../actions/admin/actionAdminMainMessage';
import {fetchStatistics} from '../../actions/ase/actionAseStatistics';
import Select from 'react-select';
import authManagementMessages from '../../intl/admin/authManagementMessages';
import countryGroupMessages from '../../intl/common/countryGroupMessages';
import {PrimaryButton} from '../common/Button';
import buttonMessages from '../../intl/common/buttonMessages';
import {
    updateAseUserStatisticsPreference,
    updateAseUserStatisticsSGPreference
} from '../../actions/ase/actionAsePreferences';
import {union} from 'lodash';

const Wrapper = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  margin: 10px;
`;

const DOMAIN = domains.ASE;

class AseDomainContent extends Component {
    constructor(props) {
        super(props);
        const {userDetail: {roles}} = props;

        let isSGEditor = false;
        let isEditor = false;
        let isDealer = false;

        if(moduleRoles.isSGEditorAse(roles)) isSGEditor = true;
        if (moduleRoles.isEditorAse(roles)) isEditor = true;
        if (!isEditor && moduleRoles.isDealerAse(roles)) isDealer = true;

        this.state = {
            isSGEditor, isEditor, isDealer,
            managedGroups: isEditor ? moduleRoles.getEditorGroups(DOMAIN, get(this.props.userDetail, `roles`, {})).map(({group}) => group) : [],
            managedSGroups: isSGEditor ? moduleRoles.getSGEditorGroups(DOMAIN, get(this.props.userDetail, `roles`, {})) : []
        };
    }

    loadData() {
        const {fetchMainMessages, fetchStatistics, userDetail: {dealerNumber, group}, statisticPreferenceCountries = [], statisticPreferenceSG = []} = this.props;
        const {managedGroups, managedSGroups} = this.state;
        if (this.state.isEditor && this.state.isSGEditor) {
            const filteredCountries = statisticPreferenceCountries.filter(country => managedGroups.includes(country));
            const filteredSG = managedSGroups.filter(sg => statisticPreferenceSG.includes(sg.id)).map(e => (e.id));
            const filteredSGforMainMessage = [...new Set(managedSGroups.filter(sg => statisticPreferenceSG.includes(sg.id)).map(e => (e.groups)).flat(1))];
            fetchMainMessages(DOMAIN, union(filteredCountries, filteredSGforMainMessage));
            fetchStatistics({
                countryGroups: filteredCountries,
                solutionGroups: filteredSG
            });
        } else if (this.state.isSGEditor) {
            const filteredSG = managedSGroups.filter(sg => statisticPreferenceSG.includes(sg.id)).map(e => (e.id));
            const filteredSGforMainMessage = [...new Set(managedSGroups.filter(sg => statisticPreferenceSG.includes(sg.id)).map(e => (e.groups)).flat(1))];
            fetchMainMessages(DOMAIN, filteredSGforMainMessage);
            fetchStatistics({
                solutionGroups: filteredSG
            });
        } else if (this.state.isEditor) {
            const filteredCountries = statisticPreferenceCountries.filter(country => managedGroups.includes(country));
            fetchMainMessages(DOMAIN, filteredCountries);
            fetchStatistics({
                countryGroups: filteredCountries,
            });
        } else if (this.state.isDealer && group && dealerNumber) {
            fetchMainMessages(DOMAIN, [group]);
            fetchStatistics({dealerNumber});
        }
    }

    componentDidUpdate(prevProps) {
        const {statisticPreferenceCountries = [], statisticPreferenceSG = []} = this.props;
        const {statisticPreferenceCountries: prevStatisticPreferenceCountries = [], statisticPreferenceSG: prevStatisticPreferenceSG = []} = prevProps;
        const {managedGroups = [], managedSGroups = []} = this.state;

        const selectedOptions = statisticPreferenceCountries.filter(country => managedGroups.includes(country));
        const prevSelectedOptions = prevStatisticPreferenceCountries.filter(country => managedGroups.includes(country));
        if (!isEqual(selectedOptions, prevSelectedOptions)) {
            this.loadData();
        }

        const selectedSGOptions = managedSGroups.filter(sg => statisticPreferenceSG.includes(sg.id));
        const prevSelectedSGOptions = managedSGroups.filter(sg => prevStatisticPreferenceSG.includes(sg.id));
        if (!isEqual(selectedSGOptions, prevSelectedSGOptions)) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    handleCountrySelectionChange = newOptions => {
        const {userDetail} = this.props;
        this.props.updateAseUserStatisticsPreference(userDetail.ipn, cloneDeep(newOptions.map(e => e.value) || []));
    };

    handleAllCountriesClick = () => {
        const {userDetail} = this.props;
        this.props.updateAseUserStatisticsPreference(userDetail.ipn, cloneDeep(this.state.managedGroups || []));
    };

    handleSGSelectionChange = newOptions => {
        const {userDetail} = this.props;
        this.props.updateAseUserStatisticsSGPreference(userDetail.ipn, cloneDeep(newOptions.map(e => e.value) || []))
    };

    handleAllSGClick = () => {
        const {userDetail} = this.props;
        this.props.updateAseUserStatisticsSGPreference(userDetail.ipn, cloneDeep(this.state.managedSGroups.map(e => e.id) || []));
    };

    render() {
        const {isLoadingMessages, isLoadingStatistics, statisticData, intl: {formatMessage}, statisticPreferenceCountries = [], statisticPreferenceSG = []} = this.props;
        const {isSGEditor, isEditor, isDealer, managedGroups, managedSGroups} = this.state;

        const selectedOptions = statisticPreferenceCountries.filter(country => managedGroups.includes(country));
        const options = (managedGroups || {}).map(e => ({
            value: e,
            label: countryGroupMessages[e] ? formatMessage(countryGroupMessages[e]) : e
        }));
        const formattedSelectedOptions = selectedOptions.map(e => ({
            value: e,
            label: countryGroupMessages[e] ? formatMessage(countryGroupMessages[e]) : e
        })).sort((option1, option2) => option1.value.localeCompare(option2.value));


        const selectedSGOptions = managedSGroups.filter(sg => statisticPreferenceSG.includes(sg.id))
        const sgOptions = (managedSGroups || {}).map(e => ({
            value: e.id,
            label: e.name
        }));
        const formattedSelectedSGOptions = selectedSGOptions.map(e => ({
            value: e.id,
            label:e.name
        })).sort((option1, option2) => option1.value.localeCompare(option2.value));

        return <>
            <Wrapper>
                <div className="row mb-sm-3">
                    {(isDealer || isEditor || isSGEditor) &&
                    <div className="col-sm-6">
                        <h4><FormattedMessage {...formTypeMessages[DOMAIN]} /></h4>
                    </div>
                    }
                </div>
                {isEditor &&
                <>
                    <div className="row">
                        <div className="col">
                            <h5>
                                <FormattedMessage {...mainPageMessages.SELECT_COUNTRIES} />
                            </h5>
                        </div>
                    </div>
                    <div className="row mb-sm-3">
                        <div className="col-10 pr-0">
                            <Select isMulti isSearchable
                                    value={formattedSelectedOptions}
                                    options={options}
                                    placeholder={formatMessage(authManagementMessages.ADMIN_PLACEHOLDER_SELECT_COUNTRIES)}
                                    onChange={this.handleCountrySelectionChange}/>
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            <PrimaryButton type="button" className="btn btn-sm"
                                           onClick={this.handleAllCountriesClick}>
                                <FormattedMessage {...buttonMessages.ALL}/>
                            </PrimaryButton>
                        </div>
                    </div>
                </>
                }
                {isSGEditor &&
                <>
                    <div className="row">
                        <div className="col">
                            <h5>
                                <FormattedMessage {...mainPageMessages.SELECT_SOLUTION_GROUPS} />
                            </h5>
                        </div>
                    </div>
                    <div className="row mb-sm-3">
                        <div className="col-10 pr-0">
                            <Select isMulti isSearchable
                                    value={formattedSelectedSGOptions}
                                    options={sgOptions}
                                    placeholder={formatMessage(authManagementMessages.ADMIN_PLACEHOLDER_SELECT_SOLUTION_GROUPS)}
                                    onChange={this.handleSGSelectionChange}/>
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            <PrimaryButton type="button" className="btn btn-sm"
                                           onClick={this.handleAllSGClick}>
                                <FormattedMessage {...buttonMessages.ALL}/>
                            </PrimaryButton>
                        </div>
                    </div>
                </>
                }
                {selectedOptions &&
                <>
                    <div className="row mb-sm-3">
                        {(isDealer || isEditor || isSGEditor) &&
                        <div className="col-sm-12">
                            <h5>
                                <FormattedMessage {...mainPageMessages.MAIN_MESSAGES} />
                            </h5>
                            {isLoadingMessages && <Loader/>}
                            {!isLoadingMessages &&
                            <MainMessage domain={DOMAIN}/>}
                        </div>
                        }
                    </div>
                    <div className="row mb-sm-5">
                        {(isDealer || isEditor || isSGEditor) &&
                        <div className="col-sm-12">
                            <h5>
                                <FormattedMessage {...mainPageMessages.STATISTICS} />
                            </h5>
                            {isLoadingStatistics && <Loader/>}
                            {!isLoadingStatistics &&
                            <StatisticsTable domain={DOMAIN} data={statisticData} group={selectedOptions} solutionGroup={formattedSelectedSGOptions.map(e => e.value)}/>}
                        </div>
                        }
                    </div>
                </>
                }
            </Wrapper>
        </>;
    }
}

AseDomainContent.propTypes = {
    intl: PropTypes.object.isRequired,
    userDetail: PropTypes.object.isRequired,
    isLoadingMessages: PropTypes.bool.isRequired,
    isLoadingStatistics: PropTypes.bool.isRequired,
    statisticData: PropTypes.array,
    statisticPreferenceCountries: PropTypes.array.isRequired,
    statisticPreferenceSG: PropTypes.array.isRequired,
    fetchMainMessages: PropTypes.func.isRequired,
    fetchStatistics: PropTypes.func.isRequired,
    updateAseUserStatisticsPreference: PropTypes.func.isRequired,
    updateAseUserStatisticsSGPreference: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
    isLoadingMessages: get(state, 'adminMainMessage.isLoadingAse'),
    isLoadingStatistics: get(state, `aseStatistics.isLoading`),
    statisticData: get(state, `aseStatistics.data`),
    statisticPreferenceCountries: get(state, 'asePreferences.menuStatistics'),
    statisticPreferenceSG: get(state, 'asePreferences.menuStatisticsSG'),
});

export default connect(mapStateToProps, {
    fetchMainMessages,
    fetchStatistics,
    updateAseUserStatisticsPreference,
    updateAseUserStatisticsSGPreference,
})(injectIntl(AseDomainContent));
