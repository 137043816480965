import React from 'react';
import styled from 'styled-components';
import {reduxForm, FormSection} from 'redux-form'
import {
    DOCUMENTATION_UPDATE_SECTION,
    FORM_ATTACHMENTS_SECTION,
    FORM_MESSAGES_SECTION,
    FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_TICKET_SECTION,
    FORM_VEHICLE_DETAIL_SECTION,
} from '../../../../constants/formConstants';
import RenderSyncErrors from '../../../common/RenderSyncErrors';
import formMessages from '../../../../intl/afterSales/formMessages';
import {aseFieldLabelMapping} from '../../../../constants/aseFieldLabelMapping';
import PropTypes from 'prop-types';
import SubmitButtons from './sections/SubmitButtons';
import {scrollContentWrapperToTop} from '../../../../utils/utils';
import {connect} from 'react-redux';
import RequestDetailSection from './sections/requestorInformationSection/RequestorInformationSection';
import AttachmentSection from './sections/attachments/AttachmentSection';
import TicketStatusHistory from '../../../common/TicketStatusHistory';
import ProblemDescriptionSectionInternal from './sections/problemDescription/ProblemDescriptionSectionInternal';
import VehicleDetailSectionInternal from './sections/vehicleDetail/VehicleDetailSectionInternal';
import InternalNotes from './sections/internalNotes/InternalNotes';
import DocumentationUpdateSection from './sections/documentationUpdate/DocumentationUpdateSection';

const StyledDiv = styled.div`
  border-radius: 10px;
  background-color: #FFFAE6!important;
`;

const InternalTicketMasterForm = (props) => {
    const {form, userRights, readOnly, handleSubmit, reqFields, userRoles} = props;

    return (
        <StyledDiv className="border border-dark p-4">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={aseFieldLabelMapping}/>
            <FormSection name={FORM_TICKET_SECTION}>
                <RequestDetailSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_VEHICLE_DETAIL_SECTION}>
                <VehicleDetailSectionInternal form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_PROBLEM_DESCRIPTION_SECTION}>
                <ProblemDescriptionSectionInternal form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            {userRights.canShowInternalNotes &&
                <FormSection name={FORM_MESSAGES_SECTION}>
                    <InternalNotes form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
                </FormSection>
            }
            {userRights.canShowInternalDocumentationUpdate &&
                <FormSection name={DOCUMENTATION_UPDATE_SECTION}>
                    <DocumentationUpdateSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
                </FormSection>
            }
            <FormSection name={FORM_ATTACHMENTS_SECTION}>
                <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <TicketStatusHistory form={form} isInternal/>
            <SubmitButtons form={form} onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop}
                           userRights={userRights} readOnly={readOnly}/>
        </StyledDiv>
    )
}

InternalTicketMasterForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => ({
    // selectedRequestType: formValueSelector(props.form)(state, `${FORM_TICKET_SECTION}.${FORM_FIELD_TYPE_OF_REQUEST}`),
});

const mapDispatchToProps = (dispatch, props) => ({

});

export default reduxForm({})(connect(mapStateToProps, mapDispatchToProps)(InternalTicketMasterForm));

