import {
    ASE_INTERNAL_CREATE_TICKET,
    ASE_INTERNAL_GET_SG_INFO,
    ASE_INTERNAL_GET_TICKET,
    ASE_INTERNAL_UPDATE_TICKET
} from '../../constants/websocketCommands';

export const ASE_INTERNAL_TICKET_RESET = 'ASE_INTERNAL_TICKET_RESET';
export const ASE_INTERNAL_TICKET_CREATE = 'ASE_INTERNAL_TICKET_CREATE';
export const ASE_INTERNAL_TICKET_UPDATE = 'ASE_INTERNAL_TICKET_UPDATE';
export const ASE_INTERNAL_GET_SG_INFO_RESET = 'ASE_INTERNAL_GET_SG_INFO_RESET';


export const updateAseInternalTicket = (data) => dispatch => {
    return dispatch({
        type: ASE_INTERNAL_TICKET_UPDATE,
        aseWebsocket: true,
        command: ASE_INTERNAL_UPDATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const saveAseInternalTicket = (data) => dispatch => {
    return dispatch({
        type: ASE_INTERNAL_TICKET_CREATE,
        aseWebsocket: true,
        command: ASE_INTERNAL_CREATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const fetchAseInternalTicket = (uuid) => dispatch => {
    return dispatch({
        type: ASE_INTERNAL_GET_TICKET,
        aseWebsocket: true,
        command: ASE_INTERNAL_GET_TICKET,
        payload: {
            ticket: {
                uuid: uuid,
            }
        }
    });
};

export const fetchAseInternalTicketSgInfo = (sgId) => dispatch => {
    return dispatch({
        type: ASE_INTERNAL_GET_SG_INFO,
        aseWebsocket: true,
        command: ASE_INTERNAL_GET_SG_INFO,
        payload: {
            solutionGroupId: sgId
        }
    });
};

export const resetAseInternalTicket = () => dispatch => {
    return dispatch({
        type: ASE_INTERNAL_TICKET_RESET,
    });
};

export const resetAseInternalTicketSgInfo = () => dispatch => {
    return dispatch({
        type: ASE_INTERNAL_GET_SG_INFO_RESET,
    });
};
