import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const createAseExportInternal = (exportFilter) => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_EXPORT_INTERNAL_CREATE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_EXPORT_INTERNAL_CREATE,
        payload: {
            exportFilter
        },
    });
};

export const fetchAseExportInternal = () => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_EXPORT_INTERNAL_GET,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_EXPORT_INTERNAL_GET,
        payload: {},
    });
};

export const downloadExportInternal = (exportRecord) => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_EXPORT_INTERNAL_DOWNLOAD_URL_GET,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_EXPORT_INTERNAL_DOWNLOAD_URL_GET,
        payload: {
            exportRecord
        },
    });
};
