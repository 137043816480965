import React from 'react';
import styled from 'styled-components';
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {
    FORM_FIELD_MESSAGE, FORM_FIELD_MESSAGE_OPTION,
    FORM_LABEL_MESSAGE,
    FORM_LABEL_MESSAGES_HISTORY,
    FORM_MESSAGES_SECTION,
} from '../../../../../../constants/formConstants';
import {change, getFormInitialValues} from 'redux-form';
import {connect} from 'react-redux';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import get from 'get-value';
import formMessages from '../../../../../../intl/afterSales/formMessages';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import {automaticAseResponseOptions} from '../../../../../../constants/Utils';
import automaticResponseOptionMessages
    from '../../../../../../intl/afterSales/automaticResponseOptionMessages';
import {injectIntl} from 'react-intl';
import automaticResponseTextMessages
    from '../../../../../../intl/afterSales/automaticResponseTextMessages';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {moduleRoles} from '../../../../../../utils/roles';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const MessagesSection = props => {
    const {userRights, readOnly, initialValues, change, reqFields, intl, roles} = props;

    const handleChange = (e) => {
        if (e.target.value !== '' && e.target.value !== undefined && e.target.value !== null && automaticResponseTextMessages[e.target.value]) {
            change(FORM_FIELD_MESSAGE, intl.formatMessage(automaticResponseTextMessages[e.target.value]));
        }
    };

    const canReadMessage = (message) => {
        if (moduleRoles.isEditorAse(roles) || moduleRoles.isSGEditorAse(roles) || !message.solutionGroup) {
            return true;
        }
        return message.solutionGroup.canForwardToDealer;
    }

    const getCreatorData = (message) => {
        const {creatorName, solutionGroup, creatorIpn} = message;
        const solutionGroupName = solutionGroup ? ' ' + solutionGroup.name + ' ' : ' ';
        return `${creatorName}${solutionGroupName}(${creatorIpn})`;
    }

    const messages = get(initialValues, 'messages') ? get(initialValues, 'messages').filter(item => canReadMessage(item)) : null;
    const canAnswerToDealer = get(initialValues, 'ticketSection.solutionGroup.canForwardToDealer', {default: true});

    return (
        <>
            {((userRights.canAddNotes && !readOnly) || (messages && (messages.length > 0))) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.MESSAGES_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(messages && (messages.length > 0)) &&
                    <div>
                        <div className="border-bottom border-bottom-secondary"
                             style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                            <label className="col-form-label pl-0 font-weight-bold ml-3">
                                <ValidFormattedMessage message={FORM_LABEL_MESSAGES_HISTORY}
                                                       intlMessages={formMessages}/>
                            </label>
                        </div>
                        {messages.map((message, index) =>
                            <div key={index} className="row m-0 align-items-stretch">
                                <div style={{backgroundColor: '#f8f9fa'}}
                                     className="col-sm-12 col-lg-4 flex-column border-bottom">
                                    <div>
                                        <FormattedDate value={new Date(message.created)}/> <FormattedTime
                                        value={new Date(message.created)}/>
                                        <span className="font-weight-bold">{' '}
                                            <ValidFormattedMessage message={message.isDealer ? 'DEALER' : 'EDITOR'}
                                                                   intlMessages={commonMessages}/>
                                        </span>
                                    </div>
                                    <div>
                                        {getCreatorData(message)}
                                    </div>
                                </div>
                                <div className="col border-bottom" style={{whiteSpace: 'pre-wrap'}}>
                                    {message.text}
                                </div>
                            </div>
                        )}
                    </div>
                    }
                    {userRights.canAddNotes && !readOnly &&
                    <div className="col-sm-12 px-4 pb-3 pt-1">
                        {userRights.canShowAutomaticResponse &&
                        <div className="col-xl-4 col-lg-6 col-md-12 px-0 pb-2">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_MESSAGE]}
                                                            field={FORM_FIELD_MESSAGE_OPTION}
                                                            options={get(automaticAseResponseOptions, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: []})}
                                                            intlMessages={automaticResponseOptionMessages}
                                                            handleChange={handleChange}
                                                            isDisabled={!canAnswerToDealer}
                                                            isRequired={reqFields.includes(FORM_FIELD_MESSAGE_OPTION)}
                            />
                        </div>
                        }
                        <div className="col-sm-12 px-0">
                            <TextareaFieldWithValidation field={FORM_FIELD_MESSAGE}
                                                         isRequired={reqFields.includes(FORM_FIELD_MESSAGE)}
                                                         isDisabled={!canAnswerToDealer}
                                                         rows={6}/>
                        </div>
                    </div>
                    }
                </StyledDiv>
            </>}
        </>
    );
};

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    roles: state.profile.userDetail.roles,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, FORM_MESSAGES_SECTION + '.' + name, value)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MessagesSection));
