import {
    ASE_INTERNAL_TICKET_STATUS_ANSWERED,
    ASE_INTERNAL_TICKET_STATUS_CLOSED,
    ASE_INTERNAL_TICKET_STATUS_IN_PROGRESS,
    ASE_INTERNAL_TICKET_STATUS_NEW,
    ASE_INTERNAL_TICKET_STATUS_REOPENED,
    ASE_INTERNAL_TICKET_STATUS_TRANSFERRED,
} from './formConstants';

export const editRights = {
    AS_SG_EDITOR: {
        [ASE_INTERNAL_TICKET_STATUS_NEW]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketPriority: true,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketProblemDesc: false,
            canShowInternalDocumentationUpdate: true,
            canEditInternalDocumentationUpdate: true,
            canShowInternalNotes: true,
            canAddAttach: true,
            canSave: true,
            canClose: false,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: false,
            canAnswer: false,
            canEscalate: false,
            canDeleteAttachments: false,
        },
        [ASE_INTERNAL_TICKET_STATUS_TRANSFERRED]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketPriority: false,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketProblemDesc: false,
            canShowInternalDocumentationUpdate: true,
            canEditInternalDocumentationUpdate: false,
            canShowInternalNotes: true,
            canAddAttach: false,
            canSave: false,
            canClose: false,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: false,
            canAnswer: false,
            canEscalate: false,
            canDeleteAttachments: false,
        },
        [ASE_INTERNAL_TICKET_STATUS_ANSWERED]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketPriority: false,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketProblemDesc: false,
            canShowInternalDocumentationUpdate: true,
            canEditInternalDocumentationUpdate: false,
            canShowInternalNotes: true,
            canAddAttach: false,
            canSave: false,
            canClose: false,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: false,
            canAnswer: false,
            canEscalate: false,
            canDeleteAttachments: false,
        },
        [ASE_INTERNAL_TICKET_STATUS_IN_PROGRESS]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketPriority: true,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketProblemDesc: false,
            canShowInternalDocumentationUpdate: true,
            canEditInternalDocumentationUpdate: true,
            canShowInternalNotes: true,
            canAddAttach: true,
            canSave: true,
            canClose: true,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: true,
            canAnswer: true,
            canEscalate: true,
            canDeleteAttachments: false,
        },
        [ASE_INTERNAL_TICKET_STATUS_REOPENED]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketPriority: true,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketProblemDesc: false,
            canShowInternalDocumentationUpdate: true,
            canEditInternalDocumentationUpdate: true,
            canShowInternalNotes: true,
            canAddAttach: true,
            canSave: false,
            canClose: false,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: false,
            canAnswer: true,
            canEscalate: true,
            canDeleteAttachments: false,
        },
        [ASE_INTERNAL_TICKET_STATUS_CLOSED]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketPriority: false,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketProblemDesc: false,
            canShowInternalDocumentationUpdate: true,
            canEditInternalDocumentationUpdate: false,
            canShowInternalNotes: true,
            canAddAttach: false,
            canSave: false,
            canClose: false,
            canReopen: true,
            canCancel: false,
            canAnswerToRequestor: false,
            canAnswer: false,
            canEscalate: false,
            canDeleteAttachments: false,
        }
    }
};

export const createRights = {
    canEditInternalTicketRequestDetail: true,
    canEditInternalTicketPriority: true,
    canEditInternalTicketVehDetails: true,
    canEditVehDetails: true,
    canEditBvm: true,
    canEditInternalTicketProblemDesc: true,
    canShowInternalDocumentationUpdate: true,
    canEditInternalDocumentationUpdate: true,
    canShowInternalNotes: false,
    canAddAttach: true,
    canSave: false,
    canClose: false,
    canReopen: false,
    canCancel: true,
    canAnswerToRequestor: false,
    canAnswer: false,
    canEscalate: true,
    canDeleteAttachments: true,
};

export const editAseInternalTicketListButtonVisibility = {
    AS_SG_EDITOR: [
        ASE_INTERNAL_TICKET_STATUS_NEW,
        ASE_INTERNAL_TICKET_STATUS_TRANSFERRED,
        ASE_INTERNAL_TICKET_STATUS_ANSWERED,
        ASE_INTERNAL_TICKET_STATUS_IN_PROGRESS,
        ASE_INTERNAL_TICKET_STATUS_REOPENED,
    ]
};
