import {domains} from '../constants/Utils';
import {aseWebsocketCommands} from '../constants/aseWebsocketCommands';
import { adminWebsocketCommands } from '../constants/adminWebsocketCommands';

export const FILES_UPLOAD_PENDING = 'FILES_UPLOAD_PENDING';
export const FILES_DOWNLOAD_PENDING = 'FILES_DOWNLOAD_PENDING';
export const FILE_UPLOAD_STATUS = 'FILE_UPLOAD_STATUS';

export const downloadFile = (domain, {uuid, name}, problemId) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: FILES_DOWNLOAD_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_RECURRENT_PROBLEMS_FILE_DOWNLOAD,
                payload: {attachment: {uuid, name, problemId}}
            });
        }
        case domains.ADMINISTRATION: {
            return dispatch({
                type: FILES_DOWNLOAD_PENDING,
                adminWebsocket: true,
                command: adminWebsocketCommands.ADMIN_HELP_FILE_DOWNLOAD,
                payload: {attachment: {uuid, name}}
            });
        }
    }
};

export const uploadFile = (domain, uuid, problemId, contentType, localFileUrl) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            dispatch({type: FILE_UPLOAD_STATUS, payload: {uuid}});
            return dispatch({
                type: FILES_UPLOAD_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_RECURRENT_PROBLEMS_FILE_UPLOAD,
                payload: {attachment: {uuid, problemId, contentType, localFileUrl}}
            });
        }
    }
};

