import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import {clearAlerts} from '../../../actions/alertsActions';
import SearchEngineAseTable from './FullTextSearchAseTable';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import SearchEngineAseForm from './FullTextSearchAseForm';
import fulltextSearchMessages from '../../../intl/afterSales/fulltextSearchMessages';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import {StyledPicky} from '../../common/StyledComponents';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {fillNumberFiveZero, pruneEmpty} from '../../../utils/utils';
import {SubmissionError} from 'redux-form';
import commonMessages from '../../../intl/common/commonMessages';
import {moduleRoles} from '../../../utils/roles';
import {lockAseTicket, resetRedirectAfterAseLockTicket} from '../../../actions/ase/actionAseLockTicket';
import {updateAseUserFulltextSearchPreference} from '../../../actions/ase/actionAsePreferences';
import issueOriginMessages from '../../../intl/afterSales/issueOriginMessages';
import {
    FORM_FIELD_FROM,
    FORM_FIELD_PFX,
    FORM_FIELD_SFX,
    FORM_FIELD_TO,
    FORM_TICKET_SECTION
} from '../../../constants/formConstants';
import {fetchAseLastEditor} from '../../../actions/ase/actionAseFullTextSearchPage';

class FullTextSearchAsePage extends Component {
    constructor(props) {
        super(props);
        const {intl, roles} = props;

        const isSgEditor = moduleRoles.isSGEditorAse(roles);
        const isEditor = !isSgEditor && moduleRoles.isEditorAse(roles);
        const isDealer = !isSgEditor && !isEditor && moduleRoles.isDealerAse(roles);
        const urlParams = new URLSearchParams(props.location.search);

        const storageData = this.loadStateFromSessionStorage();
        this.state = {
            ratchet: true,
            columnLibrary: [
                {
                    id: 'creatorIpn',
                    accessor: 'ticketBase.creatorIpn',
                    message: fulltextSearchMessages.TABLE_CREATED_BY,
                    filterable:false,
                },
                {
                    id: 'clientId',
                    accessor: 'ticketBase.clientId',
                    message: fulltextSearchMessages.TABLE_CLIENT_ID,
                    filterable:false,
                    Cell: e => e.value && String(e.value).padStart(6, "0")
                },
                {
                    id: 'region',
                    accessor: 'ticketBase.region',
                    message: fulltextSearchMessages.TABLE_REG_DIR,
                    filterable:false,
                },
                {
                    id: 'dealerNumber',
                    accessor: 'ticketBase.dealerNumber',
                    message: fulltextSearchMessages.TABLE_DEALER_NUMBER,
                    filterable:false,
                    Cell: e => e.value && String(e.value).padStart(8, "0")
                },
                {
                    id: 'created',
                    accessor: 'ticketBase.created',
                    message: fulltextSearchMessages.TABLE_ORDER_CREATION_TIME,
                    filterable:false,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'issueOrigin',
                    accessor: 'problemDescription.issueOrigin',
                    message: fulltextSearchMessages.TABLE_ISSUE_ORIGIN,
                    filterable:false,
                    Cell: e =>
                        <div>{issueOriginMessages[e.value] ? intl.formatMessage(issueOriginMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'status',
                    accessor: 'ticketBase.status',
                    message: fulltextSearchMessages.TABLE_STATUS,
                    filterable:false,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'solutionGroup',
                    accessor: 'ticketBase.solutionGroup',
                    message: fulltextSearchMessages.TABLE_SOLUTION_GROUP,
                    filterable:false,
                    Cell: e =>
                        <div> {e.value && !e.value.canForwardToDealer && isDealer ? '' : get(e, 'value.name', '')}</div>
                },
                {
                    id: 'lastUpdate',
                    accessor: 'ticketBase.lastUpdate',
                    filterable:false,
                    message: fulltextSearchMessages.TABLE_LAST_STATUS_DATE,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'lastEditor',
                    accessor: 'ticketBase.lastEditor',
                    message: fulltextSearchMessages.TABLE_LAST_EDITOR,
                    filterable:false,
                }
            ],
            listDefaultFilters: get(storageData, 'filters', []),
            formInitValues: get(storageData, 'formValues', {}),
            searchOption: {},
        };


        if(isDealer) {
            this.state.columnLibrary = this.state.columnLibrary.filter(column => column.id !== 'managementGroup');
        }

        let urlParamsFilter = [];
        let urlParamsForm = { vehicleDetail: {}};
        if(urlParams.has('vin')){
            const vin = urlParams.get('vin') ? urlParams.get('vin').slice(0,17) : '';
            urlParamsFilter.push(
                {
                    id: 'vin',
                    value: vin,
                }
            )
            urlParamsForm.vehicleDetail.vin = vin;
        }
        if (urlParamsFilter.length > 0) {
            this.state.listDefaultFilters = [urlParamsFilter, {}];
            this.state.formInitValues = urlParamsForm;
        }
    }

    componentWillUnmount() {
        this.props.resetRedirectAfterAseLockTicket();
    }

    resetForm = () => {
        this.setState({listDefaultFilters: [], formInitValues: {}});
        this.saveStateToSessionStorage({}, [[], {}]);
    }

    saveStateToSessionStorage(formValues = {}, filters = []) {
        sessionStorage.setItem('fullTextASE', JSON.stringify({formValues, filters}));
    }

    loadStateFromSessionStorage() {
        let value = {};
        if (sessionStorage.hasOwnProperty('fullTextASE')) {
            value = JSON.parse(sessionStorage.getItem('fullTextASE'));
            return value || {};
        }
        return {};
    }

    componentDidMount() {
        this.props.clearAlerts();
        if (moduleRoles.isEditorAse(this.props.roles) || moduleRoles.isDealerAse(this.props.roles)) {
            this.props.fetchAseLastEditor();
        }
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockAseTicket(id);
            this.setState({ratchet: false});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateAseUserFulltextSearchPreference(this.props.loggedUser.ipn,
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    handleSubmit = (values) => {
        const polishedValues = pruneEmpty(values);
        const filter = [];
        const pfx = get(polishedValues, 'ticketSection.pfxNumber', '').length;
        const sfx = get(polishedValues, 'ticketSection.sfxNumber', '').length;

        if (pfx > 0 && sfx === 0) {
            throw new SubmissionError({
                [FORM_TICKET_SECTION]: {[FORM_FIELD_SFX]: 'Fill complete ticket number'}
            })
        }

        if (pfx === 0 && sfx > 0) {
            throw new SubmissionError({
                [FORM_TICKET_SECTION]: {[FORM_FIELD_PFX]: 'Fill complete ticket number'}
            })
        }
        const temp = JSON.parse(JSON.stringify({
            ...polishedValues
        }));

        if (pfx || sfx) {
            temp.ticketSection.ticketNumber = `${polishedValues.ticketSection.pfxNumber}-${polishedValues.ticketSection.sfxNumber}`;
            delete temp.ticketSection.pfxNumber;
            delete temp.ticketSection.sfxNumber;
        }
        if (get(temp, `${FORM_TICKET_SECTION}.${FORM_FIELD_FROM}`) === null) {
            delete temp[FORM_TICKET_SECTION][FORM_FIELD_FROM];
        }
        if (get(temp, `${FORM_TICKET_SECTION}.${FORM_FIELD_TO}`) === null) {
            delete temp[FORM_TICKET_SECTION][FORM_FIELD_TO];
        }
        Object.keys(temp).filter(key => key !== 'searchOption').forEach(section => Object.keys(temp[section]).forEach(key => {

                if (key === 'ovalPlate') {
                    Object.keys(temp[section][key]).forEach(ovalKey => filter.push(
                        {
                            id: ovalKey,
                            value: temp[section][key][ovalKey]
                        }
                    ))
                } else {
                    filter.push(
                        {
                            id: key,
                            value: temp[section][key]
                        }
                    )
                }
            }
        ));
        this.setState({listDefaultFilters: [filter, polishedValues.searchOption || {}]});
        this.saveStateToSessionStorage(polishedValues, [filter, polishedValues.searchOption || {}]);
    };


    render() {
        const {intl, asePreferences, lockTicket} = this.props;
        if (lockTicket.redirect && !this.state.ratchet) {
            this.props.history.push(`/ase/edit/${lockTicket.uuid}`);
        }
        if (asePreferences.isLoading) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...fulltextSearchMessages.TITLE}/>}/>
                <SearchEngineAseForm
                    form={'aseSearchEngineForm'}
                    initialValues={this.state.formInitValues}
                    resetForm={this.resetForm}
                    enableReinitialize={true}
                    onSubmit={this.handleSubmit}
                />
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold">
                    <FormattedMessage {...fulltextSearchMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={this.state.columnLibrary.filter(item => !get(asePreferences, 'menuFullTextSearch', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={this.state.columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <SearchEngineAseTable
                    columns={this.state.columnLibrary.filter(item => !get(asePreferences, 'menuFullTextSearch', {default: []}).includes(item.id))}
                    listDefaultFilters={this.state.listDefaultFilters}
                    handleEditClick={this.handleEditClick}/>
            </div>
        );
    }
}

FullTextSearchAsePage.propTypes = {
    lockTicket: PropTypes.object.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    updateAseUserFulltextSearchPreference: PropTypes.func.isRequired,
    fetchAseLastEditor: PropTypes.func.isRequired,
    lockAseTicket: PropTypes.func.isRequired,
    resetRedirectAfterAseLockTicket: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    isLoadingLastEditors: PropTypes.bool.isRequired,
};


const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    asePreferences: state.asePreferences,
    lockTicket: state.aseListOrderLockTicketCheck,
    isLoadingLastEditors: state.aseFullTextSearchPage.isLoadingLastEditors,
    roles: state.profile.userDetail.roles,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateAseUserFulltextSearchPreference,
    lockAseTicket,
    resetRedirectAfterAseLockTicket,
    fetchAseLastEditor,
})(injectIntl(FullTextSearchAsePage))), ['AS_DEALER', 'AS_EDITOR', 'AS_SG_EDITOR']);

