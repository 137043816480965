import {
    USER_ROLE_FETCH_ERROR,
    USER_ROLE_FETCH_PENDING,
    USER_ROLE_FETCH_SUCCESS,
    USER_ROLE_SAVE_ERROR,
    USER_ROLE_SAVE_PENDING,
    USER_ROLE_SAVE_SUCCESS,
    USER_TO_BE_CREATED
} from '../actions/actionRole';
import {moduleRoles} from '../utils/roles';

export const profile = (state = {
    isLoading: true,
    userDetail: {},
    userRoles: [],
    userToBeCreated: false
}, action) => {
    switch (action.type) {
        case USER_ROLE_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case USER_ROLE_FETCH_SUCCESS: {
            const {roles} = action.payload;
            const userRoles = [];
            if (moduleRoles.isPowerUserAse(roles)) {
                userRoles.push('AS_POWER_USER');
            }
            if (moduleRoles.isAdminAse(roles)) {
                userRoles.push('AS_ADMIN');
            }
            if (moduleRoles.isDealerAse(roles)) {
                userRoles.push('AS_DEALER');
            }
            if (moduleRoles.isSGAdminAse(roles)) {
                userRoles.push('AS_SG_ADMIN');
            }
            if (moduleRoles.isSGEditorAse(roles)) {
                userRoles.push('AS_SG_EDITOR');
            }
            if (moduleRoles.isEditorAse(roles)) {
                userRoles.push('AS_EDITOR');
            }

            return Object.assign({}, state, {isLoading: false, userDetail: action.payload, userRoles});
        }
        case USER_ROLE_FETCH_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case USER_ROLE_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true, userToBeCreated: false});
        }
        case USER_ROLE_SAVE_SUCCESS: {
            return Object.assign({}, state, {isLoading: false});
        }
        case USER_ROLE_SAVE_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case USER_TO_BE_CREATED: {
            return Object.assign({}, state, {userToBeCreated: true});
        }
        default:
            return state;
    }
};
