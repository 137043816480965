import {
    ASE_DOWNLOAD_PRESIGNED_URL,
    ASE_CREATE_ATTACHMENTS,
} from '../../constants/websocketCommands';
import {extractErrorText} from '../actionUtils';
import {errorAlert} from '../alertsActions';
import {uploadAseAttachment} from '../../api/ase/restAseAttachments';
import alertMessages from '../../intl/common/alertMessages';

export const ASE_ATTACHMENTS_UPLOADING_ERROR = 'ASE_ATTACHMENTS_UPLOADING_ERROR';
export const ASE_ATTACHMENTS_UPLOADING = 'ASE_ATTACHMENTS_UPLOADING';

export const startUploadAttachments = () => dispatch => {
    return dispatch({type: ASE_ATTACHMENTS_UPLOADING});
};

export const downloadAttachment = (uuid, ticketId, name) => dispatch => {
    return dispatch({
        type: ASE_DOWNLOAD_PRESIGNED_URL,
        aseWebsocket: true,
        command: ASE_DOWNLOAD_PRESIGNED_URL,
        payload: {
            attachment: {
                uuid,
                ticketId,
                name
            }
        }
    });
};

export const uploadAttachments = (attachments, correlationId, solutionGroup) => dispatch => {
    const promises = [];
    const savedAttachments = [];
    attachments.forEach(attachment => {
        promises.push(
            uploadAseAttachment(attachment.url, attachment.localUrl, attachment.name, attachment.contentType).then(
                () => {
                    window.URL.revokeObjectURL(attachment.localUrl);
                    savedAttachments.push(attachment);
                }, error => {
                    dispatch({type: ASE_ATTACHMENTS_UPLOADING_ERROR});
                    dispatch(errorAlert(...extractErrorText(error, alertMessages.FILE_UPLOAD_ERROR)));
                }
            )
        )
    });
    return Promise.all(promises).then(() => {
            if (savedAttachments.length > 0) {
                dispatch({
                    type: ASE_CREATE_ATTACHMENTS,
                    aseWebsocket: true,
                    command: ASE_CREATE_ATTACHMENTS,
                    payload: {
                        attachments: savedAttachments,
                        correlationId,
                        uuid: savedAttachments[0].ticketId,
                        solutionGroup
                    }
                })
            } else {
                dispatch({
                    type: ASE_ATTACHMENTS_UPLOADING_ERROR,
                })
            }
        }
    )
};
