import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../Loader';
import {
    initNewAnsweringSchema,
    transformNewAnsweringSchema, transformUpdateAnsweringSchema,
} from '../../../../utils/answeringSchemaUtils';
import { createReqFields } from '../../../../constants/answeringSchemaRequiredFields';
import PageHeader from '../../../common/PageHeader';
import get from 'get-value';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    fetchAnsweringSchema,
    redirectReset,
    resetAnsweringSchema,
    saveAnsweringSchema
} from '../../../../actions/admin/actionAnsweringSchema';
import {ANSWERING_SCHEMA_FORM } from '../../../../constants/formConstants';
import {FormattedMessage, injectIntl} from 'react-intl';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import AnsweringSchemaForm from './forms/AnsweringSchemaForm';
import {fetchEscalationSchemaSGOptions} from '../../../../actions/admin/actionEscalationSchemaOptions';
import {getAdminAnsweringSchemaPath} from '../../../../routes/pathResolver';

class NewAnsweringSchemaPage extends Component {

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetAnsweringSchema(this.props.domain);
        this.props.fetchEscalationSchemaSGOptions(this.props.domain);
    }
    componentWillUnmount() {
        this.props.redirectReset(this.props.domain);
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewAnsweringSchema(this.props.domain, values.newValues);
        this.setState(dataForSend);
        this.props.saveAnsweringSchema(this.props.domain, dataForSend);
    };

    render() {
        const {
            answeringSchemaData,
            domain,
            intl: {formatMessage},
        } = this.props;

        const {
            answeringSchemaSubmittedAndShouldRedirect: shouldRedirect,
            isAnsweringSchemaCreating: creating,
            isAnsweringSchemaLoading: loading,
            answeringSchema: schema,
            initWithOldData
        } = answeringSchemaData

        const sgGroup = get(answeringSchemaData, 'answeringSchema.solutionGroupId')

        if (shouldRedirect && !creating && !loading && sgGroup) {
            return <Redirect to={{
                pathname: `${getAdminAnsweringSchemaPath(domain)}/view/${schema.formType}-${schema.solutionGroupId}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (creating || loading || shouldRedirect) {
            return <Loader/>;
        }

        let initializationValue = initNewAnsweringSchema();
        const requiredFields = createReqFields['DEFAULT'][ANSWERING_SCHEMA_FORM];
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.answeringSchema.title"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Answering Schema"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <AnsweringSchemaForm
                            form={ANSWERING_SCHEMA_FORM}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            domain={domain}
                            creating={true}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

NewAnsweringSchemaPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    answeringSchemaData: PropTypes.object.isRequired,
    fetchAnsweringSchema: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    resetAnsweringSchema: PropTypes.func.isRequired,
    saveAnsweringSchema: PropTypes.func.isRequired,
    isLoadingProfile: PropTypes.bool.isRequired,
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    clearAlerts: PropTypes.func.isRequired,
    answeringSchemaData: state.adminAnsweringSchema,
    userDetail: state.profile.userDetail,
    isLoadingProfile: state.profile.isLoading,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchAnsweringSchema,
    saveAnsweringSchema,
    resetAnsweringSchema,
    redirectReset,
    fetchEscalationSchemaSGOptions,
})(injectIntl(NewAnsweringSchemaPage))), ['AS_POWER_USER']);

