import React from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import {PrimaryButton, SecondaryButton} from '../../common/Button';
import {FormSection, reduxForm} from 'redux-form';
import buttonMessages from '../../../intl/common/buttonMessages';
import {FormattedMessage} from 'react-intl';
import RenderSyncErrors from '../../common/RenderSyncErrors';
import fulltextSearchMessages from '../../../intl/afterSales/fulltextSearchMessages';
import {aseFieldLabelMapping} from '../../../constants/aseFieldLabelMapping';
import BorderRadius from '../../common/styled/BorderRadius';
import SearchOption from './section/SearchOption';
import {
    DOCUMENTATION_UPDATE_SECTION,
    FORM_DEALER_SECTION,
    FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_SEARCH_OPTION_SECTION,
    FORM_TICKET_SECTION, FORM_VEHICLE_DETAIL_SECTION
} from '../../../constants/formConstants';
import DealerSection from './section/DealerSection';
import TicketSection from './section/TicketSection';
import ProblemDescriptionSection from './section/ProblemDescriptionSection';
import DocumentationUpdateSection from './section/DocumentationUpdateSection';
import VehicleDetailSection from './section/VehicleDetailSection';

class FullTextSearchAseForm extends React.Component {
    reset = () => {
        this.props.resetForm();
        this.props.reset();
    }

    render() {
        const {handleSubmit, form} = this.props;
        return (
            <BorderRadius size={5} className="border border-secondary p-4 bg-white">
                <RenderSyncErrors form={form} intlMessages={fulltextSearchMessages} mapping={aseFieldLabelMapping}/>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <form className="form-group">
                            <div className="col-12 pb-2">
                                <FormSection name={FORM_DEALER_SECTION}>
                                    <DealerSection form={form}/>
                                </FormSection>
                                <FormSection name={FORM_TICKET_SECTION}>
                                    <TicketSection form={form}/>
                                </FormSection>
                                <FormSection name={FORM_VEHICLE_DETAIL_SECTION}>
                                    <VehicleDetailSection form={form}/>
                                </FormSection>
                                <FormSection name={FORM_PROBLEM_DESCRIPTION_SECTION}>
                                    <ProblemDescriptionSection form={form}/>
                                </FormSection>
                                <FormSection name={DOCUMENTATION_UPDATE_SECTION}>
                                    <DocumentationUpdateSection form={form}/>
                                </FormSection>
                                <FormSection name={FORM_SEARCH_OPTION_SECTION}>
                                    <SearchOption form={form}/>
                                </FormSection>
                            </div>
                            <div className="col-12 pb-2">
                                <PrimaryButton type="button"
                                               className="btn mr-2 float-right"
                                               onClick={handleSubmit}>
                                    <FormattedMessage {...buttonMessages.SEARCH}/>
                                </PrimaryButton>
                                <SecondaryButton type="button"
                                                 className="btn float-right mr-2 bg-white"
                                                 onClick={this.reset}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </SecondaryButton>
                            </div>
                        </form>
                    </div>
                </div>
            </BorderRadius>
        )
    }
}

FullTextSearchAseForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
};

export default reduxForm({})(FullTextSearchAseForm);
