import {
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_VIN,
    FORM_FIELD_REQUESTOR_NAME,
    FORM_FIELD_REQUESTOR_IPN,
    FORM_FIELD_SOLUTION_GROUP,
    FORM_FIELD_ISSUE_ORIGIN,
    FORM_FIELD_TELEPHONE_NO,
    FORM_FIELD_REQUEST_ROLE,
    ASE_INTERNAL_TICKET_FORM,
    FORM_FIELD_PRIORITY_TREATMENT
} from './formConstants';

export const editReqFields = {
    'DEFAULT': {
        'SG_EDITOR': {
            [ASE_INTERNAL_TICKET_FORM]: [
                FORM_FIELD_REQUESTOR_NAME,
                FORM_FIELD_REQUESTOR_IPN,
                FORM_FIELD_SOLUTION_GROUP,
                FORM_FIELD_REQUEST_ROLE,
                FORM_FIELD_ISSUE_ORIGIN,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_PRIORITY_TREATMENT
            ],
        },
    }
};

export const createReqFields = {
    'DEFAULT': {
        [ASE_INTERNAL_TICKET_FORM]: [
            FORM_FIELD_REQUESTOR_NAME,
            FORM_FIELD_REQUESTOR_IPN,
            FORM_FIELD_SOLUTION_GROUP,
            FORM_FIELD_REQUEST_ROLE,
            FORM_FIELD_ISSUE_ORIGIN,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PRIORITY_TREATMENT
        ]
    }
};
