import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../../Loader';
import { FORM_BUTTON_EDIT, SOLUTION_GROUP_FORM } from '../../../../constants/formConstants';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    fetchSolutionGroup,
    resetSolutionGroup,
    updateSolutionGroup
} from '../../../../actions/admin/actionSolutionGroup';
import { initLoadedSolutionGroup } from '../../../../utils/solutionGroupUtils';
import SolutionGroupForm from './forms/SolutionGroupForm';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {getAdminSGPath} from '../../../../routes/pathResolver';

class ViewSolutionGroupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToNew: false,
            redirectToEdit: false,
            solutionGroupSubmitted: false,
            hasToBeUpdated: false,
            storedSolutionGroupData: {},
            newInitData: {},
        };
    }

    componentDidMount() {
        this.props.clearAlerts();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchSolutionGroup(this.props.domain, this.props.match.params.solutionGroupUuid, this.props.userDetail.ipn);
        }
    }

    componentWillUnmount() {
        this.props.resetSolutionGroup(this.props.domain);
    }

    handleSubmit = (values) => {
        const { submitBy } = values;

        switch (submitBy) {
            case FORM_BUTTON_EDIT: {
                this.setState({
                    solutionGroupSubmitted: true,
                    redirectToEdit: true,
                });
                break;
            }
            default: {
            }
        }
    };

    render() {
        const {
            solutionGroupData,
            domain,
            intl: {formatMessage},
        } = this.props;

        let formType = false;
        if (get(solutionGroupData, 'solutionGroup.name', { default: false })) {
            formType = SOLUTION_GROUP_FORM;
        }


        if (this.state.solutionGroupSubmitted && this.state.redirectToEdit) {
            this.props.resetSolutionGroup(this.props.domain);
            return <Redirect to={{
                pathname: `${getAdminSGPath(this.props.domain)}/edit/${solutionGroupData.solutionGroup.id}`,
            }}/>;
        } else if (this.state.solutionGroupSubmitted) {
            this.props.updateSolutionGroup(domain, this.state.storedSolutionGroupData);
            this.setState({ solutionGroupSubmitted: false, storedSolutionGroupData: {}, hasToBeUpdated: false });
        }

        const initializationValue = initLoadedSolutionGroup(solutionGroupData, formatMessage);
        if (
            solutionGroupData.isSolutionGroupLoading
            || solutionGroupData.isSolutionGroupCreating
            || !formType
        ) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.solutionGroup.title"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Solution Group"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <SolutionGroupForm
                            form={formType}
                            initialValues={initializationValue}
                            enableReinitialize={true}
                            reqFields={[]}
                            domain={domain}
                            readOnly
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    solutionGroupData: state.adminSolutionGroup,
    userDetail: state.profile.userDetail,
});

ViewSolutionGroupPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    solutionGroupData: PropTypes.object.isRequired,
    fetchSolutionGroup: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    updateSolutionGroup: PropTypes.func.isRequired,
    resetSolutionGroup: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchSolutionGroup,
    clearAlerts,
    resetSolutionGroup,
    updateSolutionGroup,
})(injectIntl(ViewSolutionGroupPage))), ['AS_POWER_USER', 'AS_SG_ADMIN']);

