import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {clearAlerts} from '../../../actions/alertsActions';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import {
    documentationUpdate,
    domains,
    getInternalTicketFormStates,
    issueOrigins,
    priorityTreatment,
} from '../../../constants/Utils';
import HistoryAseInternalTicketTable from './HistoryAseInternalTicketTable';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import commonMessages from '../../../intl/common/commonMessages';
import historyMessages from '../../../intl/afterSales/historyMessages';
import {
    updateAseUserInternalTicketHistoryPreference
} from '../../../actions/ase/actionAsePreferences';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import {StyledPicky} from '../../common/StyledComponents';
import checkRoles from '../../common/roleChecker/RoleChecker';
import { formatTicketNumber} from '../../../utils/utils';
import {Link} from 'react-router-dom';
import issueOriginMessages from '../../../intl/afterSales/issueOriginMessages';
import priorityTreatmentMessages from '../../../intl/afterSales/priorityTreatmentMessages';

const domain = domains.ASE;

class HistoryAsePage extends Component {
    constructor(props) {
        super(props);
        const {intl, loggedUser, loggedUser: {roles}} = props;

        this.state = {
            ratchet: true,
            columnLibrary: [
                {
                    id: 'ORDER_CREATION_TIME',
                    accessor: 'created',
                    message: historyMessages.TABLE_IT_CREATION_TIME,
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'ISSUE_ORIGIN',
                    accessor: 'issueOrigin',
                    message: historyMessages.TABLE_IT_ISSUE_ORIGIN,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {issueOrigins.map((key, index) =>
                                <option key={index} value={key}>
                                    {issueOriginMessages[key] ? intl.formatMessage(issueOriginMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{issueOriginMessages[e.value] ? intl.formatMessage(issueOriginMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'PRIORITY',
                    accessor: 'priorityTreatment',
                    message: historyMessages.TABLE_IT_PRIORITY,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {priorityTreatment.map((key, index) =>
                                <option key={index} value={key}>
                                    {priorityTreatmentMessages[key] ? intl.formatMessage(priorityTreatmentMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{priorityTreatmentMessages[e.value] ? intl.formatMessage(priorityTreatmentMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'CREATED_BY_IPN',
                    accessor: 'creatorIpn',
                    message: historyMessages.TABLE_IT_CREATED_BY_IPN,
                    Cell: e => <div>{e.value}</div>,
                },
                {
                    id: 'STATUS',
                    accessor: 'status',
                    message: historyMessages.TABLE_REQUEST_STATUS,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {getInternalTicketFormStates(domain).map((key, index) =>
                                <option key={index} value={key}>
                                    {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'DEALER_TICKET_NUMBER',
                    accessor: 'ticketReference',
                    message: historyMessages.TABLE_DEALER_TICKET_NUMBER,
                    Cell: row => <Link to={{
                        pathname: `/ase/view/${get(row, 'value.uuid')}`,
                        redirectedFromInternal: true
                    }}>{get(row, 'value.pfxNumber') ? formatTicketNumber(get(row, 'value.pfxNumber'), get(row, 'value.sfxNumber')) : ''}</Link>
                },
                {
                    id: 'SG_REQUESTOR',
                    accessor: 'solutionGroup',
                    message: historyMessages.TABLE_SG_REQUESTOR,
                    Cell: e =>
                        <div>{get(e, 'value.name', '')}</div>
                },
                {
                    id: 'ASSIGNED_SG',
                    accessor: 'assignedSolutionGroup',
                    message: historyMessages.TABLE_ASSIGNED_SOLUTION_GROUP,
                    Cell: e =>
                        <div>{get(e, 'value.name', '')}</div>
                },
                {
                    id: 'LUP_QS_ASSOCIATED',
                    accessor: 'lupQsAssociated',
                    message: historyMessages.TABLE_IT_LUP_QS_ASSOCIATED,
                },
                {
                    id: 'DOCUMENTATION_UPDATE',
                    accessor: 'docUpdateNeeded',
                    message: historyMessages.TABLE_IT_DOCUMENTATION_UPDATE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {documentationUpdate.map((key, index) =>
                                <option key={index} value={key}>
                                    {commonMessages[key] ? intl.formatMessage(commonMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{commonMessages[e.value] ? intl.formatMessage(commonMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'LAST_UPDATE',
                    accessor: 'lastUpdate',
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    message: historyMessages.TABLE_IT_LAST_STATUS_DATE,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'LAST_EDITOR',
                    accessor: 'lastEditor',
                    message: historyMessages.TABLE_IT_LAST_EDITOR,
                }
            ],
        };
    }

    selectMultipleOption = value => {
        this.props.updateAseUserInternalTicketHistoryPreference(this.props.loggedUser.ipn,
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    render() {
        const {intl, asePreferences} = this.props;
        const {columnLibrary} = this.state;

        if (asePreferences.isLoading) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...historyMessages.INTERNAL_TICKET_TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold"><FormattedMessage {...historyMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columnLibrary.filter(item => !get(asePreferences, 'menuInternalHistory', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={'%s ' + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={'%s ' + intl.formatMessage(commonMessages.SELECTED)}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <HistoryAseInternalTicketTable
                    columns={columnLibrary.filter(item => !get(asePreferences, 'menuInternalHistory', {default: []}).includes(item.id))}/>
            </div>
        );
    }
}

HistoryAsePage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    asePreferences: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    asePreferences: state.asePreferences,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateAseUserInternalTicketHistoryPreference,
})(injectIntl(HistoryAsePage))), ['AS_SG_EDITOR']);
