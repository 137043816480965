import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import {clearAlerts} from '../../../actions/alertsActions';
import ListAseTable from './ListAseTable';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import {
    domains,
    issueOrigins,
    getFormStates,
} from '../../../constants/Utils';
import {lockAseTicket, resetRedirectAfterAseLockTicket} from '../../../actions/ase/actionAseLockTicket';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import commonMessages from '../../../intl/common/commonMessages';
import listMessages from '../../../intl/afterSales/listMessages';
import {updateAseUserListPreference} from '../../../actions/ase/actionAsePreferences';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import {StyledPicky} from '../../common/StyledComponents';
import {moduleRoles} from '../../../utils/roles';
import checkRoles from '../../common/roleChecker/RoleChecker';
import issueOriginMessages from '../../../intl/afterSales/issueOriginMessages';

const domain = domains.ASE;

class ListAsePage extends Component {
    constructor(props) {
        super(props);
        const {intl, loggedUser, loggedUser: {roles}} = props;

        const isSgEditor = moduleRoles.isSGEditorAse(roles);
        const isEditor = !isSgEditor && moduleRoles.isEditorAse(roles);
        const isDealer = !isSgEditor && !isEditor && moduleRoles.isDealerAse(roles);

        this.state = {
            ratchet: false,
            columnLibrary: [
                {
                    id: 'CREATED_BY',
                    accessor: 'creatorIpn',
                    message: listMessages.TABLE_CREATED_BY,
                },
                {
                    id: 'CLIENT_ID',
                    accessor: 'clientId',
                    message: listMessages.TABLE_CLIENT_ID,
                    Cell: e => e.value && String(e.value).padStart(6, "0")
                },
                {
                    id: 'REG_DIR',
                    accessor: 'region',
                    message: listMessages.TABLE_REG_DIR,
                },
                {
                    id: 'DEALER_NUMBER',
                    accessor: 'dealerNumber',
                    message: listMessages.TABLE_DEALER_NUMBER,
                    Cell: e => e.value && String(e.value).padStart(8, "0")
                },
                {
                    id: 'ORDER_CREATION_TIME',
                    accessor: 'created',
                    message: listMessages.TABLE_ORDER_CREATION_TIME,
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'ISSUE_ORIGIN',
                    accessor: 'issueOrigin',
                    message: listMessages.TABLE_ISSUE_ORIGIN,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {issueOrigins.map((key, index) =>
                                <option key={index} value={key}>
                                    {issueOriginMessages[key] ? intl.formatMessage(issueOriginMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{issueOriginMessages[e.value] ? intl.formatMessage(issueOriginMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'STATUS',
                    accessor: 'status',
                    message: listMessages.TABLE_STATUS,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {getFormStates(domain).map((key, index) =>
                                <option key={index} value={key}>
                                    {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'SOLUTION_GROUP',
                    accessor: 'solutionGroup',
                    message: listMessages.TABLE_SOLUTION_GROUP,
                    Cell: e =>
                        <div> {e.value && !e.value.canForwardToDealer && isDealer ? '' : get(e, 'value.name', '')}</div>
                },
                {
                    id: 'LAST_UPDATE',
                    accessor: 'lastUpdate',
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    message: listMessages.TABLE_LAST_STATUS_DATE,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'LAST_EDITOR',
                    accessor: 'lastEditor',
                    message: listMessages.TABLE_LAST_EDITOR,
                }
            ]
        };
    }

    componentWillUnmount() {
        this.props.resetRedirectAfterAseLockTicket();
    }

    handleEditClick = ({id}) => {
        //mozna nacitat info do listu a delat lokalni kontrolu jestli je ticket zamceny
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockAseTicket(id);
            this.setState({ratchet: true});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateAseUserListPreference(this.props.loggedUser.ipn, this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    render() {
        const {intl, asePreferences, lockTicket, location} = this.props;
        const {columnLibrary} = this.state;
        if (lockTicket.redirect && this.state.ratchet) {
            this.props.history.push(`/ase/edit/${lockTicket.uuid}`);
        }
        if (asePreferences.isLoading) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...listMessages.TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span
                            className="font-weight-bold"><FormattedMessage {...listMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columnLibrary.filter(item => !get(asePreferences, 'menuList', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <ListAseTable
                    columns={columnLibrary.filter(item => !get(asePreferences, 'menuList', {default: []}).includes(item.id))}
                    handleEditClick={this.handleEditClick}
                    location={location}
                />
            </div>
        );
    }
}

ListAsePage.propTypes = {
    lockTicket: PropTypes.object.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    updateAseUserListPreference: PropTypes.func.isRequired,
    lockAseTicket: PropTypes.func.isRequired,
    resetRedirectAfterAseLockTicket: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    asePreferences: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    asePreferences: state.asePreferences,
    lockTicket: state.aseListOrderLockTicketCheck
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateAseUserListPreference,
    lockAseTicket,
    resetRedirectAfterAseLockTicket,
})(injectIntl(ListAsePage))), ['AS_DEALER', 'AS_EDITOR', 'AS_SG_EDITOR']);
