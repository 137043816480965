import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const adminAnsweringSchema = (state = {
    correlationId: null,
    isAnsweringSchemaLoading: false,
    isAnsweringSchemaCreating: false,
    answeringSchemaSubmittedAndShouldRedirect: false,
    initWithOldData: false,
    created: false,
    AnsweringSchema: {},
    AnsweringSchemaOptions: [],
    availableName: true,
}, action) => {
    switch (action.type) {
        case aseWebsocketCommands.ASE_ANSWERING_SCHEMA_REDIRECT_RESET: {
            return Object.assign({}, state, {
                answeringSchemaSubmittedAndShouldRedirect: false,
            });
        }
        case aseWebsocketCommands.ASE_ANSWERING_SCHEMA_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isAnsweringSchemaLoading: false,
                isAnsweringSchemaCreating: false,
                answeringSchemaSubmittedAndShouldRedirect: false,
                initWithOldData: false,
                created: false,
                AnsweringSchema: {},
                availableName: true
            });
        }
        case aseWebsocketCommands.ASE_ANSWERING_SCHEMA_UPDATE_PENDING:
        case aseWebsocketCommands.ASE_ANSWERING_SCHEMA_CREATE_PENDING: {
            console.log('changing state... pending');
            return Object.assign({}, state, {
                isAnsweringSchemaCreating: true,
                isAnsweringSchemaLoading: true,
                answeringSchemaSubmittedAndShouldRedirect: true,
            });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_ACCEPTED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isAnsweringSchemaCreating: false,
                created: true,
                isAnsweringSchemaLoading: true,
                correlationId
            });
        }

        case aseWebsocketCommands.ASE_ANSWERING_SCHEMA_SEND: {
            const { correlationId, answeringSchema, error } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isAnsweringSchemaLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isAnsweringSchemaLoading: false,
                correlationId,
                answeringSchema
            });
        }

        case aseWebsocketCommands.ASE_ANSWERING_SCHEMA_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isAnsweringSchemaCreating: false,
                isAnsweringSchemaLoading: false,
                correlationId,
            });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isAnsweringSchemaCreating: false,
                created: false,
                isAnsweringSchemaLoading: false,
                answeringSchemaSubmittedAndShouldRedirect: false,
                initWithOldData: true,
                correlationId,
            });
        }

        case aseWebsocketCommands.ASE_ANSWERING_SCHEMA_DETAIL_FETCH_PENDING: {
            return Object.assign({}, state, { isAnsweringSchemaLoading: true });
        }

        case aseWebsocketCommands.ASE_ADMIN_SOLUTION_GROUPS_SEND: {
            const { solutionGroups } = action.payload;
            return Object.assign({}, state, { AnsweringSchemaOptions: solutionGroups });
        }

        case aseWebsocketCommands.ASE_ANSWERING_SCHEMA_SAVE_NEW_ERROR:
        case aseWebsocketCommands.ASE_ANSWERING_SCHEMA_SAVE_UPDATE_ERROR: {
            return Object.assign({}, state, { isAnsweringSchemaLoading: false });
        }

        default:
            return state;
    }
};
