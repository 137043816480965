import {defineMessages} from 'react-intl';

//TODO
export const messages = defineMessages({
    TMP: {
        id: 'ase.textBox.option.test',
        defaultMessage: 'Test'
    },
});

export default messages;
