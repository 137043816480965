import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import get from 'get-value';
import {SubmissionError} from 'redux-form';
import moment from 'moment';
import Loader from '../../Loader';
import {
    ASE_EXPORT_FORM_FIELD_CHARSET,
    ASE_EXPORT_FORM_FIELD_TYPE,
    XLS
} from '../../../constants/exportConstant';
import {FORM_FIELD_PFX, FORM_FIELD_SFX} from '../../../constants/formConstants';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {moduleRoles} from '../../../utils/roles';
import {fetchAseDealers} from '../../../actions/ase/actionAseDealers';
import {createAseExport, downloadExport, fetchAseExport} from '../../../actions/ase/actionAseExport';
import ExportAseForm from './ExportAseForm';

class ExportAsePage extends Component {
    componentDidMount() {
        if (moduleRoles.isEditorAse(this.props.roles)) {
            this.props.fetchAseDealers();
        }
        this.props.fetchAseExport();
    }

    handleSubmit = (values) => {
        const pfx = get(values, 'pfxNumber', '').length;
        const sfx = get(values, 'sfxNumber', '').length;

        if (pfx > 0 && sfx === 0) {
            throw new SubmissionError({
                [FORM_FIELD_SFX]: 'Fill complete ticket number'
            })
        }

        if (pfx === 0 && sfx > 0) {
            throw new SubmissionError({
                [FORM_FIELD_PFX]: 'Fill complete ticket number'
            })
        }
        const temp = JSON.parse(JSON.stringify((({assigned, dealer = {}, status, solutionGroup, formType, vehicleFamily}) => {
            const {dealerNumber, dealerName} = dealer;
            return ({
                lastEditor: assigned ? assigned.trim() : undefined,
                dealerNumber: dealerNumber ? dealerNumber.trim() : undefined,
                dealerName: dealerName ? dealerName.trim() : undefined,
                status,
                solutionGroup,
                formType,
                vehicleFamily: vehicleFamily ? vehicleFamily.trim() : undefined,
            });
        })(values)));

        if (pfx || sfx) {
            temp.ticketNumber = `${values.pfxNumber}-${values.sfxNumber}`;
        }

        const exportFilter = {};
        exportFilter.filter = [];
        for (let key in temp) {
            if (temp.hasOwnProperty(key)) {
                if (key === 'lastEditor') {
                    temp[key].split(' ').forEach(element => exportFilter.filter.push({id: key, value: element}));

                } else {
                    exportFilter.filter.push({id: key, value: temp[key]})
                }
            }
        }

        if (values.from) {
            exportFilter.from = moment(values.from).add(12, 'hours').toISOString().substr(0, 10);
        }
        if (values.to) {
            exportFilter.to = moment(values.to).add(12, 'hours').toISOString().substr(0, 10);
        }
        exportFilter.type = values.type;
        if (!exportFilter.type) {
            exportFilter.type = XLS;
        }
        exportFilter.charset = values.charset;
        this.props.createAseExport(exportFilter);
    };

    downloadExport = () => {
        this.props.downloadExport(this.props.exportRecord);
    };

    render() {
        const {isLoadingDealer, isLoadingExport, charSets} = this.props;

        if (isLoadingDealer || isLoadingExport) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage id="ase.export.header" defaultMessage="Export"/>}/>
                <ExportAseForm onSubmit={this.handleSubmit}
                               form={'aseExportForm'}
                               downloadExport={this.downloadExport}
                               initialValues={{
                                   [ASE_EXPORT_FORM_FIELD_TYPE]: XLS,
                                   [ASE_EXPORT_FORM_FIELD_CHARSET]: charSets[0]
                               }}
                />
            </div>
        );
    }
}

ExportAsePage.propTypes = {
    isLoadingDealer: PropTypes.bool.isRequired,
    isLoadingExport: PropTypes.bool.isRequired,
    exportRecord: PropTypes.object.isRequired,
    fetchAseDealers: PropTypes.func.isRequired,
    fetchAseExport: PropTypes.func.isRequired,
    createAseExport: PropTypes.func.isRequired,
    downloadExport: PropTypes.func.isRequired,
    charSets: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    isLoadingDealer: state.aseExportDealers.isLoadingDealer,
    isLoadingExport: state.aseExportTickets.isLoadingExport,
    exportRecord: state.aseExportTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
    roles: state.profile.userDetail.roles,
});

export default checkRoles(connect(mapStateToProps, {
    fetchAseDealers,
    fetchAseExport,
    createAseExport,
    downloadExport,
})(injectIntl(ExportAsePage)), ['AS_EDITOR', 'AS_SG_EDITOR']);
