import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import RecurrentProblemAseContainer from './RecurrentProblemAseContainer';
import recurrentProblemMessages from '../../../intl/afterSales/recurrentProblemMessages';
import {domains} from '../../../constants/Utils';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import SelectCountryGroup from '../../common/country/SelectCountryGroup';
import styled from 'styled-components';
import {moduleRoles} from '../../../utils/roles';
import PropTypes from 'prop-types';
import Get from 'get-value';
import {connect} from 'react-redux';
import {storeSelectedCountryGroup} from '../../../actions/ase/actionAseRecurrentProblem';

const domain = domains.ASE;

const Label = styled.label`
    font-weight: bold;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

class RecurrentProblemAsePage extends Component {
    componentDidMount() {
        if (this.props.selectedCountryGroup === '') {
            this.props.storeSelectedCountryGroup(
                Get(moduleRoles.getEditorGroups(domain, this.props.roles), '0.group', this.props.group)
            );
        }
    }

    handleCountryGroupSelection = countryGroup => this.props.storeSelectedCountryGroup(countryGroup.value);

    render() {
        const {roles, selectedCountryGroup} = this.props;
        let groups = this.props.group ? [{group: this.props.group, value: true}] : [];
        if (moduleRoles.isEditorAse(this.props.roles)) {
            groups = moduleRoles.getEditorGroups(domain, roles);
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...recurrentProblemMessages.TITLE}/>}/>
                {moduleRoles.isEditorAse(roles) &&
                <StyledForm className="form-group">
                    <div className="form-row mb-sm-3">
                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                            <Label>
                                <FormattedMessage {...recurrentProblemMessages.SELECT_COUNTRY}/>
                            </Label>
                            <SelectCountryGroup groups={groups}
                                                handleCountryGroupSelection={this.handleCountryGroupSelection}
                                                selectedCountryGroup={selectedCountryGroup}/>
                        </div>
                    </div>
                </StyledForm>
                }
                {groups.map((group, index) =>
                    (selectedCountryGroup === group.group &&
                        <div key={index}>
                            <RecurrentProblemAseContainer domain={domain} selectedGroup={selectedCountryGroup}/>
                        </div>
                    )
                )}
            </div>
        );
    }
}

RecurrentProblemAsePage.propTypes = {
    roles: PropTypes.object.isRequired,
    group: PropTypes.string,
    selectedCountryGroup: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    group: state.profile.userDetail.group,
    selectedCountryGroup: state.aseRecurrentProblem.selectedCountryGroup
});

export default checkRoles(connect(mapStateToProps, {
    storeSelectedCountryGroup
})(injectIntl(RecurrentProblemAsePage)), ['AS_DEALER', 'AS_EDITOR', 'AS_SG_EDITOR']);
