import {defineMessages} from 'react-intl';

const messages = defineMessages({
    DATE_FROM: {
        id: 'ase.internal.export.form.dateFrom',
        defaultMessage: 'Date From'
    },
    DATE_TO: {
        id: 'ase.internal.export.form.dateTo',
        defaultMessage: 'Date To'
    },
    STATUS: {
        id: 'ase.internal.export.form.status',
        defaultMessage: 'Status'
    },
    IS_ASSIGNED_TO: {
        id: 'ase.internal.export.form.isAssignedTo',
        defaultMessage: 'Is Assigned To'
    },
    HAS_BEEN_ASSIGNED_TO: {
        id: 'ase.internal.export.form.hasBeenAssignedTo',
        defaultMessage: 'Has Been Assigned To'
    },
    REQUEST_TYPE: {
        id: 'ase.internal.export.form.requestType',
        defaultMessage: 'Type Of Request'
    },
    VEHICLE_FAMILY: {
        id: 'ase.internal.export.form.vehicleFamily',
        defaultMessage: 'Vehicle Family'
    },
    ENGINE_TYPE: {
        id: 'ase.internal.export.form.engineType',
        defaultMessage: 'Engine Type'
    },
    GEARBOX_TYPE: {
        id: 'ase.internal.export.form.gearboxType',
        defaultMessage: 'Gearbox Type'
    },
    NOD_ID: {
        id: 'ase.internal.export.form.nodId',
        defaultMessage: 'Nod Id'
    },
    DOCUMENT_ID: {
        id: 'ase.internal.export.form.repairMethod',
        defaultMessage: 'Repair Method'
    },
    ROOT_CAUSE: {
        id: 'ase.internal.export.form.rootCause',
        defaultMessage: 'Root Cause'
    },
    DOCUMENTATION_UPDATE_NEEDED: {
        id: 'ase.internal.export.form.documentationUpdateNeeded',
        defaultMessage: 'Documentation update needed'
    },
    TYPE: {
        id: 'ase.internal.export.form.type',
        defaultMessage: 'Type'
    },
    CHARSET: {
        id: 'ase.internal.export.form.charset',
        defaultMessage: 'Charset'
    },
    XLS: {
        id: 'ase.internal.export.form.xls',
        defaultMessage: 'XLS'
    },
    CSV: {
        id: 'ase.internal.export.form.csv',
        defaultMessage: 'CSV'
    },
    EXPORT_STATUS: {
        id: 'ase.internal.export.exportStatus',
        defaultMessage: 'Export status:'
    },
    LAST_EXPORT: {
        id: 'ase.internal.export.lastExport',
        defaultMessage: 'Last export:'
    }
});

export default messages;
