import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'ase.history.title',
        defaultMessage: 'History Ase Ticket'
    },
    INTERNAL_TICKET_TITLE: {
        id: 'ase.history.titleInternalTicket',
        defaultMessage: 'History After Sales - Internal Ticket'
    },
    VISIBLE_COLUMNS: {
        id: 'ase.history.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    TABLE_TICKET_NUMBER: {
        id: 'ase.history.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_SOLUTION_GROUP: {
        id: 'ase.history.table.solutionGroup',
        defaultMessage: 'Assigned SG'
    },
    TABLE_ISSUE_ORIGIN: {
        id: 'ase.history.table.issueOrigin',
        defaultMessage: 'Issue Origin'
    },
    TABLE_ORDER_CREATION_TIME: {
        id: 'ase.history.table.creationDate',
        defaultMessage: 'Creation Date'
    },
    TABLE_CLIENT_ID: {
        id: 'ase.history.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_DEALER_NUMBER: {
        id: 'ase.history.table.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    TABLE_STATUS: {
        id: 'ase.history.table.status',
        defaultMessage: 'Status'
    },
    TABLE_LAST_STATUS_DATE: {
        id: 'ase.history.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TABLE_LAST_EDITOR: {
        id: 'ase.history.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TABLE_CREATED_BY: {
        id: 'ase.history.table.createdBy',
        defaultMessage: 'Created by (IPN)'
    },
    TABLE_REG_DIR: {
        id: 'ase.history.table.regDir',
        defaultMessage: 'Reg. Dir.'
    },
    TABLE_INTERNAL_TICKET_NUMBER: {
        id: 'ase.internalHistory.table.ticketNo',
        defaultMessage: 'Internal Ticket No.'
    },
    TABLE_REQUEST_STATUS: {
        id: 'ase.internalHistory.table.requestStatus',
        defaultMessage: 'Request Status'
    },
    TABLE_DEALER_TICKET_NUMBER: {
        id: 'ase.internalHistory.table.dealerTicketNo',
        defaultMessage: 'Dealer Ticket No.'
    },
    TABLE_IT_CREATION_TIME: {
        id: 'ase.internalHistory.table.itCreationDate',
        defaultMessage: 'IT Creation Date'
    },
    TABLE_IT_ISSUE_ORIGIN: {
        id: 'ase.internalHistory.table.issueOrigin',
        defaultMessage: 'Issue Origin'
    },
    TABLE_IT_PRIORITY: {
        id: 'ase.internalHistory.table.priority',
        defaultMessage: 'Priority'
    },
    TABLE_IT_CREATED_BY_IPN: {
        id: 'ase.list.table.createdByIpn',
        defaultMessage: 'Created by (IPN)'
    },
    TABLE_IT_LUP_QS_ASSOCIATED: {
        id: 'ase.internalHistory.table.lupQsAssociated',
        defaultMessage: 'LUP QS Associated'
    },
    TABLE_IT_DOCUMENTATION_UPDATE: {
        id: 'ase.internalHistory.table.documentationUpdate',
        defaultMessage: 'Documentation Update'
    },
    TABLE_SG_REQUESTOR: {
        id: 'ase.internalHistory.table.sgRequestor',
        defaultMessage: 'SG Requestor'
    },
    TABLE_ASSIGNED_SOLUTION_GROUP: {
        id: 'ase.internalHistory.table.assignedSolutionGroup',
        defaultMessage: 'Assigned SG'
    },
    TABLE_IT_LAST_STATUS_DATE: {
        id: 'ase.internalHistory.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TABLE_IT_LAST_EDITOR: {
        id: 'ase.internalHistory.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
});

export default messages;
