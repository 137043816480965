import {ASE_SET_LOCK, ASE_SET_UNLOCK,} from '../../constants/websocketCommands';

export const ASE_RESET_REDIRECT = 'ASE_RESET_REDIRECT';
export const ASE_SET_LOCK_TICKET = 'ASE_SET_LOCK_TICKET';

export const lockAseTicket = uuid => dispatch =>
    dispatch({
        type: ASE_SET_LOCK_TICKET,
        aseWebsocket: true,
        command: ASE_SET_LOCK,
        payload: {uuid}
    });

export const unlockAseTicket = uuid => dispatch =>
    dispatch({
        type: ASE_SET_UNLOCK,
        aseWebsocket: true,
        command: ASE_SET_UNLOCK,
        payload: {uuid}
    });

export const resetRedirectAfterAseLockTicket = () => dispatch => dispatch({type: ASE_RESET_REDIRECT});
