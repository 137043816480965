import React from 'react';
import styled from 'styled-components';
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import {change, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {
    downloadInternalTicketAttachment,
} from '../../../../../../actions/ase/actionAseInternalTicketAttachments';
import {
    FORM_ATTACHMENTS_SECTION,
    FORM_FIELD_ATTACHMENTS,
    FORM_TICKET_SECTION
} from '../../../../../../constants/formConstants';
import formMessages from '../../../../../../intl/afterSales/formMessages';
import {downloadAttachment} from '../../../../../../actions/ase/actionAseAttachments';

const StyledA = styled.a`
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  :hover&&{
        color: #ffcc33;
    };
`;

const StyledHeaderDiv = styled.div`
    &&{background-color: #e9ecef;};
`;

const StyledInfoDiv = styled.div`
    &&{background-color: #f8f9fa;};
`;

const UploadedAttachments = (props) => {
    const {attachments, label, downloadInternalTicketAttachment, downloadAttachment, ticketId, change, userRights} = props;

    const downloadFile = (uuid, name, dealerTicketId) => (e) => {
        e.preventDefault();
        if (dealerTicketId) {
            downloadAttachment(uuid, dealerTicketId, name);
        } else {
            downloadInternalTicketAttachment(uuid, ticketId, name);
        }
    };

    const deleteAttachment = (uuid) => (e) => {
        e.preventDefault();
        const attachmentField = `${FORM_ATTACHMENTS_SECTION}.${FORM_FIELD_ATTACHMENTS}`;
        change(attachmentField, attachments.filter(item => item.uuid !== uuid));
    };

    return (
        <div>
            <StyledHeaderDiv className="border-bottom border-bottom-secondary">
                <label className="col-form-label pl-0 font-weight-bold ml-3">
                    {!!label && label instanceof Object && <FormattedMessage {...label}/>}
                </label>
                <div className="pl-2 py-auto"/>
            </StyledHeaderDiv>
            {attachments.map((att, index) =>
                <div key={index} className="row m-0 align-items-stretch">
                    <StyledInfoDiv className="col-sm-12 col-lg-4 flex-column border-bottom">
                        <div>
                            <FormattedDate value={new Date(att.created)}/> <FormattedTime
                            value={new Date(att.created)}/>{' '}
                            <span className="font-weight-bold">{att.creatorName}</span>
                            {' '}({att.creatorIpn})
                        </div>
                    </StyledInfoDiv>
                    <div className="col-sm-12 col-lg-4 flex-column border-bottom">
                        <StyledA onClick={downloadFile(att.uuid, att.name, att.dealerTicketId)}>{`${att.name}`}</StyledA>
                    </div>
                    {userRights.canDeleteAttachments && <div className="col-sm-12 col-lg-4 flex-column border-bottom">
                        <StyledA onClick={deleteAttachment(att.uuid)}><FormattedMessage {...formMessages.DELETE_ATTACHMENT}/></StyledA>
                    </div>}
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state, initialProps) => ({
    ticketId: formValueSelector(initialProps.form)(state, `${FORM_TICKET_SECTION}.uuid`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    downloadInternalTicketAttachment: (uuid, ticketId, name) => dispatch(downloadInternalTicketAttachment(uuid, ticketId, name)),
    downloadAttachment: (uuid, ticketId, name) => dispatch(downloadAttachment(uuid, ticketId, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadedAttachments);
