import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    // TOP MENU
    ADMINISTRATION: {
        id: 'admin.topMenu.administration',
        defaultMessage: 'Administration'
    },
    ASE: {
        id: 'admin.topMenu.ase',
        defaultMessage: 'After Sales Engineering'
    },

    // ADMIN SIDE MENU
    ADMIN_COMMON_SETTING: {
        id: 'admin.sideMenu.commonSetting',
        defaultMessage: 'Common setting'
    },
    ADMIN_COMMON_SETTING_CLIENT_ID: {
        id: 'admin.sideMenu.commonSetting.clientIdSetting',
        defaultMessage: 'Client ID setting'
    },

    //ase
    ADMIN_ASE_NOTIFICATION: {
        id: 'admin.sideMenu.ase.dealerNotification',
        defaultMessage: 'Dealer notification setting'
    },
    ADMIN_ASE_MAIN_PAGE_ALERTS: {
        id: 'admin.sideMenu.ase.mainPageAlerts',
        defaultMessage: 'Main page alerts'
    },
    ADMIN_ASE_RECURRENT_PROBLEMS: {
        id: 'admin.sideMenu.ase.recurrentProblems',
        defaultMessage: 'Recurrent Problems setting'
    },
    ADMIN_ASE_WORKING_HOURS: {
        id: 'admin.sideMenu.ase.workingHours',
        defaultMessage: 'Working hours'
    },
    ADMIN_ASE_TICKET_SETTING: {
        id: 'admin.sideMenu.ase.ticketSetting',
        defaultMessage: 'Ticket setting'
    },
    ADMIN_ASE_AUTH_MANAGEMENT_ADMIN: {
        id: 'admin.sideMenu.ase.authorizationManagement.admin',
        defaultMessage: 'Admin'
    },
    ADMIN_ASE_AUTH_MANAGEMENT_DEALER: {
        id: 'admin.sideMenu.ase.authorizationManagement.dealer',
        defaultMessage: 'Dealer'
    },
    ADMIN_ASE_AUTH_MANAGEMENT_EDITOR: {
        id: 'admin.sideMenu.ase.authorizationManagement.editor',
        defaultMessage: 'Editor'
    },
    ADMIN_ASE_DEFAULT_COUNTRY_ROLE: {
        id: 'admin.sideMenu.ase.defaultCountryRole',
        defaultMessage: 'Default country role'
    },
    ADMIN_ASE_AUTH_MANAGEMENT: {
        id: 'admin.sideMenu.ase.authorizationManagement',
        defaultMessage: 'Authorization management'
    },
    ADMIN_ASE_MULTI_LEVEL_ESCALATION_SCHEMA: {
        id: 'admin.sideMenu.ase.multiLevelEscalationSchema',
        defaultMessage: 'Multi-level Escalation Schema'
    },
    ADMIN_ASE_SOLUTION_GROUP_NEW: {
        id: 'admin.sideMenu.ase.solutionGroupManagement.solutionGroupNew',
        defaultMessage: 'New solution group'
    },
    ADMIN_ASE_SOLUTION_GROUP_LIST: {
        id: 'admin.sideMenu.ase.solutionGroupManagement.solutionGroupList',
        defaultMessage: 'Solution group list'
    },
    ADMIN_ASE_SOLUTION_GROUP_USER_MANAGEMENT: {
        id: 'admin.sideMenu.ase.solutionGroupMangement.userManagement',
        defaultMessage: 'User management'
    },
    ADMIN_ASE_ESCALATION_SCHEMA_NEW: {
        id: 'admin.sideMenu.ase.escalationSchemaManagement.escalationSchemaNew',
        defaultMessage: 'New escalation schema'
    },
    ADMIN_ASE_ESCALATION_SCHEMA_LIST: {
        id: 'admin.sideMenu.ase.escalationSchemaManagement.escalationSchemaList',
        defaultMessage: 'Escalation schema list'
    },
    ADMIN_ASE_ANSWERING_SCHEMA_NEW: {
        id: 'admin.sideMenu.ase.answeringSchemaManagement.answeringSchemaNew',
        defaultMessage: 'New answering schema'
    },
    ADMIN_ASE_ANSWERING_SCHEMA_LIST: {
        id: 'admin.sideMenu.ase.answeringSchemaManagement.answeringSchemaList',
        defaultMessage: 'Answering schema list'
    },
    ADMIN_ASE: {
        id: 'admin.sideMenu.ase',
        defaultMessage: 'After Sales Engineering'
    },

    // AS SIDE MENU
    ASE_LIST: {
        id: 'ase.sideMenu.list',
        defaultMessage: 'List'
    },
    ASE_HISTORY: {
        id: 'ase.sideMenu.history',
        defaultMessage: 'History'
    },
    ASE_INTERNAL_SEARCH: {
        id: 'ase.sideMenu.internal.search',
        defaultMessage: 'Search Engine'
    },
    ASE_INTERNAL_EXPORT: {
        id: 'ase.sideMenu.internal.export',
        defaultMessage: 'Export'
    },
    ASE_FULLTEXT_SEARCH: {
        id: 'ase.sideMenu.fullTextSearch',
        defaultMessage: 'Fulltext Search'
    },
    ASE_EXPORT: {
        id: 'ase.sideMenu.export',
        defaultMessage: 'Export'
    },
    ASE_RECURRENT_PROBLEM: {
        id: 'ase.sideMenu.recurrentProblem',
        defaultMessage: 'Recurrent Problem'
    },
    ASE_NEW: {
        id: 'ase.sideMenu.new',
        defaultMessage: 'New'
    },
});

export default messages;
