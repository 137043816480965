import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import AdministrationMainPage from './AdministrationMainPage';
import NotFoundPage from '../NotFoundPage';
import MainPageAlertPage from './mainPageAlert/MainPageAlertPage';
import PopUpPage from './recurrentProblem/RecurrentProblemsPage';
import WorkingHoursPage from './workingHours/WorkingHoursPage';
import AdminRightsManagementPage from './right/AdminRightsManagementPage';
import EditorRightsManagementPage from './right/EditorRightsManagementPage';
import DealerRightsManagementPage from './right/DealerRightsManagementPage';
import ClientIdPage from './clientId/ClientIdPage';
import {
    ADMINISTRATION_PATH,
    ADMINISTRATION_CLIENT_ID_PATH,
    ADMINISTRATION_ASE_ADMIN_PATH,
    ADMINISTRATION_ASE_DEALER_PATH,
    ADMINISTRATION_ASE_EDITOR_PATH,
    ADMINISTRATION_ASE_DEFAULT_COUNTRY_ROLE_PATH,
    ADMINISTRATION_ASE_GROUP_NEW_PATH,
    ADMINISTRATION_ASE_SOLUTION_GROUP_PATH,
    ADMINISTRATION_ASE_SOLUTION_GROUP_LIST_PATH,
    ADMINISTRATION_ASE_SOLUTION_GROUP_USER_PATH,
    ADMINISTRATION_ASE_RECURRENT_PROBLEM_PATH,
    ADMINISTRATION_ASE_MAIN_PAGE_PATH,
    ADMINISTRATION_ASE_WORKING_HOUR_PATH,
    ADMINISTRATION_ASE_DEALER_NOTIFICATION_PATH,
    ADMINISTRATION_ASE_TICKET_SETTING_PATH,
    ADMINISTRATION_ASE_ESCALATION_SCHEMA_NEW_PATH,
    ADMINISTRATION_ASE_ESCALATION_SCHEMA_PATH,
    ADMINISTRATION_ASE_ESCALATION_SCHEMA_LIST_PATH,
    ADMINISTRATION_ASE_ANSWERING_SCHEMA_NEW_PATH,
    ADMINISTRATION_ASE_ANSWERING_SCHEMA_PATH, ADMINISTRATION_ASE_ANSWERING_SCHEMA_LIST_PATH,
} from '../../routes/paths';
import {Domain} from '../common/Domain';
import NotificationPage from './notifications/NotificationPage';
import TicketSettingsPage from './ticketSettings/TicketSettingsPage';
import DefaultCountryRolePage from './defaultCountryRole/DefaultCountryRolePage';
import checkRoles from '../common/roleChecker/RoleChecker';
import ListSolutionGroupPage from './solutionGroup/list/ListSolutionGroupPage';
import NewSolutionGroupPage from './solutionGroup/detail/NewSolutionGroupPage';
import ViewSolutionGroupPage from './solutionGroup/detail/ViewSolutionGroupPage';
import EditSolutionGroupPage from './solutionGroup/detail/EditSolutionGroupPage';
import SolutionGroupUserRightsManagementPage from './right/SolutionGroupUserRightsManagementPage';
import NewEscalationSchemaPage from './escalationSchema/detail/NewEscalationSchemaPage';
import ListEscalationSchemaPage from './escalationSchema/list/ListEscalationSchemaPage';
import ListAnsweringSchemaPage from './answeringSchema/list/ListAnsweringSchemaPage';
import NewAnsweringSchemaPage from './answeringSchema/detail/NewAnsweringSchemaPage';
import EditEscalationSchemaPage from './escalationSchema/detail/EditEscalationSchemaPage';
import ViewEscalationSchemaPage from './escalationSchema/detail/ViewEscalationSchemaPage';
import EditAnsweringSchemaPage from './answeringSchema/detail/EditAnsweringSchemaPage';
import ViewAnsweringSchemaPage from './answeringSchema/detail/ViewAnsweringSchemaPage';

class Administration extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={ADMINISTRATION_CLIENT_ID_PATH}
                       component={checkRoles(ClientIdPage, ['AS_ADMIN'])}/>
                <Route exact path={ADMINISTRATION_PATH}
                       component={checkRoles(AdministrationMainPage, ['AS_ADMIN', 'AS_POWER_USER', 'AS_SG_ADMIN'])}/>


                <Route exact path={ADMINISTRATION_ASE_RECURRENT_PROBLEM_PATH}
                       render={() => <Domain component={checkRoles(PopUpPage, ['AS_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_MAIN_PAGE_PATH}
                       render={() => <Domain component={checkRoles(MainPageAlertPage, ['AS_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_WORKING_HOUR_PATH}
                       render={() => <Domain component={checkRoles(WorkingHoursPage, ['AS_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_DEALER_NOTIFICATION_PATH}
                       render={() => <Domain component={checkRoles(NotificationPage, ['AS_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_TICKET_SETTING_PATH}
                       render={() => <Domain component={checkRoles(TicketSettingsPage, ['AS_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_ADMIN_PATH}
                       render={() => <Domain component={checkRoles(AdminRightsManagementPage, ['AS_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_DEALER_PATH}
                       render={() => <Domain component={checkRoles(DealerRightsManagementPage, ['AS_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_EDITOR_PATH}
                       render={() => <Domain component={checkRoles(EditorRightsManagementPage, ['AS_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_DEFAULT_COUNTRY_ROLE_PATH}
                       render={() => <Domain component={checkRoles(DefaultCountryRolePage, ['AS_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_GROUP_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewSolutionGroupPage, ['AS_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_SOLUTION_GROUP_PATH + '/:view(view)/:solutionGroupUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewSolutionGroupPage, ['AS_POWER_USER', 'AS_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_SOLUTION_GROUP_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListSolutionGroupPage, ['AS_POWER_USER', 'AS_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_SOLUTION_GROUP_PATH + '/:edit(edit)/:solutionGroupUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditSolutionGroupPage, ['AS_POWER_USER', 'AS_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_SOLUTION_GROUP_USER_PATH}
                       render={() => <Domain component={checkRoles(SolutionGroupUserRightsManagementPage, ['AS_POWER_USER', 'AS_SG_ADMIN'])}/>}/>

                <Route exact path={ADMINISTRATION_ASE_ESCALATION_SCHEMA_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewEscalationSchemaPage, ['AS_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_ESCALATION_SCHEMA_PATH + '/:edit(edit)/:escalationSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditEscalationSchemaPage, ['AS_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_ESCALATION_SCHEMA_PATH + '/:view(view)/:escalationSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewEscalationSchemaPage, ['AS_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_ESCALATION_SCHEMA_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListEscalationSchemaPage, ['AS_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_ANSWERING_SCHEMA_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewAnsweringSchemaPage, ['AS_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_ANSWERING_SCHEMA_PATH + '/:edit(edit)/:answeringSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditAnsweringSchemaPage, ['AS_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_ANSWERING_SCHEMA_PATH + '/:view(view)/:answeringSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewAnsweringSchemaPage, ['AS_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ASE_ANSWERING_SCHEMA_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListAnsweringSchemaPage, ['AS_POWER_USER'])}/>}/>

                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

Administration.propTypes = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(Administration);
