import React from 'react';
import 'react-table/react-table.css';
import {PrimaryButton, SecondaryButton} from '../../common/Button';
import {FormSection, reduxForm} from 'redux-form';
import buttonMessages from '../../../intl/common/buttonMessages';
import {FormattedMessage} from 'react-intl';
import BorderRadius from '../../common/styled/BorderRadius';
import {
    DOCUMENTATION_UPDATE_SECTION,
    FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_SEARCH_OPTION_SECTION,
    FORM_TICKET_SECTION,
    FORM_VEHICLE_DETAIL_SECTION
} from '../../../constants/formConstants';
import {domains} from '../../../constants/Utils';
import RequestorInformationSection from './section/RequestorInformationSection';
import VehicleDetailInternalSearchSection from './section/VehicleDetailInternalSearchSection';
import SearchOptionInternalSearchSection
    from './section/SearchOptionInternalSearchSection';
import ProblemDescriptionInternalSearchSection from './section/ProblemDescriptionInternalSearchSection';
import DocumentationUpdateInternalSearchSection from './section/DocumentationUpdateInternalSearchSection';

class InternalTicketSearchAseForm extends React.Component {
    reset = () => {
        this.props.resetForm();
        this.props.reset();
    }

    render() {
        return (
            <BorderRadius size={5} className="border border-secondary p-4 bg-white">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <this.props.form className="form-group">
                            <div className="col-12 pb-2">
                                <FormSection name={FORM_TICKET_SECTION}>
                                    <RequestorInformationSection domain={domains.ASE} />
                                </FormSection>
                                <FormSection name={FORM_VEHICLE_DETAIL_SECTION}>
                                    <VehicleDetailInternalSearchSection domain={domains.ASE} />
                                </FormSection>
                                <FormSection name={FORM_PROBLEM_DESCRIPTION_SECTION}>
                                    <ProblemDescriptionInternalSearchSection domain={domains.ASE} />
                                </FormSection>
                                <FormSection name={DOCUMENTATION_UPDATE_SECTION}>
                                    <DocumentationUpdateInternalSearchSection domain={domains.ASE} />
                                </FormSection>
                                <FormSection name={FORM_SEARCH_OPTION_SECTION}>
                                    <SearchOptionInternalSearchSection />
                                </FormSection>
                            </div>
                            <div className="col-12 pb-2">
                                <PrimaryButton type="button"
                                               className="btn mr-2 float-right"
                                               onClick={this.props.handleSubmit}>
                                    <FormattedMessage {...buttonMessages.SEARCH}/>
                                </PrimaryButton>
                                <SecondaryButton type="button"
                                                 className="btn float-right mr-2 bg-white"
                                                 onClick={this.reset}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </SecondaryButton>
                            </div>
                        </this.props.form>
                    </div>
                </div>
            </BorderRadius>
        )
    }
}

export default reduxForm({})(InternalTicketSearchAseForm);
