import React from 'react';
import styled from 'styled-components';
import {reduxForm, FormSection} from 'redux-form'
import {
    DOCUMENTATION_UPDATE_SECTION,
    FORM_ATTACHMENTS_SECTION,
    FORM_EDITORS_NOTES_SECTION_AS,
    FORM_MESSAGES_SECTION,
    FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_TICKET_SECTION,
    FORM_VEHICLE_DETAIL_SECTION,
} from '../../../../constants/formConstants';
import TicketSection from './sections/ticket/TicketSection';
import AttachmentSection from './sections/attachments/AttachmentSection';
import TicketStatusHistory from '../../../common/TicketStatusHistory';
import VehicleDetailSection from './sections/vehicleDetail/VehicleDetailSection';
import ProblemDescriptionSection from './sections/problemDescription/ProblemDescriptionSection';
import MessagesSection from './sections/messagesSection/MessagesSection';
import RenderSyncErrors from '../../../common/RenderSyncErrors';
import {scrollContentWrapperToTop} from '../../../../utils/utils';
import SubmitButtons from './sections/SubmitButtons';
import formMessages from '../../../../intl/afterSales/formMessages';
import {aseFieldLabelMapping} from '../../../../constants/aseFieldLabelMapping';
import PropTypes from 'prop-types';
import EditorsNotesSection from './sections/editorsNotes/EditorsNotesSection';
import {moduleRoles} from '../../../../utils/roles';
import DocumentationUpdateSection from './sections/documentationUpdate/DocumentationUpdateSection';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const MethodRepairLaborTimeMasterForm = (props) => {
    const {form, userRights, readOnly, handleSubmit, reqFields, editMode, userRoles} = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={aseFieldLabelMapping}/>
            <FormSection name={FORM_TICKET_SECTION}>
                <TicketSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_VEHICLE_DETAIL_SECTION}>
                <VehicleDetailSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_PROBLEM_DESCRIPTION_SECTION}>
                <ProblemDescriptionSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_MESSAGES_SECTION}>
                <MessagesSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            {userRights.canReadEditorsNotes && <FormSection name={FORM_EDITORS_NOTES_SECTION_AS}>
                <EditorsNotesSection form={form} userRights={userRights} readOnly={readOnly}/>
            </FormSection>}
            {userRights.canViewDocumentationUpdateSection && moduleRoles.isSGEditorLVL23Ase(userRoles) && <FormSection name={DOCUMENTATION_UPDATE_SECTION}>
                <DocumentationUpdateSection form={form} userRights={userRights} reqFields={reqFields}
                                              readOnly={readOnly}/>
            </FormSection>}
            <FormSection name={FORM_ATTACHMENTS_SECTION}>
                <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <TicketStatusHistory form={form}/>
            <SubmitButtons form={form} onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop}
                           userRights={userRights} readOnly={readOnly} editMode={editMode}/>
        </StyledDiv>
    )
}

MethodRepairLaborTimeMasterForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

export default reduxForm({})(MethodRepairLaborTimeMasterForm);
