import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    MISSING: {
        id: 'ase.customerEffect.option.missing',
        defaultMessage: 'Missing'
    },
    NOT_CORRECT: {
        id: 'ase.customerEffect.option.notCorrect',
        defaultMessage: 'Not correct'
    },
    WRONG_TRANSLATION: {
        id: 'ase.customerEffect.option.wrongTranslation',
        defaultMessage: 'Wrong translation'
    },
    EASY_BASKET_NOT_COMPLETED: {
        id: 'ase.customerEffect.option.easyBasketNotCompleted',
        defaultMessage: 'Easy Basket not complete'
    }
});

export default messages;
