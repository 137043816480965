import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import 'react-table/react-table.css';
import {debounce} from 'throttle-debounce';
import {Link} from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import Table from '../../common/table/TicketTable';
import {IconEdit, PrimaryIcon} from '../../common/Button';
import {fetchAseListPageData} from '../../../actions/ase/actionAseListPage';
import {moduleRoles} from '../../../utils/roles';
import listMessages from '../../../intl/afterSales/listMessages';
import {domains} from '../../../constants/Utils';
import get from 'get-value';
import {editAseListButtonVisibility} from '../../../constants/aseTicketRights';

const DOMAIN = domains.ASE;

class ListAseTable extends React.Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(props.location.search);
        const loadedState = this.loadStateFromSessionStorage({
            listASDefaultPageSize: 10,
            listASDefaultSort: [{id: 'TICKET_NUMBER', desc: true}],
            listASDefaultFilters: [],
        });

        let selectedUrlParams = []
        if(urlParams.has('status')){
            selectedUrlParams.push(
                {
                    id: 'STATUS',
                    value: urlParams.get('status') ? urlParams.get('status') : '',
                }
            )
        }
        if(urlParams.has('group')){
            selectedUrlParams.push(
                {
                    id: 'GROUP',
                    value: urlParams.get('group').split(',')
                }
            )
        }
        if(urlParams.has('sgroup')){
            selectedUrlParams.push(
                {
                    id: 'SGROUP',
                    value: urlParams.get('sgroup').split(',')
                }
            )
        }

        if (selectedUrlParams.length > 0) {
            this.state = {
                ...loadedState,
                listASDefaultFilters: selectedUrlParams
            }
        } else {
            this.state = {
                ...loadedState,
                listASDefaultFilters: loadedState.listASDefaultFilters.filter(item => !['GROUP', 'SGROUP'].includes(item.id))
            }
        }

        this.filtering = false;
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^listAS*/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage = () => {
        for (let key in this.state) {
            if (this.state.hasOwnProperty(key) && key.match(/^listAS*/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchAseListPageData(state.pageSize, state.sorted, state.cursor, state.isNextFromPivot, this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            listASDefaultPageSize: state.pageSize,
            listASDefaultSort: state.sorted,
            listASDefaultFilters: state.filtered
        });
    };

    fetchStrategy = state => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date || filterItem.id === 'ORDER_CREATION_TIME') {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const {columns, data, pages, isLoading, handleEditClick, isNextPage, isNextFromPivot} = this.props;
        return (
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'TICKET_NUMBER',
                                    accessor: 'ticketNumber',
                                    message: listMessages.TABLE_TICKET_NUMBER,
                                    Cell: row => <Link to={`view/${row.original.id}`}>{row.value}</Link>
                                },
                                ...columns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm"
                                                            title="Edit"
                                                            type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                }
                            ]}
                            isLoading={isLoading}
                            data={data}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={this.state.listASDefaultFilters}
                            defaultSorting={this.state.listASDefaultSort}
                            defaultPageSize={this.state.listASDefaultPageSize}
                            buttonVisibility={editAseListButtonVisibility[moduleRoles.isDealerAse(this.props.userRoles) ? 'DEALER' : 'EDITOR']}
                            editorGroups={moduleRoles.isEditorAse(this.props.userRoles) ? moduleRoles.getEditorGroups(domains.ASE, this.props.userRoles).map(e => e.group) : false}
                            sgPermissions = {get(this.props.userRoles, `${domains.ASE}.sgEditor.sgPermission`)}
                            checkInternationalTicketsForSGEditors
                            toolSortable={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ListAseTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    fetchAseListPageData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    userRoles: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.aseListPage.isTableLoading,
    data: state.aseListPage.listData,
    isNextPage: state.aseListPage.isNextPage,
    isNextFromPivot: state.aseListPage.isNextFromPivot,
    pages: state.aseListPage.pages,
    userRoles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {
    fetchAseListPageData
})(ListAseTable);
