import React from 'react';
import styled from 'styled-components';
import {formValueSelector, change, Field} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
    DOCUMENTATION_UPDATE_SECTION,
    FORM_FIELD_DOC_UPDATE_NEEDED,
    FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_FIELD_ROOT_CAUSE,
    FORM_FIELD_CUSTOMER_RISK,
    FORM_LABEL_DOC_UPDATE_NEEDED,
    FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_LABEL_ROOT_CAUSE,
    FORM_LABEL_CUSTOMER_RISK,
} from '../../../../../../constants/formConstants';
import formMessages from '../../../../../../intl/afterSales/formMessages';
import DatePickerComponent from '../../../../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import commonMessages from '../../../../../../intl/common/commonMessages';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {customerRisk, rootCauses} from '../../../../../../constants/Utils';
import rootCauseMessages from '../../../../../../intl/afterSales/rootCauseMessages';
import customerRiskMessages from '../../../../../../intl/afterSales/customerRiskMessages';
import {isEmpty} from 'lodash';
import get from 'get-value';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const DocumentationUpdateSection = (props) => {
    const { readOnly, reqFields, userRights, docUpdateNeeded, solutionGroupInfo } = props;

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.DOCUMENTATION_UPDATE_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_DOC_UPDATE_NEEDED]}
                                                        field={FORM_FIELD_DOC_UPDATE_NEEDED}
                                                        options={['YES', 'NO']}
                                                        intlMessages={commonMessages}
                                                        isRequired={(isEmpty(solutionGroupInfo) ? (reqFields.includes(FORM_FIELD_DOC_UPDATE_NEEDED)) : (reqFields.includes(FORM_FIELD_DOC_UPDATE_NEEDED) && get(solutionGroupInfo, "groups", []).length == 0))}
                                                        isDisabled={readOnly || !userRights.canEditInternalDocumentationUpdate}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_ROOT_CAUSE]}
                                                        field={FORM_FIELD_ROOT_CAUSE}
                                                        options={rootCauses}
                                                        intlMessages={rootCauseMessages}
                                                        isRequired={(isEmpty(solutionGroupInfo) ? (reqFields.includes(FORM_FIELD_ROOT_CAUSE)) : (reqFields.includes(FORM_FIELD_ROOT_CAUSE) && get(solutionGroupInfo, "groups", []).length == 0))}
                                                        isDisabled={readOnly || !userRights.canEditInternalDocumentationUpdate}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <Field component={DatePickerComponent}
                               selectsStart
                               label={formMessages[FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION]}
                               name={FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}
                               isDisabled={readOnly || (docUpdateNeeded !== 'YES') || !userRights.canEditInternalDocumentationUpdate}
                        />
                    </div>
                    {/* <div className="col-xl-3 col-md-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_LUP_QS_ASSOCIATED]}
                                                  field={FORM_FIELD_LUP_QS_ASSOCIATED}
                                                  maxLength={25}
                                                  isDisabled={readOnly || !userRights.canEditInternalDocumentationUpdate}
                                                  isRequired={reqFields.includes(FORM_FIELD_LUP_QS_ASSOCIATED)}
                                                  isSmall
                        />
                    </div> */}
                    <div className="col-xl-3 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_CUSTOMER_RISK]}
                                                        field={FORM_FIELD_CUSTOMER_RISK}
                                                        options={customerRisk}
                                                        intlMessages={customerRiskMessages}
                                                        isRequired={(isEmpty(solutionGroupInfo) ? (reqFields.includes(FORM_FIELD_CUSTOMER_RISK)) : (reqFields.includes(FORM_FIELD_CUSTOMER_RISK) && get(solutionGroupInfo, "groups", []).length == 0))}
                                                        isDisabled={readOnly || !userRights.canEditInternalDocumentationUpdate}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )

}

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    userRoles: state.profile.userDetail.roles,
    docUpdateNeeded: selector(initialProps.form, state, `${DOCUMENTATION_UPDATE_SECTION}.${FORM_FIELD_DOC_UPDATE_NEEDED}`),
    solutionGroupInfo: state.aseInternalTicket.solutionGroupInfo,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(DocumentationUpdateSection);
