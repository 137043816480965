import {modules} from '../constants/Utils';

export const CONNECT_ASE_WEBSOCKET_PENDING = 'CONNECT_ASE_WEBSOCKET_PENDING';
export const CONNECT_ASE_WEBSOCKET_SUCCESS = 'CONNECT_ASE_WEBSOCKET_SUCCESS';
export const CONNECT_ASE_WEBSOCKET_ERROR = 'CONNECT_ASE_WEBSOCKET_ERROR';
export const CONNECT_ADMIN_WEBSOCKET_PENDING = 'CONNECT_ADMIN_WEBSOCKET_PENDING';
export const CONNECT_ADMIN_WEBSOCKET_SUCCESS = 'CONNECT_ADMIN_WEBSOCKET_SUCCESS';
export const CONNECT_ADMIN_WEBSOCKET_ERROR = 'CONNECT_ADMIN_WEBSOCKET_ERROR';
export const CONNECT_BVM_WEBSOCKET_PENDING = 'CONNECT_BVM_WEBSOCKET_PENDING';
export const CONNECT_BVM_WEBSOCKET_SUCCESS = 'CONNECT_BVM_WEBSOCKET_SUCCESS';
export const CONNECT_BVM_WEBSOCKET_ERROR = 'CONNECT_BVM_WEBSOCKET_ERROR';
export const PING_WEBSOCKET = 'ping';

export function connectWebsockets(token) {
    return dispatch => {
        dispatch({type: CONNECT_ASE_WEBSOCKET_PENDING, payload: {token}});
        dispatch({type: CONNECT_ADMIN_WEBSOCKET_PENDING, payload: {token}});
        dispatch({type: CONNECT_BVM_WEBSOCKET_PENDING, payload: {token}});
    };
}

export function pingWebsocket(module) {
    let moduleSocket = '';

    switch (module) {
        case modules.ADMINISTRATION: {
            moduleSocket = 'adminWebsocket';
            break;
        }
        case modules.BVM: {
            moduleSocket = 'bvmWebsocket';
            break;
        }
        case modules.ASE: {
            moduleSocket = 'aseWebsocket';
            break;
        }
        default: {
            moduleSocket = 'default';
            break;
        }
    }

    return dispatch => {
        dispatch({
            type: PING_WEBSOCKET,
            command: PING_WEBSOCKET,
            [moduleSocket]: true
        });
    };
}
