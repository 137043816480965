import {
    SOLUTION_GROUP_FORM,
    FORM_FIELD_SG_NAME
} from './formConstants';

export const editReqFields = {
    'DEFAULT': {
        'EDITOR': {
            [SOLUTION_GROUP_FORM]: [
                FORM_FIELD_SG_NAME,
            ],
        }
    },
};

export const createReqFields = {
    'DEFAULT': {
        [SOLUTION_GROUP_FORM]: [
            FORM_FIELD_SG_NAME,
        ],
    }
};

