import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const fetchAseDealers = () => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_DEALERS_GET,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_DEALERS_GET,
        payload: {}
    });
};
