import { Log, UserManager } from '@oidc/client';
import {HOME_PATH, LOGOUT_PATH} from '../routes/paths';
import { initAxios } from '../api/rest';
import {logger} from './winstonLogger';

export const REFERRER_LOCAL_STORAGE = 'dog.tr.referrer';
export const REDIR_RATCHET_LOCAL_STORAGE = 'dog.tr.redirection.ratchet';
export const REDIR_TIME_SESSION_STORAGE = 'dog.tr.redirection.time';

const userManagerSettings = () => {
    return {
        client_id: process.env.REACT_APP_IDP_CLIENT_ID,
        redirect_uri: `${window.location.origin}/`,
        post_logout_redirect_uri: `${window.location.origin}${LOGOUT_PATH}`,
        // silent_redirect_uri: `${window.location.origin}/silent-renewal.html`,
        automaticSilentRenew: false,
        monitorSession: false,
        authority: process.env.REACT_APP_IDP_AUTHORITY,
        response_type: 'code',
        scope: process.env.NODE_ENV === 'production' ? 'openid alliance_profile' : 'openid profile email vectury-user',
        metadata: {
            issuer: process.env.REACT_APP_IDP_ISSUER,
            authorization_endpoint: process.env.REACT_APP_IDP_AUTHORIZATION_ENDPOINT,
            token_endpoint: process.env.REACT_APP_IDP_TOKEN_ENDPOINT,
            userinfo_endpoint: process.env.REACT_APP_IDP_USERINFO_ENDPOINT,
            jwks_uri: process.env.REACT_APP_IDP_JWKS_URI,
            end_session_endpoint: process.env.REACT_APP_IDP_END_SESSION_ENDPOINT,
        },
    }
};

const singletonUserManager = ( function() {
    if (process.env.NODE_ENV === 'development') {
        Log.logger = console;
        Log.level = Log.DEBUG;
    } else {
        Log.logger = logger;
        Log.level = Log.ERROR;
    }
    const mgr = new UserManager(userManagerSettings());
    mgr.events.addUserLoaded(function(user){
        initAxios(user);
    });

    return mgr;
}());

export const authenticateAndRedirect = async (err) => {
    logger.info("LOG009");
    if(err){
        logger.error("LOG012", err);
    }
    await checkRedirectionLoop();
    localStorage.setItem(REFERRER_LOCAL_STORAGE, window.location.pathname + window.location.search);
    await new Promise(resolve => setTimeout(resolve, 200));
    await createUserManager().clearStaleState();
    return await createUserManager().signinRedirect();
};

export const treatEmptyIdpToken = async () => {
    const redirectionRatchet = localStorage.getItem(REDIR_RATCHET_LOCAL_STORAGE);
    if(!redirectionRatchet){
        logger.error("LOG010");
        console.log("Invalid token returned from IDP, reauthenticate...");
        localStorage.setItem(REDIR_RATCHET_LOCAL_STORAGE, true);
        await createUserManager().removeUser();
        return authenticateAndRedirect();
    } else {
        alert("Incorrect IDP token received. Please contact your support for further investigation.");
        logger.error("LOG007");
    }
};

const checkRedirectionLoop = async () => {
    logger.info("LOG014");
    if (sessionStorage.hasOwnProperty(REDIR_TIME_SESSION_STORAGE)) {
        let value = JSON.parse(sessionStorage.getItem(REDIR_TIME_SESSION_STORAGE));
        if(value.timestamp + 60000 < new Date().getTime()){
            sessionStorage.setItem(REDIR_TIME_SESSION_STORAGE, JSON.stringify({timestamp: new Date().getTime(), count: 0}))
        } else if ( value.count < 10) {
            sessionStorage.setItem(REDIR_TIME_SESSION_STORAGE, JSON.stringify({timestamp: value.timestamp, count: value.count + 1}))
        } else {
            logger.error("LOG015");
            await new Promise(resolve => setTimeout(resolve, 1000));
            alert("Too many IDP requests, please wait a minute and try again. If the problem still persists, contact your support.");
        }
    } else {
        sessionStorage.setItem(REDIR_TIME_SESSION_STORAGE, JSON.stringify({timestamp: new Date().getTime(), count: 0}))
    }
};

export const processAuthenticationRedirect = async () => {
    const user = await createUserManager().signinRedirectCallback();
    const lastVisitedPath = localStorage.getItem(REFERRER_LOCAL_STORAGE) || HOME_PATH;
    logger.info("LOG004", { user, lastVisitedPath });
    return { user, lastVisitedPath };
};

export const getAuthenticatedUser = async () => await createUserManager().getUser();

const createUserManager = () => {
    logger.info("LOG008");
    return singletonUserManager;
};

export const initiateLogout = async () => {
    await createUserManager().signoutRedirect();
    console.log("User logged out.");
};
