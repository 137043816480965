import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import SideMenu from './SideMenu';
import PropTypes from 'prop-types';

class SideBar extends Component {
    render() {
        const {store} = this.props;

        //TODO Tests for ase SideMenu
        return (
            <Switch>
                <Route path="/administration" render={() => (<SideMenu store={store} administration/>)}/>
                <Route path="/ase" render={() => (<SideMenu store={store} ase/>)}/>
            </Switch>
        );
    }
}

SideBar.propTypes = {
    store: PropTypes.any,
};

export default (SideBar);
