import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    REPAIR_METHOD: {
        id: 'ase.internalTicketIssueOrigin.option.repairMethod',
        defaultMessage: 'Repair method'
    },
    LABOR_TIME: {
        id: 'ase.internalTicketIssueOrigin.option.issueOrigin',
        defaultMessage: 'Labor time'
    },
});

export default messages;
