import get from 'get-value';
import {ASE_LIST_FETCH_PENDING} from '../../actions/ase/actionAseListPage';
import {
    ASE_TICKET_LOCKED,
    ASE_TICKET_LOCKED_ERROR,
    ASE_TICKET_LOCKED_FATAL
} from '../../constants/websocketCommands';
import {ASE_RESET_REDIRECT, ASE_SET_LOCK_TICKET} from '../../actions/ase/actionAseLockTicket';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const aseListPage = (state = {
    correlationId: null,
    isLoading: false,
    isTableLoading: false,
    listData: [],
    pages: 0,
    isNextFromPivot: true,
    isNextPage: false,
}, action) => {
    switch (action.type) {
        case ASE_LIST_FETCH_PENDING: {
            return Object.assign({}, state, {isTableLoading: true});
        }
        case aseWebsocketCommands.ASE_TICKET_LIST_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    listData: get(action, 'payload.result.data', {default: []}),
                    pages: get(action, 'payload.result.pages', {default: 0}),
                    isNextFromPivot: get(action, 'payload.result.isNextFromPivot', false),
                    isNextPage: get(action, 'payload.result.isNextPage', false),
                    correlationId
                });
            }
        }
        case aseWebsocketCommands.ASE_TICKET_LIST_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {isTableLoading: false, correlationId});
            }
        }
        default:
            return state;
    }
};

export const aseListOrderLockTicketCheck = (state = {
    correlationId: null,
    isTicketLocking: false,
    redirect: false,
}, action) => {
    switch (action.type) {
        case ASE_RESET_REDIRECT: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case ASE_TICKET_LOCKED_ERROR:
        case ASE_TICKET_LOCKED_FATAL: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case ASE_TICKET_LOCKED: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: true,
            });
        }
        case ASE_SET_LOCK_TICKET: {
            const {uuid} = action.payload;
            return Object.assign({}, state, {
                isTicketLocking: true,
                redirect: false,
                uuid,
            });
        }
        default:
            return state;
    }
};
