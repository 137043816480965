import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    fetchAnsweringSchema,
    redirectReset,
    resetAnsweringSchema,
    updateAnsweringSchema
} from '../../../../actions/admin/actionAnsweringSchema';
import Loader from '../../../Loader';
import {ANSWERING_SCHEMA_FORM} from '../../../../constants/formConstants';
import { editReqFields } from '../../../../constants/answeringSchemaRequiredFields';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {FormattedMessage, injectIntl} from 'react-intl';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {
    initLoadedAnsweringSchema,
    transformUpdateAnsweringSchema
} from '../../../../utils/answeringSchemaUtils';
import {fetchEscalationSchemaSGOptions} from '../../../../actions/admin/actionEscalationSchemaOptions';
import AnsweringSchemaForm from './forms/AnsweringSchemaForm';
import {getAdminAnsweringSchemaPath} from '../../../../routes/pathResolver';


class EditAnsweringSchemaPage extends Component {

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetAnsweringSchema(this.props.domain);
        this.props.fetchEscalationSchemaSGOptions(this.props.domain);
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchAnsweringSchema(this.props.domain, this.props.match.params.answeringSchemaUuid, this.props.loggedUser.ipn);
        }
    }

    handleSubmit = (values) => {
        const dataForSend = transformUpdateAnsweringSchema(this.props.domain, values);
        this.props.updateAnsweringSchema(this.props.domain, dataForSend);
    };

    componentWillUnmount() {
        this.props.redirectReset(this.props.domain);
    }

    render() {
        const {
            answeringSchemaData,
            domain,
            intl: {formatMessage},
        } = this.props;

        const {
            answeringSchemaSubmittedAndShouldRedirect: redirecting,
            isAnsweringSchemaCreating: creating,
            isAnsweringSchemaLoading: loading,
            initWithOldData
        } = answeringSchemaData;



        let formType = false;
        if (get(answeringSchemaData, 'answeringSchema.name', { default: false })) {
            formType = ANSWERING_SCHEMA_FORM;
        }

        if (redirecting && !creating && !loading && get(answeringSchemaData, 'answeringSchema.solutionGroupId', { default: false })) {
            console.log('edit loaded and redirecting :(');
            return <Redirect to={{
                pathname: `${getAdminAnsweringSchemaPath(domain)}/view/${answeringSchemaData.answeringSchema.formType + "-" + answeringSchemaData.answeringSchema.solutionGroupId}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (loading || creating || !formType) {
            return <Loader/>;
        }

        let initializationValue;
        if (!creating && !loading && !redirecting && initWithOldData){
            initializationValue = initLoadedAnsweringSchema(domain, answeringSchemaData, formatMessage);
        } else {
            initializationValue = initLoadedAnsweringSchema(domain, answeringSchemaData, formatMessage);
        }

        const requiredFields = editReqFields['DEFAULT']['EDITOR'][formType];
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.answeringSchema.title"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Answering schema"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <AnsweringSchemaForm
                            form={formType}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            domain={domain}
                            edit={true}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

EditAnsweringSchemaPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetAnsweringSchema: PropTypes.func.isRequired,
    fetchAnsweringSchema: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateAnsweringSchema: PropTypes.func.isRequired,
    intl: PropTypes.object,
    answeringSchemaData: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    location: PropTypes.object,
    userDetail: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    answeringSchemaData: state.adminAnsweringSchema,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchAnsweringSchema,
    updateAnsweringSchema,
    resetAnsweringSchema,
    redirectReset,
    fetchEscalationSchemaSGOptions,
})(injectIntl(EditAnsweringSchemaPage))), ['AS_POWER_USER']);
