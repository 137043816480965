import {
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DEALER_NO,
    FORM_FIELD_EMAIL,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_TELEPHONE_NO,
    FORM_FIELD_VIN,
    MR_TM_ASE_FORM,
    FORM_FIELD_ISSUE_ORIGIN,
    FORM_FIELD_DOC_UPDATE_NEEDED,
    FORM_FIELD_ROOT_CAUSE,
    FORM_FIELD_CUSTOMER_EFFECT,
    FORM_FIELD_CUSTOMER_RISK,
    FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY,
} from './formConstants';

export const editReqFields = {
    'DEFAULT': {
        'DEALER': {
            [MR_TM_ASE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_VIN,
                FORM_FIELD_ISSUE_ORIGIN,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_CUSTOMER_EFFECT,
                FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY
            ]
        },
        'EDITOR': {
            [MR_TM_ASE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_VIN,
                FORM_FIELD_ISSUE_ORIGIN,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_DOC_UPDATE_NEEDED,
                FORM_FIELD_ROOT_CAUSE,
                FORM_FIELD_CUSTOMER_EFFECT,
                FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY,
                FORM_FIELD_CUSTOMER_RISK
            ]
        }
    }
};

export const createReqFields = {
    'DEFAULT': {
        [MR_TM_ASE_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_VIN,
            FORM_FIELD_ISSUE_ORIGIN,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_CUSTOMER_EFFECT,
            FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY
        ]
    }
};

