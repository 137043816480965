import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    ASE: {
        id: 'ase.internalTicketRole.option.yes',
        defaultMessage: 'ASE'
    },
    ADD: {
        id: 'ase.internalTicketRole.option.add',
        defaultMessage: 'ADD'
    },
    CIT: {
        id: 'ase.internalTicketRole.option.cit',
        defaultMessage: 'CIT'
    },
    TECHLINE: {
        id: 'ase.internalTicketRole.option.techline',
        defaultMessage: 'Techline'
    },
    WARRANTY: {
        id: 'ase.internalTicketRole.option.warranty',
        defaultMessage: 'Warranty'
    }
});

export default messages;
