import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DEALER_NO,
    FORM_FIELD_NAME_TD_SEARCH,
    FORM_FIELD_REGION,
    FORM_LABEL_CLIENT_ID,
    FORM_LABEL_DEALER_NAME,
    FORM_LABEL_DEALER_NO,
    FORM_LABEL_NAME,
    FORM_LABEL_REGION,

} from '../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import {Field} from 'redux-form';
import fulltextSearchMessages from '../../../../intl/afterSales/fulltextSearchMessages';
import {FormattedMessage} from 'react-intl';
import {AsyncSelectField} from '../../../common/FormField';
import {handleFetchSearchClientIdsError} from '../../../../actions/admin/actionAdminClientIds';
import {injectIntl} from 'react-intl';
import commonMessages from '../../../../intl/common/commonMessages';
import {handleClientIdSearch} from '../../../../utils/utils';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const DealerSection = (props) => {
    const {handleFetchSearchClientIdsError, intl: {formatMessage}} = props;

    return (
        <>
            <h3 className="text-left ml-4">
                <FormattedMessage {...fulltextSearchMessages.DEALER_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_DEALER_NAME]}
                                                  field={FORM_FIELD_DEALER_NAME}
                                                  maxLength={200}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_DEALER_NO]}
                                                  field={FORM_FIELD_DEALER_NO}
                                                  maxLength={8}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_NAME]}
                                                  field={FORM_FIELD_NAME_TD_SEARCH}
                                                  maxLength={200}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <label className="col-form-label pl-0 font-weight-bold">
                            <FormattedMessage {...fulltextSearchMessages[FORM_LABEL_CLIENT_ID]}/>
                        </label>
                        <Field component={AsyncSelectField}
                               name={FORM_FIELD_CLIENT_ID}
                               isClearable
                               useCustomStyles
                               noOptionsMessage={() => formatMessage(commonMessages.NONE)}
                               loadingMessage={() => formatMessage(commonMessages.LOADING)}
                               loadOptions={(inputValue, callback) => {handleClientIdSearch(inputValue, callback, handleFetchSearchClientIdsError)}}
                        />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_REGION]}
                                                  field={FORM_FIELD_REGION}
                                                  maxLength={6}
                                                  isSmall/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state) => ({
    roles: state.profile.userDetail.roles,
    lastEditors: state.aseFullTextSearchPage.lastEditors,
});

export default  connect(mapStateToProps, {handleFetchSearchClientIdsError})(injectIntl(DealerSection));
