import {domains} from '../../constants/Utils';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const fetchEscalationSchemaListPageData = (domain, pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_LIST_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_LIST,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: filtered}
            });
        }

    }
};

export const deleteEscalationSchemaListRow = (domain, id, formType) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_DELETE_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_DELETE,
                payload: {
                    payload:{
                        id: id,
                        escalationSchema: {
                            formType: formType
                        }
                    }
                }
            });
        }
    }
};
