import Scroll from 'react-scroll/modules';
import {SORT_ASC, SORT_DESC, SORT_NONE} from '../constants/Utils';
import {fetchSearchClientIds} from '../actions/admin/actionAdminClientIds';
import _ from 'lodash';
import {FORM_FIELD_FULLTEXT_SEARCH} from '../constants/formConstants';

const computeOrder = (column, sorting) => {
    if (sorting.column === column) {
        if (!sorting.order) {
            return SORT_ASC;
        } else if (sorting.order === SORT_ASC) {
            return SORT_DESC;
        }
        return SORT_NONE;
    }
    return SORT_ASC;
};

export const fillSfxZeros = (number) => ('0').repeat(6 - (number + '').length).concat(number);

export const fillZeros = (count, number) => ('0').repeat(count - (number + '').length).concat(number);

export const fillNumberFiveZero = (number) => {
    const lengthNumber = ( 5 - (number + '').length);
    if (lengthNumber < 1) {
        return number;
    }
    return ('0').repeat(lengthNumber).concat(number);
};

export const formatTicketNumber = (pfxNumber, sfxNumber) => pfxNumber + '-' + fillSfxZeros(sfxNumber);

export const formatInternalTicketNumber = (pfxNumber, sfxNumber) => pfxNumber + '-INT' + fillZeros(7, sfxNumber);

export const computeSorting = (column, sorting) => ({
    column,
    order: computeOrder(column, sorting),
});

export const move = (arr, old_index, new_index) => {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        let k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

export const extractFileExtension = fileName => {
    if (!fileName) {
        return '';
    } else {
        const fileExtension = fileName.match(/^.*\.(.*)$/);
        return fileExtension ? fileExtension[1] : '';
    }
};

export const scrollContentWrapperToTop = () => {
    const scroll = Scroll.animateScroll;
    scroll.scrollToTop({
        duration: 250,
        smooth: true,
        containerId: 'contentWrapper'
    });
};

export function handleClientIdSearch(inputValue, debouncedCallback, errorCallback) {
    fetchSearchClientIds(inputValue).then(
        ({data}) => debouncedCallback(
            data.sort((clientId1, clientId2) => clientId1.localeCompare(clientId2))
                .map(clientId => ({value: clientId, label: clientId}))
        ),
        error => errorCallback(error)
    );
};

export const pruneEmpty = (obj) => {
    return function prune(current) {
        _.forOwn(current, function (value, key) {
            if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value) ||
                (_.isString(value) && _.isEmpty(value)) ||
                (_.isObject(value) && _.isEmpty(prune(value)))) {
                delete current[key];
            }
        });
        return current;
    }(_.cloneDeep(obj));
};

export const mapFiltersForFulltext = (obj) => {
    let props = [];
    for (const [key, value] of Object.entries(obj)) {
        if (value && typeof value === 'object') {
            props = [...props, ...mapFiltersForFulltext(value)];
        } else if (key !== FORM_FIELD_FULLTEXT_SEARCH && value !== '' && value !== null && value !== undefined) {
            props = [...props, {id: key, value: value}];
        }
    }
    return props;
};
