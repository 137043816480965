import React, {Component} from 'react';
import {connect} from 'react-redux';
import get from 'get-value';
import Loader from '../../Loader';
import {editRights} from '../../../constants/aseTicketRights';
import {
    ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    ASE_TICKET_STATUS_NEW, ASE_TICKET_STATUS_READ_BY_SUBSIDIARY,
    FORM_BUTTON_ANSWER,
    FORM_BUTTON_CREATE_INTERNAL_TICKET,
    FORM_BUTTON_ESCALATE, FORM_BUTTON_FORWARD_TO_DEALER,
    FORM_BUTTON_MARK_AS_READ,
    FORM_BUTTON_TAKE_BACK,
} from '../../../constants/formConstants';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    fetchAseTicket,
    resetAseTicket,
    updateAseTicket
} from '../../../actions/ase/actionAseTicket';
import {
    createInternalTicketAseInitData,
    transformUpdateAseTicketForSave
} from '../Utils';
import {
    lockAseTicket,
    resetRedirectAfterAseLockTicket,
    unlockAseTicket
} from '../../../actions/ase/actionAseLockTicket';
import UploadingAseAttachments from './UploadingAseAttachments';
import checkRoles from '../../common/roleChecker/RoleChecker';
import EditLoadTicketDataPage from './EditLoadTicketDataPage';
import {ASE_NEW_INTERNAL_TICKET} from '../../../routes/paths';
import {moduleRoles} from '../../../utils/roles';
import alertMessages from '../../../intl/common/alertMessages';
import {injectIntl} from 'react-intl';


class EditAseTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false, createInternalTicket: false, skippedReadBySubsidiaryStatus: false, fetchedNewTicketData: false};
    }

    componentWillUnmount() {
        const {uuid, redirect} = this.props.lockTicket;
        if (!redirect) {
            this.props.unlockAseTicket(uuid);
        }
        this.props.resetRedirectAfterAseLockTicket();
    }

    componentDidMount() {
        this.props.resetAseTicket();
        this.props.lockAseTicket(this.props.match.params.ticketUuid);

        if (!this.props.location.redirectedAfterUpdate) {
            this.setState({...this.state, fetchedNewTicketData: true});
            this.props.fetchAseTicket(this.props.match.params.ticketUuid);
        }
    }

    answeredToSuspiciousGroup = (submitBy, dataForSend) => {
        if (submitBy === FORM_BUTTON_ANSWER) {
            const assignedSolutionGroup = get(dataForSend, 'ticket.solutionGroup.id');
            return !this.props.previousSolutionGroups.includes(assignedSolutionGroup)
        }
    };

    missingEditorsNotes = (submitBy, dataForSend) => {
        if (submitBy === FORM_BUTTON_ANSWER || submitBy === FORM_BUTTON_ESCALATE) {

            const {loggedUser, ticketData} = this.props;

            const isEditor = moduleRoles.isEditorAse(loggedUser) || moduleRoles.isSGEditorAse(loggedUser);
            const rights = editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status];

            return rights.canAddEditorsNotes && !dataForSend.editorsNote;
        }
    };

    missingMessageToDealer = (submitBy, dataForSend) => {
        return submitBy === FORM_BUTTON_FORWARD_TO_DEALER && !dataForSend.message;
    };

    handleSubmit = (values) => {
        const {submitBy} = values;
        const dataForSend = transformUpdateAseTicketForSave(values);

        if (this.answeredToSuspiciousGroup(submitBy, dataForSend)) {
            const groupName = get(dataForSend, 'ticket.solutionGroup.name');
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ESCALATION_SUSPICIOUS_GROUP_WARN, {groupName}))) {
                return;
            }
        }
        if (this.missingEditorsNotes(submitBy, dataForSend)) {
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ESCALATION_MISSING_EDITORS_NOTES_GROUP_WARN))) {
                return;
            }
        }
        if (this.missingMessageToDealer(submitBy, dataForSend)) {
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ANSWER_TO_DEALER_MISSING_MESSAGE_WARN))) {
                return;
            }
        }
        if (submitBy === FORM_BUTTON_CREATE_INTERNAL_TICKET) {
            const initData = createInternalTicketAseInitData(values.newValues);
            this.setState({
                ...this.state,
                ticketSubmittedAndShouldRedirect: true,
                createInternalTicket: true,
                createInternalTicketData: initData
            })
        } else {
            this.props.updateAseTicket(dataForSend);
            if (!([FORM_BUTTON_MARK_AS_READ, FORM_BUTTON_TAKE_BACK].includes(submitBy))) {
                this.setState({...this.state, ticketSubmittedAndShouldRedirect: true});
            }
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.ticketSubmittedAndShouldRedirect && !this.state.ticketSubmittedAndShouldRedirect
            && prevProps.ticketData.isTicketCreating && !this.props.ticketData.isTicketCreating
            && this.props.ticketData.isTicketLoading) {
            this.props.lockAseTicket(this.props.match.params.ticketUuid);
        }
    }

    render() {
        const {
            ticketData,
            attachmentsData,
            lockTicket,
            loggedUser
        } = this.props;

        if (lockTicket.redirect) {
            return <Redirect to={`/ase/view/${lockTicket.uuid}`}/>;
        }
        if (this.state.createInternalTicket) {
            return <Redirect to={{
                pathname: ASE_NEW_INTERNAL_TICKET,
                initData: this.state.createInternalTicketData,
            }}/>
        }

        let formType = false;
        if (get(ticketData, 'ticket.formType', {default: false})) {
            formType = ticketData.ticket.formType;
        }

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAseAttachments attachments={attachmentsData.attachmentsForUpload}
                                                attachmentsUrl={ticketData.newAttachments}
                                                correlationId={ticketData.correlationId}
                                                isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                                formType={formType}/>
        }

        if (ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || lockTicket.isTicketLocking
            || !formType
        ) {
            return <Loader/>;
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/ase/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (get(ticketData, 'ticket.uuid', {default: false})
            && [ASE_TICKET_STATUS_NEW, ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY].includes(get(ticketData, 'ticket.status', {default: false}))
            && (moduleRoles.isEditorAse(loggedUser) || moduleRoles.isSGEditorAse(loggedUser))
            && !this.state.skippedReadBySubsidiaryStatus
            && this.state.fetchedNewTicketData
        ) {
            this.setState({...this.state, skippedReadBySubsidiaryStatus: true});
            this.props.updateAseTicket({
                ticket: {
                    pfxNumber: ticketData.ticket.pfxNumber,
                    sfxNumber: ticketData.ticket.sfxNumber,
                    status: ASE_TICKET_STATUS_READ_BY_SUBSIDIARY,
                    uuid: ticketData.ticket.uuid,
                }
            });
            return <Loader/>;
        }

        return (
            <EditLoadTicketDataPage formType={formType} handleSubmit={this.handleSubmit}/>
        );
    }
}

const getPreviousSolutionGroups = (state) => {
    const logs = get(state, 'aseTicket.logs') || [];

    const fromIds = logs.map(item => item.solutionGroup && item.solutionGroup.id);
    const toIds = logs.map(item => item.toSolutionGroup && item.toSolutionGroup.id);

    return [...new Set([...fromIds, ...toIds])];
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.aseTicket,
    lockTicket: state.aseLockTicketCheck,
    attachmentsData: state.aseAttachment,
    previousSolutionGroups: getPreviousSolutionGroups(state)
});

EditAseTicketPage.propTypes = {
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchAseTicket: PropTypes.func.isRequired,
    resetAseTicket: PropTypes.func.isRequired,
    lockAseTicket: PropTypes.func.isRequired,
    unlockAseTicket: PropTypes.func.isRequired,
    resetRedirectAfterAseLockTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateAseTicket: PropTypes.func.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchAseTicket,
    resetAseTicket,
    lockAseTicket,
    unlockAseTicket,
    resetRedirectAfterAseLockTicket,
    updateAseTicket,
})(injectIntl(EditAseTicketPage))), ['AS_DEALER', 'AS_EDITOR', 'AS_SG_EDITOR']);

