import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    VEHICLE_SAFETY_IMPACT: {
        id: 'ase.priorityTreatment.option.vehicleSafetyImpact',
        defaultMessage: 'Vehicle safety impact'
    },
    OTS_QUALITY_BLOCAGE: {
        id: 'ase.priorityTreatment.option.otsQualityBlocage',
        defaultMessage: 'OTS / Quality blocage'
    },
    ACTIS_REPAIR_METHOD: {
        id: 'ase.priorityTreatment.option.actisRepairMethod',
        defaultMessage: 'ACTIS / Repair method (ASDRM)'
    },
    VOR_PVI: {
        id: 'ase.priorityTreatment.option.vorPvi',
        defaultMessage: 'VOR / PVI'
    }
});

export default messages;
