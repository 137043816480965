import {domains} from '../../constants/Utils';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const MAIN_MESSAGES_SELECT_GROUP = 'MAIN_MESSAGES_SELECT_GROUP';
export const ASE_MAIN_MESSAGES_FETCH_PENDING = 'ASE_MAIN_MESSAGES_FETCH_PENDING';
export const MAIN_MESSAGE_FETCH_PENDING = 'MAIN_MESSAGE_FETCH_PENDING';
export const MAIN_MESSAGE_SAVE_PENDING = 'MAIN_MESSAGE_SAVE_PENDING';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({type: MAIN_MESSAGES_SELECT_GROUP, payload: countryGroup});

export const fetchMainMessages = (domain, groups) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: ASE_MAIN_MESSAGES_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_MAIN_MESSAGES_GET,
                payload: {groups}
            });
        }
    }
};

export const fetchMainMessage = (domain, group) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: MAIN_MESSAGE_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_MAIN_MESSAGE_GET,
                payload: {group}
            });
        }
    }
};

export const saveMainMessage = (domain, mainMessage) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: MAIN_MESSAGE_SAVE_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_MAIN_MESSAGE_SET,
                payload: {mainMessage}
            });
        }
    }
}
