import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {clearAlerts} from '../../../actions/alertsActions';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import {
    issueOrigins
} from '../../../constants/Utils';
import HistoryAseTable from './HistoryAseTable';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import commonMessages from '../../../intl/common/commonMessages';
import {
    updateAseUserHistoryPreference
} from '../../../actions/ase/actionAsePreferences';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import {StyledPicky} from '../../common/StyledComponents';
import {moduleRoles} from '../../../utils/roles';
import checkRoles from '../../common/roleChecker/RoleChecker';
import historyMessages from '../../../intl/afterSales/historyMessages';
import issueOriginMessages from '../../../intl/afterSales/issueOriginMessages';

class HistoryAsePage extends Component {
    constructor(props) {
        super(props);
        const {intl, loggedUser: {roles}} = props;

        const isSgEditor = moduleRoles.isSGEditorAse(roles);
        const isEditor = !isSgEditor && moduleRoles.isEditorAse(roles);
        const isDealer = !isSgEditor && !isEditor && moduleRoles.isDealerAse(roles);

        this.state = {
            ratchet: true,
            columnLibrary: [
                {
                    id: 'CREATED_BY',
                    accessor: 'creatorIpn',
                    message: historyMessages.TABLE_CREATED_BY,
                },
                {
                    id: 'CLIENT_ID',
                    accessor: 'clientId',
                    message: historyMessages.TABLE_CLIENT_ID,
                    Cell: e => e.value && String(e.value).padStart(6, "0")
                },

                {
                    id: 'REG_DIR',
                    accessor: 'region',
                    message: historyMessages.TABLE_REG_DIR,
                },
                {
                    id: 'DEALER_NUMBER',
                    accessor: 'dealerNumber',
                    message: historyMessages.TABLE_DEALER_NUMBER,
                    Cell: e => e.value && String(e.value).padStart(8, "0")
                },
                {
                    id: 'ORDER_CREATION_TIME',
                    accessor: 'created',
                    message: historyMessages.TABLE_ORDER_CREATION_TIME,
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'ISSUE_ORIGIN',
                    accessor: 'issueOrigin',
                    message: historyMessages.TABLE_ISSUE_ORIGIN,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {issueOrigins.map((key, index) =>
                                <option key={index} value={key}>
                                    {issueOriginMessages[key] ? intl.formatMessage(issueOriginMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{issueOriginMessages[e.value] ? intl.formatMessage(issueOriginMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'STATUS',
                    accessor: 'status',
                    message: historyMessages.TABLE_STATUS,
                    sortable: false,
                    filterable: false,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'SOLUTION_GROUP',
                    accessor: 'solutionGroup',
                    message: historyMessages.TABLE_SOLUTION_GROUP,
                    Cell: e =>
                        <div> {e.value && !e.value.canForwardToDealer && isDealer ? '' : get(e, 'value.name', '')}</div>
                },
                {
                    id: 'LAST_UPDATE',
                    accessor: 'lastUpdate',
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    message: historyMessages.TABLE_LAST_STATUS_DATE,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'LAST_EDITOR',
                    accessor: 'lastEditor',
                    message: historyMessages.TABLE_LAST_EDITOR,
                }
            ],
        };
    }

    selectMultipleOption = value => {
        this.props.updateAseUserHistoryPreference(this.props.loggedUser.ipn,
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    render() {
        const {intl, asePreferences} = this.props;
        const {columnLibrary} = this.state;

        if (asePreferences.isLoading) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...historyMessages.TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold"><FormattedMessage {...historyMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columnLibrary.filter(item => !get(asePreferences, 'menuHistory', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <HistoryAseTable
                    columns={columnLibrary.filter(item => !get(asePreferences, 'menuHistory', {default: []}).includes(item.id))}/>
            </div>
        );
    }
}

HistoryAsePage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    updateAseUserHistoryPreference: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    asePreferences: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    asePreferences: state.asePreferences,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateAseUserHistoryPreference,
})(injectIntl(HistoryAsePage))), ['AS_DEALER', 'AS_EDITOR', 'AS_SG_EDITOR']);
