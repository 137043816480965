import {domains} from '../../constants/Utils';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const ADMIN_POPUP_SELECT_GROUP = 'ADMIN_POPUP_SELECT_GROUP';
export const ADMIN_POPUP_FETCH_PENDING = 'ADMIN_POPUP_FETCH_PENDING';
export const ADMIN_POPUP_SAVE_PENDING = 'ADMIN_POPUP_SAVE_PENDING';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({type: ADMIN_POPUP_SELECT_GROUP, payload: countryGroup});

export const fetchPopUp = (domain, group) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: ADMIN_POPUP_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_POPUP_GET,
                payload: {group}
            });
        }
    }
};

export const savePopUp = (domain, popup) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: ADMIN_POPUP_SAVE_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_POPUP_SET,
                payload: {popup}
            });
        }
    }
}
