import React, {Component} from 'react';
import {connect} from 'react-redux';
import get from 'get-value';
import Loader from '../../Loader';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    fetchAseInternalTicket,
    resetAseInternalTicket,
    updateAseInternalTicket
} from '../../../actions/ase/actionAseInternalTicket';
import {transformUpdateAseInternalTicketForSave} from '../Utils';
import {
    lockAseInternalTicket,
    resetRedirectAfterAseInternalLockTicket,
    unlockAseInternalTicket
} from '../../../actions/ase/actionAseInternalLockTicket';
import UploadingAseInternalTicketAttachments from './UploadingAseInternalTicketAttachments';
import checkRoles from '../../common/roleChecker/RoleChecker';
import EditLoadInternalTicketDataPage from './EditLoadInternalTicketDataPage';
import {
    ASE_INTERNAL_TICKET_STATUS_ANSWERED,
    ASE_INTERNAL_TICKET_STATUS_IN_PROGRESS,
    ASE_INTERNAL_TICKET_STATUS_NEW,
    ASE_INTERNAL_TICKET_STATUS_REOPENED,
    ASE_INTERNAL_TICKET_STATUS_TRANSFERRED,
    FORM_BUTTON_ANSWER,
    FORM_BUTTON_ESCALATE,
    FORM_BUTTON_TRANSFER_TO_REQUESTOR,
} from '../../../constants/formConstants';
import {moduleRoles} from '../../../utils/roles';
import alertMessages from '../../../intl/common/alertMessages';
import {injectIntl} from 'react-intl';

class EditAseInternalTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false, createInternalTicket: false, skippedStatus: false, fetchedNewTicketData: false};
    }

    componentWillUnmount() {
        const {uuid, redirect} = this.props.lockTicket;
        if (!redirect) {
            this.props.unlockAseInternalTicket(uuid);
        }
        this.props.resetRedirectAfterAseInternalLockTicket();
    }

    componentDidMount() {
        this.props.resetAseInternalTicket();
        this.props.lockAseInternalTicket(this.props.match.params.ticketUuid);

        if (!this.props.location.redirectedAfterUpdate) {
            this.setState({fetchedNewTicketData: true});
            this.props.fetchAseInternalTicket(this.props.match.params.ticketUuid);
        }
        if (!!this.props.location.checkStatusSkipAfterUpdateRedirect) {
            this.setState({fetchedNewTicketData: true});
        }
    }

    missingEditorsNotes = (submitBy, dataForSend) => {
        if (submitBy === FORM_BUTTON_ANSWER || submitBy === FORM_BUTTON_ESCALATE || submitBy === FORM_BUTTON_TRANSFER_TO_REQUESTOR) {
            return !dataForSend.message;
        }
    }

    answeredToSuspiciousGroup = (submitBy, dataForSend) => {
        if (submitBy === FORM_BUTTON_ANSWER) {
            const assignedSolutionGroup = get(dataForSend, 'ticket.assignedSolutionGroup.id');
            return !this.props.previousSolutionGroups.includes(assignedSolutionGroup)
        }
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const dataForSend = transformUpdateAseInternalTicketForSave(values);
        if (this.answeredToSuspiciousGroup(submitBy, dataForSend)) {
            const groupName = get(dataForSend, 'ticket.assignedSolutionGroup.name');

            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ESCALATION_SUSPICIOUS_GROUP_WARN, {groupName}))) {
                return;
            }
        }
        if (this.missingEditorsNotes(submitBy, dataForSend)) {
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ESCALATION_MISSING_EDITORS_NOTES_GROUP_WARN))) {
                return;
            }
        }
        this.props.updateAseInternalTicket(dataForSend);
        this.setState({ticketSubmittedAndShouldRedirect: true});
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.ticketSubmittedAndShouldRedirect && !this.state.ticketSubmittedAndShouldRedirect
            && prevProps.ticketData.isTicketCreating && !this.props.ticketData.isTicketCreating
            && this.props.ticketData.isTicketLoading) {
            this.props.lockAseInternalTicket(this.props.match.params.ticketUuid);
        }
    }

    render() {
        const {
            ticketData,
            attachmentsData,
            lockTicket,
            loggedUser
        } = this.props;

        if (lockTicket.redirect) {
            return <Redirect to={`/ase/internalTicket/view/${lockTicket.uuid}`}/>;
        }

        let formType = false;
        if (get(ticketData, 'ticket.formType', {default: false})) {
            formType = ticketData.ticket.formType;
        }

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAseInternalTicketAttachments attachments={attachmentsData.attachmentsForUpload}
                                                attachmentsUrl={ticketData.newAttachments}
                                                correlationId={ticketData.correlationId}
                                                isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                                formType={formType}/>
        }

        if (ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || lockTicket.isTicketLocking
            || !formType
        ) {
            return <Loader/>;
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/ase/internalTicket/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (get(ticketData, 'ticket.uuid', {default: false})
            && [ASE_INTERNAL_TICKET_STATUS_NEW, ASE_INTERNAL_TICKET_STATUS_ANSWERED, ASE_INTERNAL_TICKET_STATUS_TRANSFERRED, ASE_INTERNAL_TICKET_STATUS_REOPENED].includes(get(ticketData, 'ticket.status', {default: false}))
            && moduleRoles.isSGEditorAseInSG(loggedUser, get(ticketData, 'ticket.assignedSolutionGroup.id', {default: ''}))
            && !this.state.skippedStatus
            && this.state.fetchedNewTicketData
        ) {
            this.setState({skippedStatus: true});
            this.props.updateAseInternalTicket({
                ticket: {
                    pfxNumber: ticketData.ticket.pfxNumber,
                    sfxNumber: ticketData.ticket.sfxNumber,
                    status: ASE_INTERNAL_TICKET_STATUS_IN_PROGRESS,
                    uuid: ticketData.ticket.uuid,
                }
            });
            return <Loader/>;
        }

        return (
            <EditLoadInternalTicketDataPage formType={formType} handleSubmit={this.handleSubmit}/>
        );
    }
}

const getPreviousSolutionGroups = (state) => {
    const logs = get(state, 'aseInternalTicket.logs') || [];

    const fromIds = logs.map(item => item.solutionGroup && item.solutionGroup.id);
    const toIds = logs.map(item => item.toSolutionGroup && item.toSolutionGroup.id);

    return [...new Set([...fromIds, ...toIds])];
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.aseInternalTicket,
    lockTicket: state.aseLockInternalTicketCheck,
    attachmentsData: state.aseInternalTicketAttachment,
    previousSolutionGroups: getPreviousSolutionGroups(state)
});

EditAseInternalTicketPage.propTypes = {
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchAseInternalTicket: PropTypes.func.isRequired,
    resetAseInternalTicket: PropTypes.func.isRequired,
    lockAseInternalTicket: PropTypes.func.isRequired,
    unlockAseInternalTicket: PropTypes.func.isRequired,
    resetRedirectAfterAseInternalLockTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateAseInternalTicket: PropTypes.func.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchAseInternalTicket,
    resetAseInternalTicket,
    lockAseInternalTicket,
    unlockAseInternalTicket,
    resetRedirectAfterAseInternalLockTicket,
    updateAseInternalTicket,
})(injectIntl(EditAseInternalTicketPage))), ['AS_SG_EDITOR']);

