import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const ASE_POPUP_FETCH_COMMAND = 'ASE_POPUP_FETCH_COMMAND';
export const ASE_POPUP_MODAL_OPEN = 'ASE_POPUP_MODAL_OPEN';
export const ASE_POPUP_MODAL_CONFIRM = 'ASE_POPUP_MODAL_CONFIRM';

export const openAsePopUpModal = () => (dispatch) => {
    dispatch({
        type: ASE_POPUP_MODAL_OPEN,
    });
};

export const confirmAsePopUpModal = () => (dispatch) => {
    dispatch({
        type: ASE_POPUP_MODAL_CONFIRM
    });
};

export const fetchAsePopUp = (group) => dispatch => {
    return dispatch({
        type: ASE_POPUP_FETCH_COMMAND,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_POPUP_GET,
        payload: {group}
    });
};
