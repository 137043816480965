import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import { Redirect, withRouter } from 'react-router-dom';
import { clearAlerts } from '../../../../actions/alertsActions';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import answeringSchemaMessages from '../../../../intl/admin/answeringSchemaMessages';
import commonMessages from '../../../../intl/common/commonMessages';
import {
    getCurrentFormTypes,
    getInternalFormTypes,
} from '../../../../constants/Utils';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import ListAnsweringSchemaTable from './ListAnsweringSchemaTable';
import {getAdminAnsweringSchemaPath} from '../../../../routes/pathResolver';

class ListAnsweringSchemaPage extends Component {
    constructor(props) {
        super(props);
        const { intl, domain } = props;
        const internalFormTypesOptions = (getInternalFormTypes(props.domain) || {}).map(formType => ({
            value: formType,
            label: (formTypeMessages[formType] ? intl.formatMessage(formTypeMessages[formType]) : formType) + ' (Internal)'
        }));

        const formTypeOptions = getCurrentFormTypes((props.domain) || {}).map(formType => ({
            value: formType,
            label: formTypeMessages[formType] ? intl.formatMessage(formTypeMessages[formType]) : formType
        }));

        const allFormTypesOptions = [...formTypeOptions, ...internalFormTypesOptions];

        this.state = {
            ratchet: false,
            answeringSchema: '',
            columnLibrary: [
                {
                    id: 'formType',
                    accessor: row => row.formType,
                    message: answeringSchemaMessages.TABLE_FORMTYPE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {allFormTypesOptions.map((item, index) =>
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>)
                            }                        </select>
                    ),
                    Cell: e => <div>{this.translateFormType(e.value, intl)}</div>
                },
                {
                    id: 'ticketType',
                    accessor: row => row.ticketType,
                    message: answeringSchemaMessages.TICKET_TYPE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {['dealer', 'internal'].map((key, index) =>
                                <option key={index} value={key}>
                                    {key}
                                </option>)
                            }
                        </select>
                    ),
                },
                {
                    id: 'answeringSolutionGroups',
                    accessor: row => this.formatAnsweringSolutionGroupsLabel(row.answeringSolutionGroups),
                    message: answeringSchemaMessages.TABLE_ANSWERING_SOLUTION_GROUPS,
                    sortable: false,
                },
            ],
        };
    }

    translateFormType = (formType, intl) => {
        return formTypeMessages[formType] ? intl.formatMessage(formTypeMessages[formType]) : formType;
    }

    formatAnsweringSolutionGroupsLabel = (value) => {
        let result = '';
        if (value) {
            value.map(sg => result += sg.name + ', ')
        }
        return result.replace(/,\s$/, '');
    };


    componentDidMount() {
        //this.props.clearAlerts();
    }

    handleEditClick = ({ id }) => {
        this.setState({ ratchet: true, answeringSchema: id });
    };

    render() {
        const { filter, domain } = this.props;
        const { columnLibrary, answeringSchema } = this.state;


        if (this.state.ratchet) {
            return <Redirect to={`${getAdminAnsweringSchemaPath(this.props.domain)}/edit/${answeringSchema}`}/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.answeringSchema.listTitle"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Answering Schema List"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <ListAnsweringSchemaTable
                    columns={columnLibrary}
                    handleEditClick={this.handleEditClick} filter={filter} domain={domain}/>
            </div>
        );
    }
}

ListAnsweringSchemaPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
};

const mapStateToPropos = state => ({});

export default checkRoles(withRouter(connect(mapStateToPropos, {
    clearAlerts,
})(injectIntl(ListAnsweringSchemaPage))), ['AS_POWER_USER']);
