import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const createAseExport = (exportFilter) => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_EXPORT_CREATE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_EXPORT_CREATE,
        payload: {
            exportFilter
        },
    });
};

export const fetchAseExport = () => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_EXPORT_GET,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_EXPORT_GET,
        payload: {},
    });
};

export const downloadExport = (exportRecord) => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_EXPORT_DOWNLOAD_URL_GET,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_EXPORT_DOWNLOAD_URL_GET,
        payload: {
            exportRecord
        },
    });
};
