import {
    ADMIN_ESCALATION_LEVEL_ADD,
    ADMIN_ESCALATION_LEVEL_EDIT,
    ADMIN_ESCALATION_LEVEL_FETCH, ADMIN_ESCALATION_LEVEL_REMOVE, ADMIN_ESCALATION_LEVEL_UPDATE
} from '../../actions/admin/actionAdminEscalationLevel';

export const adminEscalationLevel = (state = {
    isLoading: false,
    escalationLevels: [],
    edit: -1,
}, action) => {
    switch (action.type) {
        case ADMIN_ESCALATION_LEVEL_FETCH:
            return Object.assign({}, state, {isLoading: true, escalationLevels: []});
        case ADMIN_ESCALATION_LEVEL_ADD:
            return Object.assign({}, state, {isLoading: true});
        case ADMIN_ESCALATION_LEVEL_EDIT:
            return Object.assign({}, state, {edit: action.payload.index});
        case ADMIN_ESCALATION_LEVEL_UPDATE:
            return Object.assign({}, state, {isLoading: true, edit: -1});
        case ADMIN_ESCALATION_LEVEL_REMOVE:
            return Object.assign({}, state, {isLoading: true});

        default:
            return state;
    }
};
