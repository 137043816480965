import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {injectIntl} from 'react-intl';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import {
    FORM_FIELD_DOC_UPDATE_NEEDED,
    FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_FIELD_LUP_QS_ASSOCIATED,
    FORM_FIELD_ROOT_CAUSE,
    FORM_LABEL_DOC_UPDATE_NEEDED,
    FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_LABEL_LUP_QS_ASSOCIATED,
    FORM_LABEL_ROOT_CAUSE,
} from '../../../../constants/formConstants';
import BorderRadius from '../../../common/styled/BorderRadius';
import InputSelectFieldWithValidation from '../../../common/InputSelectFieldWithValidation';
import {customerRisk, rootCauses} from '../../../../constants/Utils';
import commonMessages from '../../../../intl/common/commonMessages';
import rootCauseMessages from '../../../../intl/afterSales/rootCauseMessages';
import {Field} from 'redux-form';
import DatePickerComponent from '../../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import fulltextSearchMessages from '../../../../intl/afterSales/fulltextSearchMessages';
import customerRiskMessages
    from '../../../../intl/afterSales/customerRiskMessages';

const DocumentationUpdateSection = ({domain, intl}, ) => {

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...fulltextSearchMessages.DOCUMENTATION_UPDATE_SECTION}/>
            </h3>
            <BorderRadius size={5} className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_DOC_UPDATE_NEEDED]}
                                                        field={FORM_FIELD_DOC_UPDATE_NEEDED}
                                                        options={['YES', 'NO']}
                                                        intlMessages={commonMessages}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_ROOT_CAUSE]}
                                                        field={FORM_FIELD_ROOT_CAUSE}
                                                        options={rootCauses}
                                                        intlMessages={rootCauseMessages}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={fulltextSearchMessages[FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION]}
                               name={FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_LUP_QS_ASSOCIATED]}
                                                        field={FORM_FIELD_LUP_QS_ASSOCIATED}
                                                        options={customerRisk}
                                                        intlMessages={customerRiskMessages}
                        />
                    </div>
                </div>
            </BorderRadius>
        </>
    )
};

const mapStateToProps = (state) => ({
    roles: state.profile.userDetail.roles,
});

const mapDispatchToProps = (dispatch, props) => ({
});


export default  connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocumentationUpdateSection));
