import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import get from 'get-value';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {
    FORM_FIELD_CREATION_DATE,
    FORM_FIELD_NETWORK_TICKET_NUMBER,
    FORM_FIELD_PRIORITY_TREATMENT,
    FORM_FIELD_REQUEST_ROLE,
    FORM_FIELD_REQUESTOR_IPN,
    FORM_FIELD_REQUESTOR_NAME,
    FORM_FIELD_SOLUTION_GROUP,
    FORM_FIELD_TELEPHONE_NO,
    FORM_LABEL_NETWORK_TICKET_NUMBER,
    FORM_LABEL_PRIORITY_TREATMENT,
    FORM_LABEL_REQUESTOR_IPN,
    FORM_LABEL_REQUESTOR_NAME,
    FORM_LABEL_REQUESTOR_ROLE,
    FORM_LABEL_SOLUTION_GROUP,
    FORM_LABEL_TELEPHONE_NO,
    FORM_TICKET_SECTION,
    FORM_LABEL_PRIORITY_CASES,
    FORM_FIELD_PRIORITY_CASES
} from '../../../../../../constants/formConstants';
import {change, formValueSelector, getFormInitialValues} from 'redux-form';
import formMessages from '../../../../../../intl/afterSales/formMessages';
import {
    internalTicketRole,
    priorityCases,
    priorityTreatment,
} from '../../../../../../constants/Utils';
import BorderRadius from '../../../../../common/styled/BorderRadius';
import {positiveNumber} from '../../../../../common/validationCommons';
import internalTicketRoleMessages from '../../../../../../intl/afterSales/internalTicketRoleMessages';
import priorityTreatmentMessages from '../../../../../../intl/afterSales/priorityTreatmentMessages';
import priorityCasesMessages from '../../../../../../intl/afterSales/priorityCasesMessages';
import {ConditionalWrapper} from '../../../../../common/ConditionalWrapper';
import {Link} from 'react-router-dom';
import {fetchAseInternalTicketSgInfo} from '../../../../../../actions/ase/actionAseInternalTicket';

const RequestorInformationSection = (props) => {
    const {ticketReferenceId, userRights, readOnly, reqFields, roles, networkTicketNumberValue, initialValues, priorityTreatmentValue, fetchAseInternalTicketSgInfo} = props;

    const handleChange = (event, value) => {
        fetchAseInternalTicketSgInfo(value);
    };

    const getSolutionGroupsOptions = () => {
        // requester solution group can be change only during creation of the ticket
        const sgGroups = (!userRights.canEditInternalTicketRequestDetail || readOnly) ?
            [get(initialValues, 'requestorSolutionGroup')] : // read => get current solution group
            get(roles, 'ASE.sgEditor.sgPermission'); // edit => offer users solution groups

        return (sgGroups || []).map(sg => ({
            uuid: sg.id,
            presetName: sg.name
        }));
    };

    return (
        <>
            <h3 className="text-left ml-4">
                <FormattedMessage {...formMessages.REQUESTOR_INFORMATION_SECTION}/>
            </h3>
            <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_REQUESTOR_NAME]}
                                                  field={FORM_FIELD_REQUESTOR_NAME}
                                                  isSmall
                                                  isRequired={reqFields.includes(FORM_FIELD_REQUESTOR_NAME)}
                                                  isDisabled
                        />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_REQUESTOR_IPN]}
                                                  field={FORM_FIELD_REQUESTOR_IPN}
                                                  isSmall
                                                  isRequired={reqFields.includes(FORM_FIELD_REQUESTOR_IPN)}
                                                  isDisabled
                        />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_SOLUTION_GROUP]}
                                                        field={FORM_FIELD_SOLUTION_GROUP}
                                                        customOptionsMsg={getSolutionGroupsOptions()}
                                                        isRequired={reqFields.includes(FORM_FIELD_SOLUTION_GROUP)}
                                                        isDisabled={!userRights.canEditInternalTicketRequestDetail || readOnly}
                                                        handleChange={handleChange}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_REQUESTOR_ROLE]}
                                                        field={FORM_FIELD_REQUEST_ROLE}
                                                        options={internalTicketRole}
                                                        intlMessages={internalTicketRoleMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_REQUEST_ROLE)}
                                                        isDisabled={!userRights.canEditInternalTicketRequestDetail || readOnly}
                        />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_TELEPHONE_NO]}
                                                  field={FORM_FIELD_TELEPHONE_NO}
                                                  isSmall
                                                  maxLength={12}
                                                  validations={[positiveNumber]}
                                                  isRequired={reqFields.includes(FORM_FIELD_TELEPHONE_NO)}
                                                  isDisabled={!userRights.canEditInternalTicketRequestDetail || readOnly}
                        />
                    </div>
                    {(networkTicketNumberValue) &&
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <ConditionalWrapper condition={!!ticketReferenceId}
                                                wrapper={children => <Link to={{pathname: `/ase/view/${ticketReferenceId}`, redirectedFromInternal: true}}>
                                                    {children}
                                                </Link>}
                            >
                                <InputFieldWithValidation label={formMessages[FORM_LABEL_NETWORK_TICKET_NUMBER]}
                                                          field={FORM_FIELD_NETWORK_TICKET_NUMBER}
                                                          isSmall
                                                          isRequired={reqFields.includes(FORM_FIELD_NETWORK_TICKET_NUMBER)}
                                                          isDisabled
                                />
                            </ConditionalWrapper>
                        </div>
                    }
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_PRIORITY_TREATMENT]}
                                                        field={FORM_FIELD_PRIORITY_TREATMENT}
                                                        options={priorityTreatment}
                                                        intlMessages={priorityTreatmentMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_PRIORITY_TREATMENT)}
                                                        isDisabled={!userRights.canEditInternalTicketPriority || readOnly}
                        />
                    </div>
                    {priorityTreatmentValue === "YES" &&
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_PRIORITY_CASES]}
                                                            field={FORM_FIELD_PRIORITY_CASES}
                                                            options={priorityCases}
                                                            intlMessages={priorityCasesMessages}
                                                            isRequired={priorityTreatmentValue === "YES"}
                                                            isDisabled={!userRights.canEditInternalTicketPriority || readOnly}
                            />
                        </div>
                    }
                </div>
            </BorderRadius>
        </>
    )
};

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, props) => ({
    ticketReferenceId: get(state, 'aseInternalTicket.ticketReference.uuid', false),
    initialValues: getFormInitialValues(props.form)(state),
    creationDate: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_CREATION_DATE}`),
    networkTicketNumberValue: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_NETWORK_TICKET_NUMBER}`),
    priorityTreatmentValue: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_PRIORITY_TREATMENT}`),
    roles: state.profile.userDetail.roles,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            fetchAseInternalTicketSgInfo
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value))
});
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RequestorInformationSection));
