import {
    ASE_TICKET_STATUS_NEW,
    ASE_TICKET_STATUS_IN_PROGRESS_DEALER,
    ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    ASE_TICKET_STATUS_READ_BY_SUBSIDIARY,
    ASE_TICKET_STATUS_REOPENED,
    ASE_TICKET_STATUS_TRANSFERRED
} from './formConstants';

export const editRights = {
    DEALER: {
        NEW: {
            canAddNotes: false, //y
            canAddEditorsNotes: false, //y
            canReadEditorsNotes: false, //y
            canEditVehDetails: true, //y
            canAddAttach: true, //y
            canEditProblemDesc: true, //y
            canEditProblemDescText: true, //y
            canEditTempSolutionText: true,
            canEditHeader: true, //y
            canChangeToReadBySubs: false, //y
            canClose: true, //y
            canForwardToDealer: false, //dunno
            canForwardToSubs: false, //y
            canReopen: false, //y
            canSave: true, //y
            canEditClientId: true, //y
            canForwardBackToSubs: false, //y
            canShowAutomaticResponse: false, //y
            canAnswer: false, //y
            canEscalate: false, //y
            canViewDocumentationUpdateSection: false, //Y TODO
            canEditDocumentationUpdate: false, //y TODO
            canViewInternalTicketReferences: false, //y
        },
        CLOSED: {
            canAddNotes: false,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditVehDetails: false,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: true,
            canSave: false,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
        },
        READ_BY_SUBSIDIARY: {
            canAddNotes: false,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditVehDetails: false,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
        },
        WAIT_SUBSIDIARY: {
            canAddNotes: false,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditVehDetails: false,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
        },
        WAIT_DEALER: {
            canAddNotes: true,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditVehDetails: false,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: true,
            canReopen: false,
            canSave: true,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
        },
        REOPEN: {
            canAddNotes: true,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditVehDetails: false,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: true,
            canReopen: false,
            canSave: true,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
        },
        TRANSFERRED: {
            canAddNotes: false,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditVehDetails: false,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
        }
    },
    EDITOR: {
        NEW: {
            canAddNotes: false,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditVehDetails: false,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: true,
            canClose: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canEditClientId: true,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
        },
        CLOSED: {
            canAddNotes: false,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditVehDetails: false,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: true,
            canSave: false,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: true,
        },
        READ_BY_SUBSIDIARY: {
            canAddNotes: true,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditVehDetails: true,
            canAddAttach: true,
            canEditProblemDesc: true,
            canEditProblemDescText: false,
            canEditTempSolutionText: true,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditClientId: true,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: true,
            canEscalate: true,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
        },
        WAIT_SUBSIDIARY: {
            canAddNotes: false,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditVehDetails: false,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: true,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
        },
        WAIT_DEALER: {
            canAddNotes: false,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditVehDetails: false,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditTempSolutionText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditClientId: false,
            canForwardBackToSubs: true,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
        },
        REOPEN: {
            canAddNotes: true,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditVehDetails: true,
            canAddAttach: true,
            canEditProblemDesc: true,
            canEditProblemDescText: false,
            canEditTempSolutionText: true,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: true,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
        },
        TRANSFERRED: {
            canAddNotes: true,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditVehDetails: true,
            canAddAttach: true,
            canEditProblemDesc: true,
            canEditProblemDescText: false,
            canEditTempSolutionText: true,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditClientId: false,
            canForwardBackToSubs: false,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: true,
            canEscalate: true,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
        }
    }
};

export const createRights = {
    canAddNotes: false,
    canAddEditorsNotes: false,
    canReadEditorsNotes: false,
    canEditVehDetails: true,
    canAddAttach: true,
    canEditProblemDesc: true,
    canEditProblemDescText: true,
    canEditTempSolutionText: true,
    canEditHeader: true,
    canChangeToReadBySubs: false,
    canClose: false,
    canForwardToDealer: false,
    canForwardToSubs: false,
    canReopen: false,
    canSave: true,                           //changed
    canEditClientId: true,
    canForwardBackToSubs: false,
    canShowAutomaticResponse: false,
    canEditBvm: true,
    canViewEditorReply: false,
};


export const editAseListButtonVisibility = {
    DEALER: [
        ASE_TICKET_STATUS_NEW,
        ASE_TICKET_STATUS_IN_PROGRESS_DEALER,
        ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
        ASE_TICKET_STATUS_REOPENED
    ],
    EDITOR: [
        ASE_TICKET_STATUS_NEW,
        ASE_TICKET_STATUS_IN_PROGRESS_DEALER,
        ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
        ASE_TICKET_STATUS_READ_BY_SUBSIDIARY,
        ASE_TICKET_STATUS_TRANSFERRED,
        ASE_TICKET_STATUS_REOPENED
    ]
};
