import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const ASE_UPDATE_USER_LIST_PREFERENCE = 'ASE_UPDATE_USER_LIST_PREFERENCE';
export const ASE_UPDATE_USER_HISTORY_PREFERENCE = 'ASE_UPDATE_USER_HISTORY_PREFERENCE';
export const ASE_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE = 'ASE_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE';
export const ASE_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE = 'ASE_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE';
export const ASE_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE = 'ASE_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE';
export const ASE_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE = 'ASE_UPDATE_USER_FULL_TEXT_SEARCH_INTENRAL_PREFERENCE';
export const ASE_UPDATE_USER_STATISTICS_PREFERENCE = 'ASE_UPDATE_USER_STATISTICS_PREFERENCE';
export const ASE_UPDATE_USER_STATISTICS_SG_PREFERENCE = 'ASE_UPDATE_USER_STATISTICS_SG_PREFERENCE';
export const ASE_FETCH_PREFERENCES_PENDING = 'ASE_FETCH_PREFERENCES_PENDING';

export const getAsePreferences = () => dispatch => {
    return dispatch({
        type: ASE_FETCH_PREFERENCES_PENDING,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_PREFERENCE_GET
    });
};

export const updateAseUserListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: ASE_UPDATE_USER_LIST_PREFERENCE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_USER_LIST_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateAseUserHistoryPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: ASE_UPDATE_USER_HISTORY_PREFERENCE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_USER_HISTORY_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateAseUserFulltextSearchPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: ASE_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_USER_FULL_TEXT_SEARCH_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateAseUserFulltextSearchInternalPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: ASE_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_USER_INTERNAL_FULL_TEXT_SEARCH_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateAseUserStatisticsPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: ASE_UPDATE_USER_STATISTICS_PREFERENCE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_USER_STATISTICS_PREFERENCE_SET,
        payload: {preferences: {ipn, countries: preferences}}
    });
};

export const updateAseUserStatisticsSGPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: ASE_UPDATE_USER_STATISTICS_SG_PREFERENCE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_USER_STATISTICS_SG_PREFERENCE_SET,
        payload: {preferences: {ipn, solutionGroups: preferences}}
    });
};

export const updateAseUserInternalTicketListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: ASE_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_USER_INTERNAL_LIST_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateAseUserInternalTicketHistoryPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: ASE_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_USER_INTERNAL_HISTORY_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};
