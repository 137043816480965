import {domains} from '../../constants/Utils';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const fetchAnsweringSchemaListPageData = (domain, pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            dispatch({
                type: aseWebsocketCommands.ASE_ANSWERING_SCHEMA_LIST_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_ANSWERING_SCHEMA_LIST,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: filtered}
            });
            break;
        }
    }
};


export const deleteAnsweringSchemaListRow = (domain, id, formType) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            dispatch({
                type: aseWebsocketCommands.ASE_ANSWERING_SCHEMA_DELETE_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_ANSWERING_SCHEMA_DELETE,
                payload: {
                    payload:{
                        id: id,
                        answeringSchema: {
                            formType: formType
                        }
                    }
                }
            });
            break;
        }
    }
};
