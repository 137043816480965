import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import 'react-table/react-table.css';
import Table from '../../common/table/TicketTable';
import {IconEdit, PrimaryIcon} from '../../common/Button';
import {fetchAseFullTextSearchPageData} from '../../../actions/ase/actionAseFullTextSearchPage';
import {Link} from 'react-router-dom';
import fulltextSearchMessages from '../../../intl/afterSales/fulltextSearchMessages';
import * as moment from 'moment';
import {moduleRoles} from '../../../utils/roles';
import get from 'get-value';
import {domains} from '../../../constants/Utils';
import {editAseListButtonVisibility} from '../../../constants/aseTicketRights';


class FullTextSearchAseTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listDefaultPageSize: 10,
            listDefaultSort: [{id: 'ticketNumber', desc: true}],
        };
        this.handleFetchData = this.handleFetchData.bind(this);
    }


    handleFetchData(state) {
        this.props.fetchAseFullTextSearchPageData(
            state.pageSize,
            state.sorted,
            state.cursor,
            state.isNextFromPivot,
            get(this, 'props.listDefaultFilters.1', {default: {}}),
            this.normalizeFiltered(get(this, 'props.listDefaultFilters.0', {default: []}))
        );

        this.setState({
            listDefaultPageSize: state.pageSize,
            listDefaultSort: state.sorted,
        });
    }

    normalizeFiltered = state => {
        return state
            .map(filterItem => {
                if (filterItem.id === 'from' || filterItem.id === 'to') {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value, 'YYYY-MM-DD').add(12, 'hours').toISOString()
                    };
                } else if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    canReadSolutionGroup = (group, roles) => {
        if (moduleRoles.isEditorAse(roles) || moduleRoles.isSGEditorAse(roles) || !group) {
            return true;
        }
        return group.canForwardToDealer;
    }

    render() {
        const {columns, data, pages, isLoading, handleEditClick, listDefaultFilters, isNextPage, isNextFromPivot, roles} = this.props;
        const modifiedData = data.map(ticket => {
            const group = get(ticket, 'ticketBase.solutionGroup');
            const solutionGroup = this.canReadSolutionGroup(group, roles) ? group : null;

            return {
                ...ticket,
                ticketBase: {
                    ...ticket.ticketBase,
                    solutionGroup
                },
            }
        })
        return (
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'ticketNumber',
                                    accessor: 'ticketBase.ticketNumber',
                                    filterable: false,
                                    message: fulltextSearchMessages.TABLE_TICKET_NUMBER,
                                    Cell: (row) => <Link to={`view/${row.original.id}`}>{row.value}</Link>
                                },
                                ...columns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm"
                                                            title="Edit"
                                                            type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                }
                            ]}
                            isLoading={isLoading}
                            data={modifiedData}
                            pages={pages}
                            handleFetchData={this.handleFetchData}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={listDefaultFilters}
                            defaultSorting={this.state.listDefaultSort}
                            defaultPageSize={this.state.listDefaultPageSize}
                            buttonVisibility={editAseListButtonVisibility[moduleRoles.isDealerAse(this.props.userRoles) ? 'DEALER' : 'EDITOR']}
                            dealerNumber={moduleRoles.isDealerAse(this.props.userRoles) ? this.props.dealerNumber : 'EDITOR'}
                            editorGroups={moduleRoles.isEditorAse(this.props.userRoles) ? moduleRoles.getEditorGroups(domains.ASE, this.props.userRoles).map(e => e.group) : false}
                            sgPermissions = {get(roles, `${domains.ASE}.sgEditor.sgPermission`)}
                            checkInternationalTicketsForSGEditors
                            toolSortable={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

FullTextSearchAseTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    fetchAseFullTextSearchPageData: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    listDefaultFilters: PropTypes.array.isRequired,
    userRoles: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.aseFullTextSearchPage.isTableLoading,
    data: state.aseFullTextSearchPage.fullTextData,
    isNextPage: state.aseFullTextSearchPage.isNextPage,
    isNextFromPivot: state.aseFullTextSearchPage.isNextFromPivot,
    pages: state.aseFullTextSearchPage.pages,
    userRoles: state.profile.userDetail.roles,
    dealerNumber: state.profile.userDetail.dealerNumber,
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {fetchAseFullTextSearchPageData})(FullTextSearchAseTable);
