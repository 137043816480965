import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedDate} from 'react-intl';
import PropTypes from 'prop-types';
import FileIconWithName from '../../administration/common/FileIconWithName';
import {downloadFile} from '../../../actions/actionFile';

const TextCell = styled.td`
  white-space: pre-wrap;
`;

const FileCell = styled.td`
    cursor: ${props => props.cursorPointer ? 'pointer' : 'default'};
`;

class RecurrentProblemAseRow extends Component {
    handleDownload = (e) => {
        e.preventDefault();
        this.props.downloadFile(this.props.domain, this.props.recurrentProblem.attachment, this.props.recurrentProblem.uuid);
    };

    render() {
        const {recurrentProblem, index} = this.props;
        return (
            <tr className="d-flex">
                <td className="col-2">{index + 1}</td>
                <TextCell className="col-6">{recurrentProblem.message}</TextCell>
                <FileCell cursorPointer={recurrentProblem.attachment} className="col-2">
                    {
                        recurrentProblem.attachment &&
                        <FileIconWithName file={recurrentProblem.attachment} showIcon onClick={this.handleDownload}/>
                    }
                </FileCell>
                <td className="col-2">
                    <FormattedDate value={new Date(recurrentProblem.updated)}/>
                </td>
            </tr>
        );
    }
}

RecurrentProblemAseRow.propTypes = {
    recurrentProblem: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    downloadFile: PropTypes.func.isRequired,
};

export default connect(null, {
    downloadFile
})(RecurrentProblemAseRow);
