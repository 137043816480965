import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'ase.internal.search.title',
        defaultMessage: 'Search Engine'
    },
    REQUEST_DETAIL_SECTION: {
        id: 'ase.internal.search.requestDetailSection',
        defaultMessage: 'Request details'
    },
    // search criteria
    INTERNAL_TICKET_NUMBER: {
        id: 'ase.internal.search.requestDetailSection.ticketNumber',
        defaultMessage: 'Internal Ticket number'
    },
    DEALER_TICKET_NUMBER: {
        id: 'ase.internal.search.requestDetailSection.dealerTicketNumber',
        defaultMessage: 'Dealer ticket'
    },
    DEALER_FORM_TYPE: {
        id: 'ase.internal.search.requestDetailSection.dealerFormType',
        defaultMessage: 'Dealer Formtype'
    },
    REQUEST_STATUS: {
        id: 'ase.internal.search.requestDetailSection.requestStatus',
        defaultMessage: 'Request Status'
    },
    SG_REQUESTER: {
        id: 'ase.internal.search.requestDetailSection.sgRequester',
        defaultMessage: 'SG requestor'
    },
    REQUESTOR_IPN: {
        id: 'ase.internal.search.requestDetailSection.requestorIpn',
        defaultMessage: 'Requestor (IPN)'
    },
    REQUESTOR_ROLE: {
        id: 'ase.internal.search.requestDetailSection.role',
        defaultMessage: 'Role'
    },
    PRIORITY_TREATMENT: {
        id: 'ase.internal.search.requestDetailSection.priority',
        defaultMessage: 'Priority'
    },
    CREATED_AT_INTERNAL_TICKET_FROM: {
        id: 'ase.internal.search.requestDetailSection.createdAtInternalTicketFrom',
        defaultMessage: 'Creation Date of the Internal Ticket From'
    },
    CREATED_AT_INTERNAL_TICKET_TO: {
        id: 'ase.internal.search.requestDetailSection.createdAtInternalTicketTo',
        defaultMessage: 'Creation Date of the Internal Ticket To'
    },
    VEHICLE_DETAIL_SECTION: {
        id: 'ase.internal.search.vehicleDetailSection',
        defaultMessage: 'Vehicle details'
    },
    VIN: {
        id: 'ase.internal.search.vehicleDetailSection.vin',
        defaultMessage: 'VIN number'
    },
    MANUFACTURE_DATE: {
        id: 'ase.internal.search.vehicleDetailSection.manufactureDate',
        defaultMessage: 'Manufacturing date'
    },
    CAR_MODEL: {
        id: 'ase.internal.search.vehicleDetailSection.carModel',
        defaultMessage: 'Car Model'
    },
    REGISTRATION_NUMBER: {
        id: 'ase.internal.search.vehicleDetailSection.registrationNumber',
        defaultMessage: 'Registration number'
    },
    ENGINE_TYPE: {
        id: 'ase.internal.search.vehicleDetailSection.engineTypeAndSuffix',
        defaultMessage: 'Engine Type & Suffix'
    },
    ENGINE_SERIAL_NUMBER: {
        id: 'ase.internal.search.vehicleDetailSection.engineSerialNumber',
        defaultMessage: 'Engine serial number'
    },
    GEARBOX_TYPE: {
        id: 'ase.internal.search.vehicleDetailSection.gearboxType',
        defaultMessage: 'Gearbox Type & Suffix'
    },
    GEARBOX_SERIAL_NUMBER: {
        id: 'ase.internal.search.vehicleDetailSection.gearboxSerialNumber',
        defaultMessage: 'Gearbox serial number'
    },
    PROBLEM_DESCRIPTION_SECTION: {
        id: 'ase.internal.search.problemDescriptionSection',
        defaultMessage: 'Problem Description'
    },
    ISSUE_ORIGIN: {
        id: 'ase.internal.search.issueOrigin',
        defaultMessage: 'Issue origin'
    },
    PART_REFERENCE_ASSOCIATED: {
        id: 'ase.internal.search.partReferenceAssociatedToTheIssue',
        defaultMessage: 'Part reference associated to the issue'
    },
    NOD_ID: {
        id: 'ase.internal.search.nodId',
        defaultMessage: 'Nod ID'
    },
    DOCUMENT_ID: {
        id: 'ase.internal.search.repairMethodOrLaborTime',
        defaultMessage: 'Repair Method/Labor time number'
    },
    DOCUMENTATION_UPDATE_SECTION: {
        id: 'ase.internal.search.documentationUpdateSection',
        defaultMessage: 'Documentation Update'
    },
    DOC_UPDATE_NEEDED: {
        id: 'ase.internal.search.docUpdateNeeded',
        defaultMessage: 'Documentation update needed?'
    },
    ROOT_CAUSE: {
        id: 'ase.internal.search.rootCause',
        defaultMessage: 'Root Cause'
    },
    ESTIMATED_DATE_OF_MODIFICATION: {
        id: 'ase.internal.search.estimatedDateOfModification',
        defaultMessage: 'Estimated Date Of Modification'
    },
    LUP_QS_ASSOCIATED: {
        id: 'ase.internal.search.lupQsAssociated',
        defaultMessage: 'Customer risk'
    },
    // searching options
    SEARCH_OPTION_SECTION : {
        id: 'ase.internal.search.options',
        defaultMessage: 'Searching option'
    },
    FULLTEXT_SEARCH : {
        id: 'ase.internal.search.options.fulltextSearch',
        defaultMessage: 'Fulltext'
    },

    VISIBLE_COLUMNS: {
        id: 'ase.internal.search.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },


    // table
    TABLE_TICKET_NUMBER: {
        id: 'ase.internal.search.table.ticketNumber',
        defaultMessage: 'Internal Ticket number'
    },
    TABLE_REQUEST_STATUS: {
        id: 'ase.internal.search.table.status',
        defaultMessage: 'Request Status'
    },
    TABLE_DEALER_TICKET_NUMBER: {
        id: 'ase.internal.search.table.dealerTicketNo',
        defaultMessage: 'Dealer Ticket No.'
    },
    TABLE_IT_CREATION_TIME: {
        id: 'ase.internal.search.table.itCreationDate',
        defaultMessage: 'IT Creation Date'
    },
    TABLE_SG_REQUESTOR: {
        id: 'ase.internal.search.table.sgRequestor',
        defaultMessage: 'SG Requestor'
    },
    TABLE_ASSIGNED_SOLUTION_GROUP: {
        id: 'ase.internal.search.table.assignedSolutionGroup',
        defaultMessage: 'Assigned SG'
    },
    TABLE_IT_LAST_STATUS_DATE: {
        id: 'ase.internal.search.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TABLE_IT_LAST_EDITOR: {
        id: 'ase.internal.search.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TABLE_IT_ISSUE_ORIGIN: {
        id: 'ase.internal.search.table.issueOrigin',
        defaultMessage: 'Issue Origin'
    },
    TABLE_IT_PRIORITY: {
        id: 'ase.internal.search.table.priority',
        defaultMessage: 'Priority'
    },
    TABLE_IT_CREATED_BY_IPN: {
        id: 'ase.list.table.createdByIpn',
        defaultMessage: 'Created by (IPN)'
    },
    TABLE_IT_LUP_QS_ASSOCIATED: {
        id: 'ase.internal.search.table.lupQsAssociated',
        defaultMessage: 'LUP QS Associated'
    },
    TABLE_IT_DOCUMENTATION_UPDATE: {
        id: 'ase.internal.search.table.documentationUpdate',
        defaultMessage: 'Documentation Update'
    },
});

export default messages;
