import get from 'get-value';
import formTypeMessages from '../intl/common/formTypeMessages';
import React from 'react';
import {domains, getInternalFormTypes} from '../constants/Utils';

export const initLoadedEscalationSchema = (domain, escalationSchemaData, formatMessage) => {

    let formattedFormType = {}
    const formType = get(escalationSchemaData, 'escalationSchema.formType');
    if (formType){
        const internal = isInternal(domain, formType)
        console.log("isInternal")
        console.log(internal)
        const messages = formTypeMessages;
        const label = formatMessage(messages[formType]);
        formattedFormType = {
            label: internal ? label + ' (Internal)' : label,
            value: formType
        };
    }

    let formattedEscalationGroups = []
    if(get(escalationSchemaData, "escalationSchema.escalationSolutionGroups")){
        formattedEscalationGroups = get(escalationSchemaData, "escalationSchema.escalationSolutionGroups").map(sg => ({
            label: sg.name,
            value: sg.id
        }))
    }

    return {
        escalationSchemaInfoSection: {
            name: {label: get(escalationSchemaData, "escalationSchema.name"), value: get(escalationSchemaData, "escalationSchema.solutionGroupId")},
            formType: formattedFormType,
        },
        escalationSchemaEscalationSection: {
            escalationGroups: formattedEscalationGroups
        }
    }
};

export const initNewEscalationSchema = () => {
    return {
        escalationSchemaInfoSection: {
            name: '',
            formType: '',
        },
        escalationSchemaEscalationSection: {
            escalationGroups: [],
        }
    };
};

export const transformNewEscalationSchema = (domain, values) => {
    const escalationGroups = values.escalationSchemaEscalationSection.escalationGroups;

    const formattedEscalationGroups = escalationGroups.map(sg => ({
        name: sg.label,
        id: sg.value
    }));

    return values.escalationSchemaInfoSection.formType.map(formType => ({
        id: values.escalationSchemaInfoSection.name.value,
        name: values.escalationSchemaInfoSection.name.label,
        escalationSchema: {
            formType: formType.value,
            ticketType: resolveTicketTypeBy(domain, formType.value),
            escalationSolutionGroups: formattedEscalationGroups,
        }
    }));
};

export const transformUpdateEscalationSchema = (domain, {newValues}) => {
    let formattedEscalationGroups = []
    if(get(newValues, "escalationSchemaEscalationSection.escalationGroups")){
        formattedEscalationGroups = get(newValues, "escalationSchemaEscalationSection.escalationGroups").map(sg => ({
            name: sg.label,
            id: sg.value
        }))
    }

    const formType = newValues.escalationSchemaInfoSection.formType.value;

    const data = {
        id: newValues.escalationSchemaInfoSection.name.value,
        name: newValues.escalationSchemaInfoSection.name.label,
        escalationSchema: {
            formType: formType,
            ticketType: resolveTicketTypeBy(domain, formType),
            escalationSolutionGroups: formattedEscalationGroups,
        }
    };

    return data;
};

export const resolveTicketTypeBy = (domain, formType) => {
    return getInternalFormTypes(domain).includes(formType) ? 'internal' : 'dealer';
}

export const isInternal = (domain, formType) => resolveTicketTypeBy(domain, formType) === 'internal' && (domain !== domains.ASE);
