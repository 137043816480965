import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const ASE_LIST_FETCH_PENDING = 'ASE_LIST_FETCH_PENDING';

export const fetchAseListPageData = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: ASE_LIST_FETCH_PENDING,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_LIST_GET,
        payload: {pageSize: pageSize, sorted: sorted, cursor, isNextFromPivot, filtered: filtered}
    });
};
