import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../Loader';
import {
    initNewSolutionGroup,
    initNewSolutionGroupAfterFail,
    transformNewSolutionGroupForSave
} from '../../../../utils/solutionGroupUtils';
import { createReqFields } from '../../../../constants/solutionGroupRequiredFields';
import PageHeader from '../../../common/PageHeader';
import get from 'get-value';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    redirectReset,
    resetSolutionGroup,
    saveSolutionGroup
} from '../../../../actions/admin/actionSolutionGroup';
import SolutionGroupForm from './forms/SolutionGroupForm';
import { SOLUTION_GROUP_FORM } from '../../../../constants/formConstants';
import {FormattedMessage, injectIntl} from 'react-intl';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {getAdminSGPath} from '../../../../routes/pathResolver';

class NewSolutionGroupPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetSolutionGroup(this.props.domain);
    }
    componentWillUnmount() {
        this.props.redirectReset();
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewSolutionGroupForSave(values.newValues, this.props.domain);
        this.setState(dataForSend)
        this.props.saveSolutionGroup(this.props.domain, dataForSend);
    };

    render() {
        const {
            sgData,
            domain,
            intl: {formatMessage},
        } = this.props;

        if (sgData.solutionGroupSubmittedAndShouldRedirect && !sgData.isSolutionGroupCreating && !sgData.isSolutionGroupLoading && get(sgData, 'solutionGroup.id', { default: false })) {
            return <Redirect to={{
                pathname: `${getAdminSGPath(this.props.domain)}/view/${sgData.solutionGroup.id}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (sgData.isSolutionGroupCreating
            || sgData.isSolutionGroupLoading
            || sgData.solutionGroupSubmittedAndShouldRedirect
        ) {
            return <Loader/>;
        }

        let initializationValue
        if(!sgData.isSolutionGroupCreating && !sgData.isSolutionGroupLoading && !sgData.solutionGroupSubmittedAndShouldRedirect && sgData.initWithOldData && this.state){
            initializationValue = initNewSolutionGroupAfterFail(this.state, formatMessage);
        } else {
            initializationValue = initNewSolutionGroup();
        }

        const requiredFields = createReqFields['DEFAULT'][SOLUTION_GROUP_FORM];
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.solutionGroup.title"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Solution Group"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <SolutionGroupForm
                            form={SOLUTION_GROUP_FORM}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            domain={domain}
                            creating={true}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

NewSolutionGroupPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    sgData: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    resetSolutionGroup: PropTypes.func.isRequired,
    saveSolutionGroup: PropTypes.func.isRequired,
    isLoadingProfile: PropTypes.bool.isRequired,
    availableName: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    clearAlerts: PropTypes.func.isRequired,
    sgData: state.adminSolutionGroup,
    userDetail: state.profile.userDetail,
    isLoadingProfile: state.profile.isLoading,
    availableName: state.adminSolutionGroup.availableName,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    saveSolutionGroup,
    resetSolutionGroup,
    redirectReset,
})(injectIntl(NewSolutionGroupPage))), ['AS_POWER_USER']);

