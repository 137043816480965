import {ASE_INTERNAL_SET_LOCK, ASE_INTERNAL_SET_UNLOCK,} from '../../constants/websocketCommands';

export const ASE_INTERNAL_RESET_REDIRECT = 'ASE_INTERNAL_RESET_REDIRECT';
export const ASE_INTERNAL_SET_LOCK_TICKET = 'ASE_INTERNAL_SET_LOCK_TICKET';

export const lockAseInternalTicket = uuid => dispatch =>
    dispatch({
        type: ASE_INTERNAL_SET_LOCK_TICKET,
        aseWebsocket: true,
        command: ASE_INTERNAL_SET_LOCK,
        payload: {uuid}
    });

export const unlockAseInternalTicket = uuid => dispatch =>
    dispatch({
        type: ASE_INTERNAL_SET_UNLOCK,
        aseWebsocket: true,
        command: ASE_INTERNAL_SET_UNLOCK,
        payload: {uuid}
    });

export const resetRedirectAfterAseInternalLockTicket = () => dispatch => dispatch({type: ASE_INTERNAL_RESET_REDIRECT});
