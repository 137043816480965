import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {addLocaleData} from 'react-intl';
import {routerMiddleware} from 'react-router-redux';
import metisMenuReducer from 'react-metismenu/lib/reducers';
import csLocaleData from 'react-intl/locale-data/cs';
import skLocaleData from 'react-intl/locale-data/sk';
import enLocaleData from 'react-intl/locale-data/en';
import deLocaleData from 'react-intl/locale-data/de';
import frLocaleData from 'react-intl/locale-data/fr';
import daLocaleData from 'react-intl/locale-data/da';
import esLocaleData from 'react-intl/locale-data/es';
import hrLocaleData from 'react-intl/locale-data/hr';
import huLocaleData from 'react-intl/locale-data/hu';
import itLocaleData from 'react-intl/locale-data/it';
import nlLocaleData from 'react-intl/locale-data/nl';
import plLocaleData from 'react-intl/locale-data/pl';
import ptLocaleData from 'react-intl/locale-data/pt';
import roLocaleData from 'react-intl/locale-data/ro';
import slLocaleData from 'react-intl/locale-data/sl';
import srLocaleData from 'react-intl/locale-data/sr';
import svLocaleData from 'react-intl/locale-data/sv';
import thunk from 'redux-thunk';
import {reducer as form} from 'redux-form';
import createSagaMiddleware from 'redux-saga'
import {
    createAdminSocketMiddleware,
    createBvmSocketMiddleware,
    createAseSocketMiddleware
} from './socketMiddleware';
import {alerts} from '../reducers/alertReducer';
import {bvmData} from '../reducers/bvmReducer';
import {intl} from '../reducers/intlReducer';
import {mainFiles} from '../reducers/fileReducer';
import {menu} from '../reducers/menuReducer';
import {profile} from '../reducers/roleReducer';
import {supportModal} from '../reducers/supportReducer';
import {websocket} from '../reducers/websocketReducer';
import {adminMainMessage} from '../reducers/admin/adminMainMessageReducer';
import {adminNotification} from '../reducers/admin/adminNotificationReducer';
import {adminPopUp} from '../reducers/admin/adminPopUpReducer';
import {adminRecurrentProblem} from '../reducers/admin/adminRecurrentProblemReducer';
import {adminRightsManagement} from '../reducers/admin/adminRightsManagementReducer';
import {adminTicketSettings} from '../reducers/admin/adminTicketSettingsReducer';
import {adminWorkingHours} from '../reducers/admin/adminWorkingHoursReducer';
import {adminClientId} from '../reducers/admin/adminClientIdReducer';
import {adminClientIds} from '../reducers/admin/adminClientIdsReducer';
import {adminPresetResponse} from '../reducers/admin/adminPresetResponseReducer';
import {asePopUpModal} from '../reducers/ase/asePopUpModalReducer';
import {aseRecurrentProblem} from '../reducers/ase/aseRecurrentProblemReducer';
import {aseStatistics} from '../reducers/ase/aseStatisticsReducer';
import adminClientIdSaga from '../sagas/adminClientIdSaga';
import recurrentProblemFileSaga from '../sagas/recurrentProblemFileSaga';
import rightsManagementSaga from '../sagas/rightsManagementSaga';
import supportModalSaga from '../sagas/supportModalSaga';
import aseTicketSaga from '../sagas/ase/aseTicketSaga';
import initialState from './initialState';
import history from '../history.js';
import {adminDefaultDealerRole} from '../reducers/admin/adminDefaultDealerRoleReducer';
import {adminEscalationLevel} from '../reducers/admin/adminEscalationLevelReducer';
import {adminSolutionGroupList} from '../reducers/admin/adminSolutionGroupListReducer';
import {adminSolutionGroup} from '../reducers/admin/adminSolutionGroupReducer';
import {adminEscalationSchema} from '../reducers/admin/adminEscalationSchemaReducer';
import {adminEscalationSchemaList} from '../reducers/admin/adminEscalationSchemaListReducer';
import {adminAnsweringSchemaList} from '../reducers/admin/adminAnsweringSchemaListReducer';
import {adminAnsweringSchema} from '../reducers/admin/adminAnsweringSchemaReducer';
import {asePreferences} from '../reducers/ase/asePreferencesReducer';
import {aseListOrderLockTicketCheck, aseListPage} from '../reducers/ase/aseListReducer';
import {aseHistoryPage} from '../reducers/ase/aseHistoryReducer';
import {aseAttachment} from '../reducers/ase/aseAttachmentReducer';
import {aseLockTicketCheck, aseTicket} from '../reducers/ase/aseTicketReducer';
import {aseInternalTicket, aseLockInternalTicketCheck} from '../reducers/ase/aseInternalTicketReducer';
import {aseInternalTicketAttachment} from '../reducers/ase/aseInternalTicketAttachmentReducer';
import {
    aseInternalTicketListOrderLockTicketCheck,
    aseInternalTicketListPage
} from '../reducers/ase/aseInternalTicketListReducer';
import {aseInternalTicketHistoryPage} from '../reducers/ase/aseInternalTicketHistoryReducer';
import {aseInternalTicketSearch} from '../reducers/ase/aseInternalTicketSearchReducer';
import {aseFullTextSearchPage} from '../reducers/ase/aseFullTextSearchReducer';
import {aseExportDealers} from '../reducers/ase/aseDealersReducer';
import {aseExportTickets} from '../reducers/ase/aseExportReducer';
import {aseExportInternalTickets} from '../reducers/ase/aseInternalTicketExportReducer';
import adminFileSaga from '../sagas/adminFileSaga';

/**
 * Configuration of store, reducers, middlewares, enhancers management.
 */
const configureStore = (initialStoreContent = {}) => {
    const persistedState = Object.assign(initialState, initialStoreContent);
    const middlewares = process.env.NODE_ENV !== 'production' ?
        [require('redux-immutable-state-invariant').default(), thunk] :
        [thunk];
    const enhancers = [];
    const sagaMiddleware = createSagaMiddleware();

    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);

    const appReducers = combineReducers({
        alerts,
        bvmData,
        intl,
        mainFiles,
        menu,
        profile,
        supportModal,
        websocket,
        adminMainMessage,
        adminNotification,
        adminPopUp,
        adminRecurrentProblem,
        adminRightsManagement,
        adminTicketSettings,
        adminDefaultDealerRole,
        adminWorkingHours,
        adminClientId,
        adminClientIds,
        adminPresetResponse,
        adminEscalationLevel,
        adminSolutionGroup,
        adminSolutionGroupList,
        adminEscalationSchema,
        adminEscalationSchemaList,
        adminAnsweringSchema,
        adminAnsweringSchemaList,
        asePopUpModal,
        aseInternalTicketAttachment,
        aseAttachment,
        aseExportDealers,
        aseExportTickets,
        aseExportInternalTickets,
        aseHistoryPage,
        aseInternalTicketHistoryPage,
        aseInternalTicketSearch,
        aseFullTextSearchPage,
        aseListOrderLockTicketCheck,
        aseInternalTicketListPage,
        aseInternalTicketListOrderLockTicketCheck,
        aseListPage,
        asePreferences,
        aseRecurrentProblem,
        aseStatistics,
        aseLockTicketCheck,
        aseLockInternalTicketCheck,
        aseInternalTicket,
        aseTicket,

        metisMenuStore: metisMenuReducer,
        form,
    });

    const store = createStore(
        appReducers,
        persistedState,
        compose(
            applyMiddleware(
                ...middlewares,
                createAdminSocketMiddleware(),
                createAseSocketMiddleware(),
                createBvmSocketMiddleware(),
                routerMiddleware(history),
                sagaMiddleware
            ),
            ...enhancers
        )
    );

    addLocaleData(enLocaleData);
    addLocaleData(csLocaleData);
    addLocaleData(daLocaleData);
    addLocaleData(deLocaleData);
    addLocaleData(esLocaleData);
    addLocaleData(frLocaleData);
    addLocaleData(hrLocaleData);
    addLocaleData(huLocaleData);
    addLocaleData(itLocaleData);
    addLocaleData(nlLocaleData);
    addLocaleData(plLocaleData);
    addLocaleData(ptLocaleData);
    addLocaleData(roLocaleData);
    addLocaleData(skLocaleData);
    addLocaleData(slLocaleData);
    addLocaleData(srLocaleData);
    addLocaleData(svLocaleData);

    sagaMiddleware.run(adminFileSaga);
    sagaMiddleware.run(recurrentProblemFileSaga);
    sagaMiddleware.run(rightsManagementSaga);
    sagaMiddleware.run(supportModalSaga);
    sagaMiddleware.run(adminClientIdSaga);
    sagaMiddleware.run(aseTicketSaga);

    return store;
};

export default configureStore;
