import {domains} from '../../constants/Utils';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const ADMIN_TICKET_SETTINGS_FETCH_PENDING = 'ADMIN_TICKET_SETTINGS_FETCH_PENDING';
export const ADMIN_TICKET_SETTINGS_SELECT_GROUP = 'ADMIN_TICKET_SETTINGS_SELECT_GROUP';
export const ADMIN_TICKET_SETTINGS_UPDATE_PENDING = 'ADMIN_TICKET_SETTINGS_UPDATE_PENDING';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_TICKET_SETTINGS_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchTicketSettingsData = (domain, group) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_TICKET_SETTINGS_GET,
                payload: {group}
            });
        }
    }
};

export const updateTicketSettingsData = (domain, settings) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_UPDATE_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_TICKET_SETTINGS_SET,
                payload: {settings}
            });
        }
    }
}
