export const supportProperties = {
    DEFAULT: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
    FR: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
};

export const localeToCodeMapping = {
    bs: 'BS',
    cs: 'CZ',
    da: 'DK',
    de: 'DE',
    en: 'DEFAULT',
    es: 'ES',
    fr: 'FR',
    hr: 'HR',
    hu: 'HU',
    it: 'IT',
    mx: 'MX',
    nl: 'NL',
    pl: 'PL',
    pt: 'PT',
    ro: 'RO',
    ru: 'RU',
    sk: 'SK',
    sl: 'SI',
    sr: 'RS',
    sv: 'SE',
    ua: 'UA',
    gsw: 'GSW',
    sq: 'XK',
    tr: 'TR',
    bg: 'BG',
    za: 'ZA',
    si: 'SI',
    gr: 'GR',
    is: 'IS',
    rs: 'RS',
    ie: 'IE',
    au: 'AU',
};

