import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Select from 'react-select';
import styled from 'styled-components';
import countryGroupMessages from '../../intl/common/countryGroupMessages';
import {importerGroupsMapping} from "../../constants/Utils";

const StyledDiv = styled.div`
    padding: 10px;
    border: 1px;
    border-style: solid;
    border-color: #e9e9e9;
    border-radius: 0px 4px 4px 4px;
`;

const StyledUl = styled.ul`
    border-bottom: none;
`;

const styles = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? {...base, display: 'none'} : base;
    },
};
const ImportersTable = props => {
    const {importerGroups, intl} = props;

    return <div className="mt-3 mb-5">
        <StyledUl className="nav nav-tabs" id="importers" role="tablist">
            {importerGroups.map((group, index) =>
                <li key={index} className="nav-item">
                    <a className={`nav-link ${index > 0 ? '' : 'active'} text-secondary`}
                       id={`${group}-tab`}
                       data-toggle="tab"
                       href={`#${group}`}
                       type="button"
                       role="tab"
                       aria-controls={group}
                       aria-selected={index > 0 ? 'false' : 'true'}>
                        <FormattedMessage {...countryGroupMessages[group]} />
                    </a>
                </li>)}
        </StyledUl>
        <StyledDiv className="tab-content" id="importersContent">
            {importerGroups.map((group, index) =>
                <div className={`tab-pane fade ${index > 0 ? '' : 'show active'}`}
                     id={group}
                     role="tabpanel"
                     aria-labelledby={`${group}-tab`}>
                    <Select isMulti
                            styles={styles}
                            isDisabled={true}
                            isLoading={false}
                            isClearable={false}
                            value={importerGroupsMapping[group].map(country => Object.assign({}, {
                                value: country,
                                label: countryGroupMessages[country] ? intl.formatMessage(countryGroupMessages[country]) : country,
                                isFixed: true
                            })).sort((countryA, countryB) => countryA.label >= countryB.label ? 1 : -1)}/>
                </div>
            )}
        </StyledDiv>
    </div>
};

export default injectIntl(ImportersTable);
