import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    FORM_FIELD_FIC_CIR,
    FORM_FIELD_ISSUE_ORIGIN,
    FORM_FIELD_NOD_ID,
    FORM_FIELD_PART_REFERENCE_ASSOCIATED,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_DOCUMENT_ID,
    FORM_FIELD_TEMP_SOLUTION,
    FORM_LABEL_FIC_CIR,
    FORM_LABEL_ISSUE_ORIGIN,
    FORM_LABEL_NOD_ID,
    FORM_LABEL_PART_REFERENCE_ASSOCIATED,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_DOCUMENT_ID,
    FORM_LABEL_TEMP_SOLUTION,
    FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_LABEL_CUSTOMER_EFFECT,
    FORM_FIELD_CUSTOMER_EFFECT,
    FORM_LABEL_VEHICLE_COVERED_BY_WARRANTY,
    FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import formMessages from '../../../../../../intl/afterSales/formMessages';
import InternalTicketReferences from './InternalTicketReferences';
import {change, formValueSelector} from 'redux-form';
import {bindActionCreators} from 'redux';
import {warnAlert} from '../../../../../../actions/alertsActions';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {customerEffects, issueOrigins} from '../../../../../../constants/Utils';
import issueOriginMessages from '../../../../../../intl/afterSales/issueOriginMessages';
import customerEffectMessages from '../../../../../../intl/afterSales/customerEffectMessages';
import commonMessages from '../../../../../../intl/common/commonMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

class ProblemDescriptionSection extends Component {

    render() {
        const {readOnly, userRights, form, reqFields, intl: {formatMessage}} = this.props;

        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                    <div className="form-row">
                        <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_ISSUE_ORIGIN]}
                                                            field={FORM_FIELD_ISSUE_ORIGIN}
                                                            options={issueOrigins}
                                                            intlMessages={issueOriginMessages}
                                                            isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                            isRequired={reqFields.includes(FORM_FIELD_ISSUE_ORIGIN)}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_CUSTOMER_EFFECT]}
                                                            field={FORM_FIELD_CUSTOMER_EFFECT}
                                                            options={customerEffects}
                                                            intlMessages={customerEffectMessages}
                                                            isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                            isRequired={reqFields.includes(FORM_FIELD_CUSTOMER_EFFECT)}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_REFERENCE_ASSOCIATED]}
                                                      field={FORM_FIELD_PART_REFERENCE_ASSOCIATED}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_PART_REFERENCE_ASSOCIATED)}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-xl-2 col-lg-4 col-md-12 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_NOD_ID]}
                                                      field={FORM_FIELD_NOD_ID}
                                                      maxLength={20}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_NOD_ID)}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                                <InputFieldWithValidation label={formMessages[FORM_LABEL_DOCUMENT_ID]}
                                                          field={FORM_FIELD_DOCUMENT_ID}
                                                          maxLength={40}
                                                          isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                          isRequired={reqFields.includes(FORM_FIELD_NOD_ID)}
                                                          isSmall/>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_VEHICLE_COVERED_BY_WARRANTY]}
                                                            field={FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY}
                                                            options={['YES', 'NO']}
                                                            intlMessages={commonMessages}
                                                            isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                            isRequired={reqFields.includes(FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY)}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_FIC_CIR]}
                                                      field={FORM_FIELD_FIC_CIR}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_FIC_CIR)}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 pr-0 pb-1">
                            <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                         field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                         isDisabled={!userRights.canEditProblemDescText || readOnly}
                                                         rows={6}
                                                         isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 pr-0 pb-1">
                            <TextareaFieldWithValidation label={formMessages[FORM_LABEL_TEMP_SOLUTION]}
                                                         field={FORM_FIELD_TEMP_SOLUTION}
                                                         isDisabled={!userRights.canEditTempSolutionText || readOnly}
                                                         rows={6}
                                                         isRequired={reqFields.includes(FORM_FIELD_TEMP_SOLUTION)}/>
                        </div>
                    </div>
                    {userRights.canViewInternalTicketReferences && <InternalTicketReferences form={form} />}
                </StyledDiv>
            </>
        )
    }
}

const mapStateToProps = (state, initialProps) => ({
    user: state.profile.userDetail,
    submittedBy: state.aseTicket.submittedBy,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            warnAlert
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProblemDescriptionSection));
