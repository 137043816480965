import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import styled from 'styled-components';
import {moduleRoles} from '../../utils/roles';
import Loader from '../Loader';
import renaultCar from '../../images/car.png';
import mainPageMessages from '../../intl/home/mainPageMessages';
import AseDomainContent from './AseDomainContent';

const Img = styled.img`
    height: 35%;
    width: 35%;
`;
Img.displayName = 'Img';

class HomePage extends Component {
    render() {
        const {userDetail: {roles}, isLoadingAsePreferences} = this.props;

        return <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <h1 className="text-center"><FormattedMessage {...mainPageMessages.TITLE} /></h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="text-center pt-3">
                        <Img src={renaultCar}/>
                    </div>
                </div>
            </div>
            {
                <>
                    <div className="row pb-4">
                        {(moduleRoles.isDealerAse(roles) || moduleRoles.isEditorAse(roles) || moduleRoles.isSGEditorAse(roles)) &&
                        <div className="col-12 col-lg-6">
                            {isLoadingAsePreferences ? <Loader/> : <AseDomainContent/>}
                        </div>
                        }
                    </div>
                </>
            }
        </div>
    }
}

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
    isLoadingAsePreferences: get(state, 'asePreferences.isLoading'),
});

export default connect(mapStateToProps, {})(HomePage);
