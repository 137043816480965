import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';
import get from 'get-value';
import {
    ASE_RECURRENT_PROBLEM_PENDING,
    ASE_RECURRENT_PROBLEM_SELECT_GROUP
} from '../../actions/ase/actionAseRecurrentProblem';

export const aseRecurrentProblem = (state = {
    isLoading: false,
    recurrentProblems: [],
    selectedCountryGroup: '',
}, action) => {
    switch (action.type) {
        case ASE_RECURRENT_PROBLEM_PENDING: {
            return Object.assign({}, state, {isLoading: true, recurrentProblems: []});
        }
        case aseWebsocketCommands.ASE_RECURRENT_PROBLEMS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                recurrentProblems: get(action, 'payload.recurrentProblems', {default: []}),
                correlationId
            });
        }
        case aseWebsocketCommands.ASE_RECURRENT_PROBLEMS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, recurrentProblems: [], correlationId});
        }
        case ASE_RECURRENT_PROBLEM_SELECT_GROUP: {
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        }
        default:
            return state;
    }
};
