import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import Select from 'react-select';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import authManagementMessages from '../../../intl/admin/authManagementMessages';
import {BoldPrimaryButton, PrimaryButton, SecondaryButton} from '../../common/Button';
import buttonMessages from '../../../intl/common/buttonMessages';

const Label = styled.label`
  font-weight: bold;
`;

const SelectUserSolutionGroups = props => {
    const {
        solutionGroupEditorData, intl: {formatMessage}, selectedSolutionGroupEditorOptions, fixedSolutionGroupEditorOptions, solutionGroupEditorRightsChanged, handleSolutionGroupSelectionChange,
        handleAllSolutionGroupsClick, handleSaveClick, handleCancelClick
    } = props;

    const options = (solutionGroupEditorData || []).map(sg => ({
        value: sg.id ? sg.id : sg.value,
        label: sg.name ? sg.name : sg.label,
        groups: sg.groups,
        isDefault: sg.isDefault,
        isActive: sg.isActive,
        canForwardToDealer: sg.canForwardToDealer,
    }))

    const formattedSelectedOptions = (selectedSolutionGroupEditorOptions || []).map(sg => ({
            value: sg.id ? sg.id : sg.value,
            label: sg.name ? sg.name : sg.label,
            groups: sg.groups,
            isDefault: sg.isDefault,
            isActive: sg.isActive,
            canForwardToDealer: sg.canForwardToDealer,
        }))

    const styles = {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed
                ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                : base;
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
    };

    return (
        <div className="container-fluid">
            <div className="row mb-sm-2">
                <div className="col-sm-12">
                    <Label>
                        <FormattedMessage {...authManagementMessages.EDITOR_SG_SELECT_SG}/>
                    </Label>
                    <PrimaryButton type="button" className="btn ml-sm-3" onClick={handleAllSolutionGroupsClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col">
                    <Select isMulti isSearchable
                            options={options}
                            value={[].concat(fixedSolutionGroupEditorOptions).concat(formattedSelectedOptions)}
                            isClearable={options.some(v => !v.isFixed)}
                            placeholder={formatMessage(authManagementMessages.EDITOR_SG_PLACEHOLDER_SELECT_SG)}
                            styles={styles}
                            onChange={handleSolutionGroupSelectionChange}/>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col-sm-6">
                    <SecondaryButton type="button" className="btn float-right" onClick={handleCancelClick}
                                     disabled={!solutionGroupEditorRightsChanged}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </SecondaryButton>
                </div>
                <div className="col-sm-6">
                    <BoldPrimaryButton type="button" className="btn float-left" onClick={handleSaveClick}
                                       disabled={!solutionGroupEditorRightsChanged}>
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                </div>
            </div>
        </div>
    );
};

SelectUserSolutionGroups.propTypes = {
    solutionGroupEditorData: PropTypes.object.isRequired,
    solutionGroupOptions: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    selectedSolutionGroupEditorOptions: PropTypes.array,
    fixedSolutionGroupEditorOptions: PropTypes.array,
    solutionGroupEditorRightsChanged: PropTypes.bool.isRequired,
    handleSolutionGroupSelectionChange: PropTypes.func.isRequired,
    handleAllSolutionGroupsClick: PropTypes.func.isRequired,
    handleSaveClick: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    solutionGroupEditorData: state.adminRightsManagement.solutionGroupEditorData,
    selectedSolutionGroupEditorOptions: state.adminRightsManagement.selectedSolutionGroupEditorOptions,
    fixedSolutionGroupEditorOptions: state.adminRightsManagement.fixedSolutionGroupEditorOptions,
    solutionGroupEditorRightsChanged: state.adminRightsManagement.solutionGroupEditorRightsChanged,
    solutionGroupOptions: state.adminRightsManagement.solutionGroupOptions,
});

export default connect(mapStateToProps, {})(injectIntl(SelectUserSolutionGroups))
