export const aseWebsocketCommands = {
    // route (commands)
    ASE_HISTORY_GET: 'getHistory',
    ASE_LIST_GET: 'getList',
    ASE_INTERNAL_TICKET_HISTORY_GET: 'getInternalHistory',
    ASE_INTERNAL_TICKET_LIST_GET: 'getInternalList',
    ASE_INTERNAL_TICKET_SEARCH_GET: 'getFullTextSearchInternal',
    ASE_POPUP_GET: 'getPopUp',
    ASE_POPUP_SET: 'setPopUp',
    ASE_RECURRENT_PROBLEMS_GET: 'getRecurrentProblem',
    ASE_RECURRENT_PROBLEMS_CREATE: 'createRecurrentProblem',
    ASE_RECURRENT_PROBLEMS_MOVE: 'moveRecurrentProblem',
    ASE_RECURRENT_PROBLEMS_DELETE: 'deleteRecurrentProblem',
    ASE_RECURRENT_PROBLEMS_UPDATE: 'updateRecurrentProblem',
    ASE_RECURRENT_PROBLEMS_FILE_DOWNLOAD: 'recurrentProblemAttachmentDownload',
    ASE_RECURRENT_PROBLEMS_FILE_UPLOAD: 'recurrentProblemAttachmentUpload',
    ASE_USER_LIST_PREFERENCE_SET: 'setUserListPreference',
    ASE_USER_HISTORY_PREFERENCE_SET: 'setUserHistoryPreference',
    ASE_USER_FULL_TEXT_SEARCH_PREFERENCE_SET: 'setUserFullTextSearchPreference',
    ASE_USER_INTERNAL_LIST_PREFERENCE_SET: 'setUserInternalListPreference',
    ASE_USER_INTERNAL_HISTORY_PREFERENCE_SET: 'setUserInternalHistoryPreference',
    ASE_USER_INTERNAL_FULL_TEXT_SEARCH_PREFERENCE_SET: 'setUserFullTextSearchInternalPreference',
    ASE_USER_STATISTICS_PREFERENCE_SET: 'setUserStatisticsPreference',
    ASE_USER_STATISTICS_SG_PREFERENCE_SET: 'setUserStatisticsSGPreference',
    ASE_PREFERENCE_GET: 'getPreferences',
    ASE_DEALERS_GET: 'getDealers',
    ASE_EXPORT_GET: 'getExport',
    ASE_EXPORT_CREATE: 'createTicketExport',
    ASE_EXPORT_DOWNLOAD_URL_GET: 'exportDownloadPreSignedUrl',
    ASE_EXPORT_INTERNAL_GET: 'getInternalExport',
    ASE_EXPORT_INTERNAL_CREATE: 'createInternalTicketExport',
    ASE_EXPORT_INTERNAL_DOWNLOAD_URL_GET: 'internalExportDownloadPreSignedUrl',
    ASE_FULL_TEXT_SEARCH_GET: 'getFullTextSearch',
    ASE_FULL_TEXT_SEARCH_LAST_EDITORS_GET: 'getLastEditors',

    ASE_ADMIN_ROLE_GET: 'getAdminRole',
    ASE_ADMIN_ROLE_SET: 'setAdminRole',
    ASE_DEALER_ROLE_GET: 'getDealerRole',
    ASE_DEALER_ROLE_SET: 'setDealerRole',
    ASE_EDITOR_ROLE_GET: 'getEditorRole',
    ASE_EDITOR_ROLE_SET: 'setEditorRole',
    ASE_NOTIFICATIONS_GET: 'getEmailNotification',
    ASE_NOTIFICATIONS_SET: 'setEmailNotification',
    ASE_MAIN_MESSAGES_GET: 'getGroupsMainMessages',
    ASE_MAIN_MESSAGE_GET: 'getMainMessage',
    ASE_MAIN_MESSAGE_SET: 'setMainMessage',
    ASE_TICKET_SETTINGS_GET: 'getTicketSettings',
    ASE_TICKET_SETTINGS_SET: 'updateTicketSettings',
    ASE_DEFAULT_DEALER_ROLE_GET: 'getDefaultDealerRole',
    ASE_DEFAULT_DEALER_ROLE_SET: 'setDefaultDealerRole',
    ASE_WORKING_HOURS_GET: 'getWorkingHours',
    ASE_WORKING_HOURS_SET: 'setWorkingHours',

    // receive
    ASE_TICKET_SEND: 'ASE_TICKET_SEND',
    ASE_TICKET_SEND_ERROR: 'TASETICKET_SEND_ERROR',
    ASE_TICKET_LIST_SEND: 'ASE_TICKET_LIST_SEND',
    ASE_TICKET_LIST_SEND_ERROR: 'ASE_TICKET_LIST_SEND_ERROR',
    ASE_TICKET_HISTORY_SEND: 'ASE_TICKET_HISTORY_SEND',
    ASE_TICKET_HISTORY_SEND_ERROR: 'ASE_TICKET_HISTORY_SEND_ERROR',
    ASE_INTERNAL_TICKET_LIST_SEND: 'ASE_INTERNAL_TICKET_LIST_SEND',
    ASE_INTERNAL_TICKET_LIST_SEND_ERROR: 'ASE_INTERNAL_TICKET_LIST_SEND_ERROR',
    ASE_INTERNAL_TICKET_HISTORY_SEND: 'ASE_INTERNAL_TICKET_HISTORY_SEND',
    ASE_INTERNAL_TICKET_HISTORY_SEND_ERROR: 'ASE_INTERNAL_TICKET_HISTORY_SEND_ERROR',
    ASE_INTERNAL_TICKET_SEARCH_SEND: 'ASE_INTERNAL_TICKET_SEARCH_SEND',
    ASE_INTERNAL_TICKET_SEARCH_SEND_ERROR: 'ASE_INTERNAL_TICKET_SEARCH_SEND_ERROR',
    ASE_GENERATED_PRESIGNED_URLS: 'ASE_GENERATED_PRESIGNED_URLS',
    ASE_TICKET_CREATED: 'ASE_TICKET_CREATED',
    ASE_TICKET_CREATE_ERROR: 'ASE_TICKET_CREATE_ERROR',
    ASE_TICKET_LOCKED: 'ASE_TICKET_LOCKED',
    ASE_TICKET_LOCKED_ERROR: 'ASE_TICKET_LOCKED_ERROR',
    ASE_TICKET_UNLOCKED: 'ASE_TICKET_UNLOCKED',
    ASE_TICKET_UNLOCKED_ERROR: 'ASE_TICKET_UNLOCKED_ERROR',
    ASE_TICKET_ATTACHMENTS_SAVED: 'ASE_TICKET_ATTACHMENTS_SAVED',
    ASE_TICKET_ATTACHMENTS_SAVED_ERROR: 'ASE_TICKET_ATTACHMENTS_SAVED_ERROR',
    ASE_TICKET_ATTACHMENTS_DOWNLOAD: 'ASE_TICKET_ATTACHMENTS_DOWNLOAD',
    ASE_TICKET_ATTACHMENTS_DOWNLOAD_ERROR: 'ASE_TICKET_ATTACHMENTS_DOWNLOAD_ERROR',
    ASE_TICKET_UPDATED: 'ASE_TICKET_UPDATED',
    ASE_TICKET_UPDATE_ERROR: 'ASE_TICKET_UPDATE_ERROR',
    ASE_ROLES_ACCEPTED: 'ASE_ROLES_ACCEPTED',
    ASE_ROLES_ERROR: 'ASE_ROLES_ERROR',
    ASE_ADMIN_ROLE_SEND: 'ASE_ADMIN_ROLE_SEND',
    ASE_ADMIN_ROLE_SEND_ERROR: 'ASE_ADMIN_ROLE_SEND_ERROR',
    ASE_DEALER_ROLE_SEND: 'ASE_DEALER_ROLE_SEND',
    ASE_DEALER_ROLE_SEND_ERROR: 'ASE_DEALER_ROLE_SEND_ERROR',
    ASE_EDITOR_ROLE_SEND: 'ASE_EDITOR_ROLE_SEND',
    ASE_EDITOR_ROLE_SEND_ERROR: 'ASE_EDITOR_ROLE_SEND_ERROR',
    ASE_NOTIFICATIONS_ACCEPTED: 'ASE_NOTIFICATIONS_ACCEPTED',
    ASE_NOTIFICATIONS_ERROR: 'ASE_NOTIFICATIONS_ERROR',
    ASE_NOTIFICATIONS_SEND: 'ASE_NOTIFICATIONS_SEND',
    ASE_NOTIFICATIONS_SEND_ERROR: 'ASE_NOTIFICATIONS_SEND_ERROR',
    ASE_WORKING_HOURS_ACCEPTED: 'ASE_WORKING_HOURS_ACCEPTED',
    ASE_WORKING_HOURS_ERROR: 'ASE_WORKING_HOURS_ERROR',
    ASE_WORKING_HOURS_SEND: 'ASE_WORKING_HOURS_SEND',
    ASE_WORKING_HOURS_SEND_ERROR: 'ASE_WORKING_HOURS_SEND_ERROR',
    ASE_RECURRENT_PROBLEMS_ACCEPTED: 'ASE_RECURRENT_PROBLEMS_ACCEPTED',
    ASE_RECURRENT_PROBLEMS_ERROR: 'ASE_RECURRENT_PROBLEMS_ERROR',
    ASE_RECURRENT_PROBLEMS_SEND: 'ASE_RECURRENT_PROBLEMS_SEND',
    ASE_RECURRENT_PROBLEMS_SEND_ERROR: 'ASE_RECURRENT_PROBLEMS_SEND_ERROR',
    ASE_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD: 'ASE_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD',
    ASE_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD_ERROR: 'ASE_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD_ERROR',
    ASE_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD: 'ASE_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD',
    ASE_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD_ERROR: 'ASE_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD_ERROR',
    ASE_MAIN_MESSAGES_ACCEPTED: 'ASE_MAIN_MESSAGES_ACCEPTED',
    ASE_MAIN_MESSAGES_ERROR: 'ASE_MAIN_MESSAGES_ERROR',
    ASE_MAIN_MESSAGES_SEND: 'ASE_MAIN_MESSAGES_SEND',
    ASE_MAIN_MESSAGES_SEND_ERROR: 'ASE_MAIN_MESSAGES_SEND_ERROR',
    ASE_GROUPS_MAIN_MESSAGES_SEND: 'ASE_GROUPS_MAIN_MESSAGES_SEND',
    ASE_GROUPS_MAIN_MESSAGES_SEND_ERROR: 'ASE_GROUPS_MAIN_MESSAGES_SEND_ERROR',
    ASE_POPUP_ACCEPTED: 'ASE_POPUP_ACCEPTED',
    ASE_POPUP_ERROR: 'ASE_POPUP_ERROR',
    ASE_POPUP_SEND: 'ASE_POPUP_SEND',
    ASE_POPUP_SEND_ERROR: 'ASE_POPUP_SEND_ERROR',
    ASE_TICKET_SETTINGS_ACCEPTED: 'ASE_TICKET_SETTINGS_ACCEPTED',
    ASE_TICKET_SETTINGS_ERROR: 'ASE_TICKET_SETTINGS_ERROR',
    ASE_TICKET_SETTINGS_SEND: 'ASE_TICKET_SETTINGS_SEND',
    ASE_TICKET_SETTINGS_SEND_ERROR: 'ASE_TICKET_SETTINGS_SEND_ERROR',
    ASE_DEFAULT_DEALER_ROLES_SEND: 'ASE_DEFAULT_DEALER_ROLES_SEND',
    ASE_DEFAULT_DEALER_ROLES_SEND_ERROR: 'ASE_DEFAULT_DEALER_ROLES_SEND_ERROR',
    ASE_PREFERENCES_ACCEPTED: 'ASE_PREFERENCES_ACCEPTED',
    ASE_PREFERENCES_ERROR: 'ASE_PREFERENCES_ERROR',
    ASE_PREFERENCES_SEND: 'ASE_PREFERENCES_SEND',
    ASE_PREFERENCES_SEND_ERROR: 'ASE_PREFERENCES_SEND_ERROR',
    ASE_DEALERS_SEND: 'ASE_DEALERS_FETCH_SUCCESS',
    ASE_DEALERS_SEND_ERROR: 'ASE_DEALERS_FETCH_ERROR',
    ASE_EXPORT_SEND: 'ASE_EXPORT_FETCH_SUCCESS',
    ASE_EXPORT_SEND_ERROR: 'ASE_EXPORT_FETCH_ERROR',
    ASE_EXPORT_STATUS: 'ASE_EXPORT_STATUS',
    ASE_EXPORT_ERROR: 'ASE_EXPORT_ERROR',
    ASE_EXPORT_DOWNLOAD_URL_SEND: 'ASES_EXPORT_DOWNLOAD',
    ASE_EXPORT_DOWNLOAD_URL_SEND_ERROR: 'ASES_EXPORT_DOWNLOAD_ERROR',
    ASE_EXPORT_INTERNAL_SEND: 'ASE_EXPORT_INTERNAL_FETCH_SUCCESS',
    ASE_EXPORT_INTERNAL_SEND_ERROR: 'ASE_EXPORT_INTERNAL_FETCH_ERROR',
    ASE_EXPORT_INTERNAL_STATUS: 'ASE_EXPORT_INTERNAL_STATUS',
    ASE_EXPORT_INTERNAL_ERROR: 'ASE_EXPORT_INTERNAL_ERROR',
    ASE_EXPORT_INTERNAL_DOWNLOAD_URL_SEND: 'ASE_EXPORT_INTERNAL_DOWNLOAD',
    ASE_EXPORT_INTERNAL_DOWNLOAD_URL_SEND_ERROR: 'ASE_EXPORT_INTERNAL_DOWNLOAD_ERROR',
    ASE_FULL_TEXT_SEARCH_SEND: 'ASE_FULL_TEXT_SEARCH_FETCH_SUCCESS',
    ASE_FULL_TEXT_SEARCH_SEND_ERROR: 'ASE_FULL_TEXT_SEARCH_FETCH_ERROR',
    ASE_FULL_TEXT_SEARCH_LAST_EDITORS_SEND: 'ASE_FULL_TEXT_SEARCH_LAST_EDITORS_FETCH_SUCCESS',
    ASE_FULL_TEXT_SEARCH_LAST_EDITORS_SEND_ERROR: 'ASE_FULL_TEXT_SEARCH_LAST_EDITORS_FETCH_ERROR',
    // PRESET RESPONSE
    ASE_PRESET_RESPONSE_GET: 'getPresetResponses',
    ASE_PRESET_RESPONSE_CREATE: 'createPresetResponse',
    ASE_PRESET_RESPONSE_DELETE: 'deletePresetResponse',
    // PRESET RESPONSE
    ASE_PRESET_RESPONSES_ERROR: 'ASE_PRESET_RESPONSES_ERROR',
    ASE_PRESET_RESPONSES_SEND: 'ASE_PRESET_RESPONSES_SEND',
    ASE_PRESET_RESPONSES_SEND_ERROR: 'ASE_PRESET_RESPONSES_SEND_ERROR',

    //SOLUTION GROUPS
    //Get List Solution Group
    ASE_SOLUTION_GROUPS_LIST: 'getSolutionGroups',
    ASE_SOLUTION_GROUPS_LIST_FETCH_PENDING: 'ASE_SOLUTION_GROUPS_LIST_FETCH_PENDING',
    ASE_SOLUTION_GROUPS_LIST_SEND: 'ASE_SOLUTION_GROUPS_LIST_SEND',
    ASE_SOLUTION_GROUPS_LIST_SEND_ERROR: 'ASE_SOLUTION_GROUPS_LIST_SEND_ERROR',
    //Get Detail Solution Group
    ASE_SOLUTION_GROUPS_DETAIL: 'getSolutionGroup',
    ASE_SOLUTION_GROUPS_DETAIL_FETCH_PENDING: 'ASE_SOLUTION_GROUP_DETAIL_FETCH_PENDING',
    //Create Solution Group
    ASE_SOLUTION_GROUPS_CREATE: 'createSolutionGroup',
    ASE_SOLUTION_GROUPS_CREATE_PENDING: 'ASE_SOLUTION_GROUPS_CREATE_PENDING',
    ASE_SOLUTION_GROUPS_ACCEPTED: 'ASE_SOLUTION_GROUPS_ACCEPTED',
    ASE_SOLUTION_GROUPS_ERROR: 'ASE_SOLUTION_GROUPS_ERROR',
    //Update Solution Group
    ASE_SOLUTION_GROUPS_UPDATE: 'updateSolutionGroup',
    ASE_SOLUTION_GROUPS_UPDATE_PENDING: 'ASE_SOLUTION_GROUPS_UPDATE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    ASE_SOLUTION_GROUP_RESET: 'ASE_SOLUTION_GROUP_RESET',
    ASE_SOLUTION_GROUP_REDIRECT_RESET: 'ASE_SOLUTION_GROUP_REDIRECT_RESET',
    ASE_SOLUTION_GROUPS_SEND: 'ASE_SOLUTION_GROUPS_SEND',
    ASE_SOLUTION_GROUPS_SEND_ERROR: 'ASE_SOLUTION_GROUPS_SEND_ERROR',
    ASE_SOLUTION_GROUP_SAVE_NEW_ERROR: 'ASE_SOLUTION_GROUP_SAVE_NEW_ERROR',
    ASE_SOLUTION_GROUP_SAVE_UPDATE_ERROR: 'ASE_SOLUTION_GROUP_SAVE_UPDATE_ERROR',

    ASE_SOLUTION_GROUPS_USER_ROLE_GET: 'getSolutionGroupEditorRole',
    ASE_SOLUTION_GROUPS_USER_ROLE_SET: 'setSolutionGroupEditorRole',

    //fetch SG options
    ASE_ADMIN_SOLUTION_GROUPS_OPTIONS: 'getAdminSolutionGroups',
    ASE_ADMIN_SOLUTION_GROUPS_FETCH_PENDING: 'ASE_ADMIN_SOLUTION_GROUPS_FETCH_PENDING',
    ASE_ADMIN_SOLUTION_GROUPS_SEND: 'ASE_ADMIN_SOLUTION_GROUPS_SEND',

    ASE_SOLUTION_GROUPS_EDITOR_ROLE_SEND: 'ASE_SOLUTION_GROUPS_EDITOR_ROLE_SEND',
    ASE_SOLUTION_GROUPS_EDITOR_ROLE_SEND_ERROR: 'ASE_SOLUTION_GROUPS_EDITOR_ROLE_SEND_ERROR',


    //ESCALATION SCHEMA
    //Get List Solution Group
    ASE_ESCALATION_SCHEMA_LIST: 'getEscalationSchemas',
    ASE_ESCALATION_SCHEMA_LIST_FETCH_PENDING: 'ASE_ESCALATION_SCHEMA_LIST_FETCH_PENDING',
    ASE_ESCALATION_SCHEMA_LIST_SEND: 'ASE_ESCALATION_SCHEMA_LIST_SEND',
    ASE_ESCALATION_SCHEMA_LIST_SEND_ERROR: 'ASE_ESCALATION_SCHEMA_LIST_SEND_ERROR',
    //Get Detail Solution Group
    ASE_ESCALATION_SCHEMA_DETAIL: 'getEscalationSchema',
    ASE_ESCALATION_SCHEMA_DETAIL_FETCH_PENDING: 'ASE_ESCALATION_SCHEMA_DETAIL_FETCH_PENDING',
    //Create Solution Group
    ASE_ESCALATION_SCHEMA_CREATE: 'createEscalationSchema',
    ASE_ESCALATION_SCHEMA_CREATE_PENDING: 'ASE_ESCALATION_SCHEMA_CREATE_PENDING',
    ASE_ESCALATION_SCHEMA_ACCEPTED: 'ASE_ESCALATION_SCHEMA_ACCEPTED',
    ASE_ESCALATION_SCHEMA_ERROR: 'ASE_ESCALATION_SCHEMA_ERROR',
    //Update Solution Group
    ASE_ESCALATION_SCHEMA_UPDATE: 'updateEscalationSchema',
    ASE_ESCALATION_SCHEMA_UPDATE_PENDING: 'ASE_ESCALATION_SCHEMA_UPDATE_PENDING',
    //Delete Solution Group
    ASE_ESCALATION_SCHEMA_DELETE: 'deleteEscalationSchema',
    ASE_ESCALATION_SCHEMA_DELETE_PENDING: 'ASE_ESCALATION_SCHEMA_DELETE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    ASE_ESCALATION_SCHEMA_RESET: 'ASE_ESCALATION_SCHEMA_RESET',
    ASE_ESCALATION_SCHEMA_REDIRECT_RESET: 'ASE_ESCALATION_SCHEMA_REDIRECT_RESET',
    ASE_ESCALATION_SCHEMA_SEND: 'ASE_ESCALATION_SCHEMA_SEND',
    ASE_ESCALATION_SCHEMA_SEND_ERROR: 'ASE_ESCALATION_SCHEMA_SEND_ERROR',
    ASE_ESCALATION_SCHEMA_SAVE_NEW_ERROR: 'ASE_ESCALATION_SCHEMA_SAVE_NEW_ERROR',
    ASE_ESCALATION_SCHEMA_SAVE_UPDATE_ERROR: 'ASE_ESCALATION_SCHEMA_SAVE_UPDATE_ERROR',


    //ANSWERING SCHEMA
    //Get List Solution Group
    ASE_ANSWERING_SCHEMA_LIST: 'getAnsweringSchemas',
    ASE_ANSWERING_SCHEMA_LIST_FETCH_PENDING: 'ASE_ANSWERING_SCHEMA_LIST_FETCH_PENDING',
    ASE_ANSWERING_SCHEMA_LIST_SEND: 'ASE_ANSWERING_SCHEMA_LIST_SEND',
    ASE_ANSWERING_SCHEMA_LIST_SEND_ERROR: 'ASE_ANSWERING_SCHEMA_LIST_SEND_ERROR',
    //Get Detail Solution Group
    ASE_ANSWERING_SCHEMA_DETAIL: 'getAnsweringSchema',
    ASE_ANSWERING_SCHEMA_DETAIL_FETCH_PENDING: 'ASE_ANSWERING_SCHEMA_DETAIL_FETCH_PENDING',
    //Create Solution Group
    ASE_ANSWERING_SCHEMA_CREATE: 'createAnsweringSchema',
    ASE_ANSWERING_SCHEMA_CREATE_PENDING: 'ASE_ANSWERING_SCHEMA_CREATE_PENDING',
    ASE_ANSWERING_SCHEMA_ACCEPTED: 'ASE_ANSWERING_SCHEMA_ACCEPTED',
    ASE_ANSWERING_SCHEMA_ERROR: 'ASE_ANSWERING_SCHEMA_ERROR',
    //Update Solution Group
    ASE_ANSWERING_SCHEMA_UPDATE: 'updateAnsweringSchema',
    ASE_ANSWERING_SCHEMA_UPDATE_PENDING: 'ASE_ANSWERING_SCHEMA_UPDATE_PENDING',
    //Delete Solution Group
    ASE_ANSWERING_SCHEMA_DELETE: 'deleteAnsweringSchema',
    ASE_ANSWERING_SCHEMA_DELETE_PENDING: 'ASE_ANSWERING_SCHEMA_DELETE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    ASE_ANSWERING_SCHEMA_RESET: 'ASE_ANSWERING_SCHEMA_RESET',
    ASE_ANSWERING_SCHEMA_REDIRECT_RESET: 'ASE_ANSWERING_SCHEMA_REDIRECT_RESET',
    ASE_ANSWERING_SCHEMA_SEND: 'ASE_ANSWERING_SCHEMA_SEND',
    ASE_ANSWERING_SCHEMA_SEND_ERROR: 'ASE_ANSWERING_SCHEMA_SEND_ERROR',
    ASE_ANSWERING_SCHEMA_SAVE_NEW_ERROR: 'ASE_ANSWERING_SCHEMA_SAVE_NEW_ERROR',
    ASE_ANSWERING_SCHEMA_SAVE_UPDATE_ERROR: 'ASE_ANSWERING_SCHEMA_SAVE_UPDATE_ERROR',
}
