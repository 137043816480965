import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_HEADER_STATUS: {
        id: 'mainPage.statistics.table.header.status',
        defaultMessage: 'Status'
    },
    TABLE_HEADER_TOTAL: {
        id: 'mainPage.statistics.table.header.total',
        defaultMessage: 'Sum'
    },
    TABLE_FOOTER_TOTAL: {
        id: 'mainPage.statistics.table.footer.total',
        defaultMessage: 'Total'
    },
    TABLE_ROW_ASE_NEW: {
        id: 'mainPage.statistics.table.row.afterSales.new',
        defaultMessage: 'After Sales (Created)'
    },
    TABLE_ROW_ASE_WAIT_DEALER: {
        id: 'mainPage.statistics.table.row.afterSales.waitDealer',
        defaultMessage: 'Waiting After Sales (pending dealer)'
    },
    TABLE_ROW_ASE_READ_BY_DEALER: {
        id: 'mainPage.statistics.table.row.afterSales.readDealer',
        defaultMessage: 'In progress After Sales (read by dealer)'
    },
    TABLE_ROW_ASE_READ_BY_SUBSIDIARY: {
        id: 'mainPage.statistics.table.row.afterSales.readSubsidiary',
        defaultMessage: 'In progress After Sales (read by subsidiary)'
    },
    TABLE_ROW_ASE_WAIT_SUBSIDIARY: {
        id: 'mainPage.statistics.table.row.afterSales.waitSubsidiary',
        defaultMessage: 'Waiting After Sales (pending subsidiary)'
    },
    TABLE_ROW_ASE_REOPEN: {
        id: 'mainPage.statistics.table.row.afterSales.reopened',
        defaultMessage: 'Reopened After Sales'
    },
    TABLE_ROW_ASE_CLOSED: {
        id: 'mainPage.statistics.table.row.afterSales.closed',
        defaultMessage: 'Closed After Sales'
    },
    TABLE_ROW_ASE_TRANSFERRED: {
        id: 'mainPage.statistics.table.row.afterSales.transferred',
        defaultMessage: 'Transferred After Sales'
    },
});

export default messages;
