import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    FORM_FIELD_ISSUE_ORIGIN,
    FORM_FIELD_NOD_ID,
    FORM_FIELD_PART_REFERENCE_ASSOCIATED,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_DOCUMENT_ID,
    FORM_FIELD_TEMPORARY_SOLUTION,
    FORM_LABEL_ISSUE_ORIGIN,
    FORM_LABEL_NOD_ID,
    FORM_LABEL_PART_REFERENCE_ASSOCIATED,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_DOCUMENT_ID,
    FORM_LABEL_TEMPORARY_SOLUTION,
} from '../../../../../../constants/formConstants';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import formMessages from '../../../../../../intl/afterSales/formMessages';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {issueOrigin} from '../../../../../../constants/Utils';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import internalTicketIssueOriginMessages
    from '../../../../../../intl/afterSales/internalTicketIssueOriginMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const ProblemDescriptionSectionInternal = (props) => {
    const {readOnly, userRights, reqFields, intl: {formatMessage}} = props;

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-4 pr-0 pb-1">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_ISSUE_ORIGIN]}
                                                        field={FORM_FIELD_ISSUE_ORIGIN}
                                                        options={issueOrigin}
                                                        intlMessages={internalTicketIssueOriginMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_ISSUE_ORIGIN)}
                                                        isDisabled={!userRights.canEditInternalTicketProblemDesc || readOnly}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_REFERENCE_ASSOCIATED]}
                                                  field={FORM_FIELD_PART_REFERENCE_ASSOCIATED}
                                                  maxLength={10}
                                                  isDisabled={!userRights.canEditInternalTicketProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_PART_REFERENCE_ASSOCIATED)}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-2 col-lg-4 col-md-12 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_NOD_ID]}
                                                  field={FORM_FIELD_NOD_ID}
                                                  maxLength={20}
                                                  isDisabled={!userRights.canEditInternalTicketProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_NOD_ID)}
                                                  isSmall/>
                    </div>
                    <div className="col-4 pr-0 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_DOCUMENT_ID]}
                                                  field={FORM_FIELD_DOCUMENT_ID}
                                                  isSmall
                                                  maxLength={40}
                                                  isRequired={reqFields.includes(FORM_FIELD_DOCUMENT_ID)}
                                                  isDisabled={!userRights.canEditInternalTicketProblemDesc || readOnly}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-12 pr-0 pb-1">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                     field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                     isDisabled={!userRights.canEditInternalTicketProblemDesc || readOnly}
                                                     rows={6}
                                                     maxLength={2000}
                                                     isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}
                                                     />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-12 pr-0 pb-1">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_TEMPORARY_SOLUTION]}
                                                     field={FORM_FIELD_TEMPORARY_SOLUTION}
                                                     isDisabled={!userRights.canEditInternalTicketProblemDesc || readOnly}
                                                     rows={6}
                                                     maxLength={1500}
                                                     isRequired={reqFields.includes(FORM_FIELD_TEMPORARY_SOLUTION)}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )
}

export default connect(null, null)(injectIntl(ProblemDescriptionSectionInternal));
