import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {change, formValueSelector} from 'redux-form';
import formMessages from '../../../../../../../intl/admin/answeringSchemaMessages';
import {
    ASE_INTERNAL_TICKET_FORM,
    FORM_ANSWERING_SCHEMA_ANSWERING_SECTION,
    FORM_ANSWERING_SCHEMA_INFO_SECTION,
    FORM_FIELD_AS_ANSWERING_GROUPS,
    FORM_FIELD_AS_FORMTYPE,
    FORM_FIELD_AS_SG_NAME, FORM_FIELD_ES_FORMTYPE,
    FORM_LABEL_AS_FORMTYPE,
    FORM_LABEL_AS_SG_NAME,
} from '../../../../../../../constants/formConstants';
import {FormattedMessage, injectIntl} from 'react-intl';
import Select from 'react-select';
import {Label, RequiredSpan} from '../../../../../../common/StyledComponents';
import {
    getCurrentFormTypes,
} from '../../../../../../../constants/Utils';
import formTypeMessages from '../../../../../../../intl/common/formTypeMessages';
import {PrimaryButton} from '../../../../../../common/Button';
import buttonMessages from '../../../../../../../intl/common/buttonMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const AnsweringInfoSection = (props) => {
    const {
        readOnly, reqFields,  intl: {formatMessage}, domain, answeringSchemaOptions, selectedSG, selectedFormType, change
    } = props;

    const handleSGSelectionChange = newOptions => {
        change(`${FORM_ANSWERING_SCHEMA_INFO_SECTION}.${FORM_FIELD_AS_SG_NAME}`, newOptions);
        change(`${FORM_ANSWERING_SCHEMA_ANSWERING_SECTION}.${FORM_FIELD_AS_ANSWERING_GROUPS}`, "");
    };

    const handleFormTypeSelectionChange = newOptions => {
        change(`${FORM_ANSWERING_SCHEMA_INFO_SECTION}.${FORM_FIELD_AS_FORMTYPE}`, newOptions);
    };

    const handleSelectAll = () => {
        change(`${FORM_ANSWERING_SCHEMA_INFO_SECTION}.${FORM_FIELD_ES_FORMTYPE}`, allFormTypesOptions );
    }

    const dealerFormTypeOptions = (getCurrentFormTypes(domain) || {}).map(formType => ({
        value: formType,
        label: formTypeMessages[formType] ? formatMessage(formTypeMessages[formType]) : formType
    }));

    let internalFormTypesOptions = [{
        value: ASE_INTERNAL_TICKET_FORM,
        label: (formTypeMessages[ASE_INTERNAL_TICKET_FORM] ? formatMessage(
                formTypeMessages[ASE_INTERNAL_TICKET_FORM])
            : ASE_INTERNAL_TICKET_FORM) + ' (Internal)'
    }];


    const allFormTypesOptions = [...dealerFormTypeOptions, ...internalFormTypesOptions];

    return (
        <StyledDiv className="border border-secondary px-4 py-2 bg-white">
            <div className="form-row">
                <div className="col-12 pb-2"/>
            </div>
            <div className="row mb-sm-2">
                <Label>
                    <FormattedMessage {...formMessages[FORM_LABEL_AS_SG_NAME]} />
                    {!readOnly && <RequiredSpan>{'\u00A0*'}</RequiredSpan>}
                </Label>
            </div>
            <div className="row mb-sm-3">
                <div className="col">
                    <Select isSearchable
                            isDisabled={readOnly}
                            field={FORM_FIELD_AS_SG_NAME}
                            options={answeringSchemaOptions}
                            onChange={handleSGSelectionChange}
                            value={selectedSG}
                            isRequired={reqFields.includes(FORM_FIELD_AS_SG_NAME) && !readOnly}
                            isClearable={true}/>
                </div>
            </div>
            <div className="row mb-sm-2">
                <Label>
                    <FormattedMessage {...formMessages[FORM_LABEL_AS_FORMTYPE]} />
                    {!readOnly ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
                </Label>
                {!readOnly && <PrimaryButton type="button" className="btn ml-sm-3" onClick={handleSelectAll}>
                    <FormattedMessage {...buttonMessages.ALL}/>
                </PrimaryButton>}
            </div>
            <div className="row mb-sm-3">
                <div className="col">
                    <Select isSearchable
                            isMulti={!readOnly}
                            isDisabled={readOnly}
                            field={FORM_FIELD_AS_FORMTYPE}
                            options={allFormTypesOptions}
                            onChange={handleFormTypeSelectionChange}
                            value={selectedFormType}
                            isRequired={reqFields.includes(FORM_FIELD_AS_FORMTYPE) && !readOnly}
                            isClearable={true}/>
                </div>
            </div>
        </StyledDiv>
    )

};

const answerGroupSorted = state => {
    if (state.adminAnsweringSchema && state.adminAnsweringSchema.AnsweringSchemaOptions) {
        return [...state.adminAnsweringSchema.AnsweringSchemaOptions]
            .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [];
}

const mapStateToProps = (state, props) => ({
    answeringSchemaOptions: answerGroupSorted(state),
    selectedSG: formValueSelector(props.form)(state, `${FORM_ANSWERING_SCHEMA_INFO_SECTION}.${FORM_FIELD_AS_SG_NAME}`),
    selectedFormType: formValueSelector(props.form)(state, `${FORM_ANSWERING_SCHEMA_INFO_SECTION}.${FORM_FIELD_AS_FORMTYPE}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AnsweringInfoSection));
