import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';
import App from './App';
import get from 'get-value';
import _ from 'lodash';
import {allowHttpsLogging} from '../utils/winstonLogger';
import Bowser from 'bowser';
import {REDIR_TIME_SESSION_STORAGE} from '../utils/auth';
import {Route, Switch, withRouter} from 'react-router-dom';
import Logout from './Logout';
import HeaderNotification from './HeaderNotification';
const bowserInfo = Bowser.getParser(window.navigator.userAgent);

class PreLoadApp extends Component {
    constructor(props) {
        super(props);
        this.state = {configProcessed: false};
    }

    saveConfigToLocalStorage = (config) => {
        localStorage.setItem('dog.tr.config', JSON.stringify({timestamp: new Date().getTime(), ...config}));
    }

    loadStateFromLocalStorage() {
        let value = {};
        if (localStorage.hasOwnProperty('dog.tr.config')) {
            value = JSON.parse(localStorage.getItem('dog.tr.config'));
            if (get(value, 'timestamp') && (((new Date().getTime() - value.timestamp) / 1000) > 300)) {
                return {};
            }
            return value || {};
        }
        return {};
    }

    processConfigData = (config) => {
        if (get(config, 'logging')) {
            allowHttpsLogging();
        }

        this.setState({configProcessed: true});
    };

    componentDidMount() {
        const config = this.loadStateFromLocalStorage();
        if (_.isEmpty(config)) {
            fetch(`${process.env.REACT_APP_BE_HTTP_URL}/logging/config`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_BE_API_KEY
                }
            })
                .then(e => e.json())
                .then(e => {
                    this.saveConfigToLocalStorage(e);
                    this.processConfigData(e);
                })
                .catch(e => {
                    this.setState({configProcessed: true})
                });
        } else {
            this.processConfigData(config);
        }
    }

    render() {
        const {configProcessed} = this.state;
        const config = this.loadStateFromLocalStorage();
        const maintenanceMessage = get(config, 'maintenanceMessage', '');
        if (sessionStorage.hasOwnProperty(REDIR_TIME_SESSION_STORAGE)) {
            let value = JSON.parse(sessionStorage.getItem(REDIR_TIME_SESSION_STORAGE));
            if (value.timestamp + 60000 >= new Date().getTime() && value.count >= 10) {
                return <div>
                    <h1>Too many OKTA requests!</h1>
                    <p>Please wait a minute and try again. If the problem still persists, contact your support.</p>
                </div>;
            }
        }
        if (bowserInfo && !!(bowserInfo.isBrowser('internet explorer'))) {
            return <div>
                <h1>Internet Explorer is an outdated browser</h1>
                <p>For a safer and faster user experience use a modern browser like Chrome, Firefox, Safari, or
                    Edge.</p>
            </div>;
        }
        if (!configProcessed) {
            return <Loader/>;
        }
        const {store} = this.props;
        return (
            <Switch>
                <Route path='/logout' component={Logout}/>
                <Route render={() => <>
                    {get(config, 'maintenance', false) ?
                        <>
                            <HeaderNotification/>
                            <div style={{width: '840px', margin: '0 auto', marginTop: '65px'}} className="alert alert-light" role="alert">
                                <h1 className="text-dark">
                                    Trace application is under maintenance!
                                </h1>
                                <hr/>
                                <div style={{width: '700px', margin: '0 auto', marginTop: '15px', fontSize: '22px', whiteSpace: 'pre-wrap'}}>
                                    {maintenanceMessage}
                                </div>
                            </div>
                        </> :
                        <App store={store}/>
                    }
                </>}/>
            </Switch>
        );
    }
}

PreLoadApp.propTypes = {
    store: PropTypes.any.isRequired,
};

export default withRouter(PreLoadApp);
