import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';
import get from 'get-value';
import {clearAlerts} from '../../../actions/alertsActions';
import Loader from '../../Loader';
import set from 'set-value';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import {
    initNewAseInternalTicket,
    transformNewInternalTicketForSave,
} from '../Utils';
import {createRights} from '../../../constants/aseInternalTicketRights';
import {createReqFields} from '../../../constants/aseInternalTicketRequiredFields';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import PageHeader from '../../common/PageHeader';
import PropTypes from 'prop-types';
import checkRoles from '../../common/roleChecker/RoleChecker';
import InternalTicketMasterForm from './forms/InternalTicketMasterForm';
import {
    fetchAseInternalTicketSgInfo,
    resetAseInternalTicket, resetAseInternalTicketSgInfo,
    saveAseInternalTicket
} from '../../../actions/ase/actionAseInternalTicket';
import UploadingAseInternalTicketAttachments from './UploadingAseInternalTicketAttachments';
import {scrollContentWrapperToTop} from '../../../utils/utils';
import {injectIntl} from 'react-intl';
import {ASE_INTERNAL_TICKET_FORM} from '../../../constants/formConstants';

class NewAseInternalTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentDidMount() {
        const {userDetail} = this.props;
        this.props.clearAlerts();
        this.props.resetAseInternalTicket();
        this.props.resetAseInternalTicketSgInfo();
        if (get(userDetail, 'roles.ASE.sgEditor.sgPermission', []).length === 1) {
            this.props.fetchAseInternalTicketSgInfo(get(userDetail, 'roles.ASE.sgEditor.sgPermission.0.id', ''));
        }
        scrollContentWrapperToTop();
    }


    handleSubmit = (values) => {
        const {submitBy} = values;
        const editorSolutionGroups = get(this.props.userDetail, 'roles.ASE.sgEditor.sgPermission');
        const dataForSend = transformNewInternalTicketForSave(values.newValues, editorSolutionGroups);
        this.props.saveAseInternalTicket(dataForSend);
        this.setState({ticketSubmittedAndShouldRedirect: true});
    };

    render() {
        const {
            formType,
            ticketData,
            attachmentsData,
            group,
        } = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAseInternalTicketAttachments attachments={attachmentsData.attachmentsForUpload}
                                                attachmentsUrl={ticketData.newAttachments}
                                                correlationId={ticketData.correlationId}
                                                isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                                formType={formType}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/ase/internalTicket/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (!formType
            || this.state.ticketSubmittedAndShouldRedirect
            || ticketData.correlationId
        ) {
            return <Loader/>;
        }

        const initializationValue = initNewAseInternalTicket(this.props.userDetail, this.props.location.initData);
        const requiredFields = createReqFields[createReqFields[group] ? group : 'DEFAULT'][formType];

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={<ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>}/>
                <div>
                    {formType === ASE_INTERNAL_TICKET_FORM &&
                    <InternalTicketMasterForm form={formType}
                                                    initialValues={set(initializationValue, 'ticketSection.formType', ASE_INTERNAL_TICKET_FORM)}
                                                    userRights={createRights}
                                                    reqFields={requiredFields}
                                                    enableReinitialize={true}
                                                    handleSubmit={this.handleSubmit}/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    userDetail: state.profile.userDetail,
    group: state.profile.userDetail.group,
    ticketData: state.aseInternalTicket,
    attachmentsData: state.aseInternalTicketAttachment,
});

NewAseInternalTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
    saveAseInternalTicket: PropTypes.func.isRequired,
    fetchAseInternalTicketSgInfo: PropTypes.func.isRequired,
    ticketData: PropTypes.object.isRequired,
    attachmentsData: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    saveAseInternalTicket,
    resetAseInternalTicket,
    resetAseInternalTicketSgInfo,
    fetchAseInternalTicketSgInfo,
})(injectIntl(NewAseInternalTicketPage))), ["AS_SG_EDITOR"]);

