import {domains} from '../../constants/Utils';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';



export const fetchEscalationSchemaSGOptions = (domain) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: aseWebsocketCommands.ASE_ADMIN_SOLUTION_GROUPS_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_ADMIN_SOLUTION_GROUPS_OPTIONS,
                payload: {}
            });
        }
    }
};
