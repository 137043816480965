import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    BVM_VIN_WARN001: {
        id: 'warnings.bvm.dataNotFound',
        defaultMessage: 'No data found in BVM for the VIN {vinNumber}'
    },
});

export default messages;
