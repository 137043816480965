import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import 'react-picky/dist/picky.css';
import {Link, withRouter} from 'react-router-dom';
import checkRoles from '../../common/roleChecker/RoleChecker';
import PageHeader from '../../common/PageHeader';
import get from 'get-value';
import {formatTicketNumber, mapFiltersForFulltext} from '../../../utils/utils';
import commonMessages from '../../../intl/common/commonMessages';
import {
    priorityTreatment,
} from '../../../constants/Utils';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import {StyledPicky} from '../../common/StyledComponents';
import Loader from '../../Loader';
import {lockAseInternalTicket} from '../../../actions/ase/actionAseInternalLockTicket';
import {updateAseUserFulltextSearchInternalPreference} from '../../../actions/ase/actionAsePreferences';
import InternalTicketSearchAseTable from './InternalTicketSearchAseTable';
import InternalTicketSearchAseForm from './InternalTicketSearchAseForm';
import issueOriginMessages from '../../../intl/afterSales/issueOriginMessages';
import priorityTreatmentMessages from '../../../intl/afterSales/priorityTreatmentMessages';
import internalFulltextSearchMessages from '../../../intl/afterSales/internalFulltextSearchMessages';

const SESSION_STORAGE_KEY = 'fullTextInternalTicket';

class InternalTicketSearchAsePage extends Component {
    constructor(props) {
        super(props);
        const {intl} = props;
        const storageData = this.loadStateFromSessionStorage();
        this.state = {
            leavingPage: false,
            columnLibrary: [
                {
                    id: 'created',
                    accessor: 'created',
                    message: internalFulltextSearchMessages.TABLE_IT_CREATION_TIME,
                    filterable: false,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'issueOrigin',
                    accessor: 'issueOrigin',
                    message: internalFulltextSearchMessages.TABLE_IT_ISSUE_ORIGIN, //tady
                    filterable: false,
                    Cell: e =>
                        <div>{issueOriginMessages[e.value] ? intl.formatMessage(issueOriginMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'priorityTreatment',
                    accessor: 'priorityTreatment',
                    message: internalFulltextSearchMessages.TABLE_IT_PRIORITY,
                    filterable: false,
                    Cell: e =>
                        <div>{priorityTreatmentMessages[e.value] ? intl.formatMessage(priorityTreatmentMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'creatorIpn',
                    accessor: 'creatorIpn',
                    message: internalFulltextSearchMessages.TABLE_IT_CREATED_BY_IPN,
                    filterable: false,
                    Cell: e => <div>{e.value}</div>,
                },
                {
                    id: 'status',
                    accessor: 'status',
                    message: internalFulltextSearchMessages.TABLE_REQUEST_STATUS,
                    filterable: false,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'dealerTicketNumber',
                    accessor: 'ticketReference',
                    message: internalFulltextSearchMessages.TABLE_DEALER_TICKET_NUMBER,
                    filterable: false,
                    Cell: row => <Link to={{
                        pathname: `/ase/view/${get(row, 'value.uuid')}`,
                        redirectedFromInternal: true
                    }}>{get(row, 'value.pfxNumber') ? formatTicketNumber(get(row, 'value.pfxNumber'), get(row, 'value.sfxNumber')) : ''}</Link>
                },
                {
                    id: 'sgRequester',
                    accessor: 'solutionGroup',
                    message: internalFulltextSearchMessages.TABLE_SG_REQUESTOR,
                    filterable: false,
                    Cell: e =>
                        <div>{get(e, 'value.name', '')}</div>
                },
                {
                    id: 'assignedSg',
                    accessor: 'assignedSolutionGroup',
                    message: internalFulltextSearchMessages.TABLE_ASSIGNED_SOLUTION_GROUP,
                    filterable: false,
                    Cell: e =>
                        <div>{get(e, 'value.name', '')}</div>
                },
                {
                    id: 'lupQsAssociated',
                    accessor: 'lupQsAssociated',
                    message: internalFulltextSearchMessages.TABLE_IT_LUP_QS_ASSOCIATED,
                    filterable: false,
                },
                {
                    id: 'docUpdateNeeded',
                    accessor: 'docUpdateNeeded',
                    message: internalFulltextSearchMessages.TABLE_IT_DOCUMENTATION_UPDATE,
                    filterable: false,
                    Cell: e =>
                        <div>{commonMessages[e.value] ? intl.formatMessage(commonMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'lastUpdate',
                    accessor: 'lastUpdate',
                    message: internalFulltextSearchMessages.TABLE_IT_LAST_STATUS_DATE,
                    filterable: false,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'lastEditor',
                    accessor: 'lastEditor',
                    message: internalFulltextSearchMessages.TABLE_IT_LAST_EDITOR,
                    filterable: false,
                }
            ]
,
            listDefaultFilters: get(storageData, 'filters', []),
            formInitValues: get(storageData, 'formValues', {}),
            searchOption: {},
        };
    }

    resetForm = () => {
        this.setState({listDefaultFilters: [], formInitValues: {}});
        this.saveStateToSessionStorage({}, [[], {}]);
    }

    saveStateToSessionStorage(formValues = {}, filters = []) {
        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify({formValues, filters}));
    }

    loadStateFromSessionStorage() {
        if (sessionStorage.hasOwnProperty(SESSION_STORAGE_KEY)) {
            return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY));
        }
        return {};
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockAseInternalTicket(id);
            this.setState({leavingPage: true});
        }
    };

    selectMultipleOption = (value) => {
        const ipn = this.props.loggedUser.ipn;
        const preferences = this.state.columnLibrary
            .filter(item => !value.some(value => value.id === item.id))
            .map(column => column.id);

        this.props.updateAseUserFulltextSearchInternalPreference(ipn, preferences);
    };

    handleSubmit = (values) => {
        const filter = mapFiltersForFulltext(values);
        this.setState({listDefaultFilters: [filter, values.searchOption || {}]});
        this.saveStateToSessionStorage(values, [filter, values.searchOption || {}]);
    };


    render() {
        const {intl, asePreferences, lockTicket} = this.props;
        if (lockTicket.redirect && this.state.leavingPage) {
            this.props.history.push(`/ase/internalTicket/edit/${lockTicket.uuid}`)
        }

        if (asePreferences.isLoading) {
            return <Loader/>;
        }

        const hiddenColumns = get(asePreferences, 'menuFullTextSearchInternal', {default: []});
        const columns = this.state.columnLibrary.filter(item => !hiddenColumns.includes(item.id));
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...internalFulltextSearchMessages.TITLE}/>}/>
                <InternalTicketSearchAseForm
                    initialValues={this.state.formInitValues}
                    onSubmit={this.handleSubmit}
                    resetForm={this.resetForm}
                    enableReinitialize={true}
                    form={'internalTicketSearchForm'}
                />
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold">
                    <FormattedMessage {...internalFulltextSearchMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columns.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={this.state.columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <InternalTicketSearchAseTable
                    columns={columns}
                    listDefaultFilters={this.state.listDefaultFilters}
                    handleEditClick={this.handleEditClick}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    asePreferences: state.asePreferences,
    lockTicket: state.aseInternalTicketListOrderLockTicketCheck,
    roles: state.profile.userDetail.roles,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    updateAseUserFulltextSearchInternalPreference,
    lockAseInternalTicket,
})(injectIntl(InternalTicketSearchAsePage))), ['AS_SG_EDITOR']);

