import {domains} from '../../constants/Utils';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const fetchSolutionGroupListPageData = (domain, pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            dispatch({
                type: aseWebsocketCommands.ASE_SOLUTION_GROUPS_LIST_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_SOLUTION_GROUPS_LIST,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: filtered}
            });
            break;
        }
    }
};
