
// ------------- after sales -------------
// route (commands)
export const ASE_CREATE_ATTACHMENTS = 'createAttachments';
export const ASE_UPDATE_TICKET = 'updateAseTicket';
export const ASE_CREATE_TICKET = 'createAseTicket';
export const ASE_DOWNLOAD_PRESIGNED_URL = 'attachmentDownloadPreSignedUrl';
export const ASE_SET_LOCK = 'setLockTicket';
export const ASE_SET_UNLOCK = 'setUnlockTicket';
export const ASE_GET_TICKET = 'getTicket';
export const ASE_GET_STATISTICS = 'getStatistics';
// websocket receive (commands)
export const ASE_TICKET_SEND = 'ASE_TICKET_SEND';
export const ASE_TICKET_CREATED = 'ASE_TICKET_CREATED';
export const ASE_TICKET_UPDATED = 'ASE_TICKET_UPDATED';
export const ASE_TICKET_LOCKED = 'ASE_TICKET_LOCKED';
export const ASE_TICKET_LOCKED_ERROR = 'ASE_TICKET_LOCKED_ERROR';
export const ASE_TICKET_LOCKED_FATAL = 'ASE_TICKET_LOCKED_FATAL';
export const ASE_GENERATED_PRESIGNED_URLS = 'ASE_GENERATED_PRESIGNED_URLS';
export const ASE_TICKET_ATTACHMENTS_SAVED = 'ASE_TICKET_ATTACHMENTS_SAVED';
export const ASE_ATTACHMENTS_SAVED_ERROR = 'ASE_ATTACHMENTS_SAVED_ERROR';
export const ASE_ATTACHMENTS_DOWNLOAD = 'ASE_TICKET_ATTACHMENTS_DOWNLOAD';
export const ASE_STATISTICS_SEND = 'ASE_STATISTICS_SEND';
export const ASE_STATISTICS_SEND_ERROR = 'ASE_STATISTICS_SEND_ERROR';

// route (commands)
export const ASE_INTERNAL_CREATE_ATTACHMENTS = 'createInternalAttachments';
export const ASE_INTERNAL_UPDATE_TICKET = 'updateInternalTicket';
export const ASE_INTERNAL_CREATE_TICKET = 'createInternalTicket';
export const ASE_INTERNAL_DOWNLOAD_PRESIGNED_URL = 'internalAttachmentDownloadPreSignedUrl';
export const ASE_INTERNAL_SET_LOCK = 'setLockInternalTicket';
export const ASE_INTERNAL_SET_UNLOCK = 'setUnlockInternalTicket';
export const ASE_INTERNAL_GET_TICKET = 'getInternalTicket';
export const ASE_INTERNAL_GET_SG_INFO = 'getSolutionGroupInfo';

// websocket receive (commands)
export const ASE_INTERNAL_TICKET_SEND = 'ASE_INTERNAL_TICKET_SEND';
export const ASE_INTERNAL_TICKET_SG_INFO_SEND = 'ASE_INTERNAL_TICKET_SG_INFO_SEND';
export const ASE_INTERNAL_TICKET_SG_INFO_ERROR = 'ASE_INTERNAL_TICKET_SG_INFO_ERROR';
export const ASE_INTERNAL_TICKET_CREATED = 'ASE_INTERNAL_TICKET_CREATED';
export const ASE_INTERNAL_TICKET_UPDATED = 'ASE_INTERNAL_TICKET_UPDATED';
export const ASE_INTERNAL_TICKET_LOCKED = 'ASE_INTERNAL_TICKET_LOCKED';
export const ASE_INTERNAL_TICKET_LOCKED_ERROR = 'ASE_INTERNAL_TICKET_LOCKED_ERROR';
export const ASE_INTERNAL_TICKET_LOCKED_FATAL = 'ASE_INTERNAL_TICKET_LOCKED_FATAL';
export const ASE_INTERNAL_GENERATED_PRESIGNED_URLS = 'ASE_INTERNAL_GENERATED_PRESIGNED_URLS';
export const ASE_INTERNAL_TICKET_ATTACHMENTS_SAVED = 'ASE_INTERNAL_TICKET_ATTACHMENTS_SAVED';
export const ASE_INTERNAL_TICKET_ATTACHMENTS_SAVED_ERROR = 'ASE_INTERNAL_TICKET_ATTACHMENTS_SAVED_ERROR';
export const ASE_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD = 'ASE_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD';
