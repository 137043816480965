import {
    ADMIN_WORKING_HOURS_SAVE_PENDING,
    ADMIN_WORKING_HOURS_FETCH_PENDING,
    ADMIN_WORKING_HOURS_RESET,
    ADMIN_WORKING_HOURS_SELECT_GROUP,
    ADMIN_WORKING_HOURS_UPDATE,
} from '../../actions/admin/actionAdminWorkingHours';
import {cloneDeep} from 'lodash';
import get from 'get-value';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

const defaultData = {
    workingHours: {
        days: {
            1: {
                from: 0,
                to: 0,
                diff: 0
            },
            2: {
                from: 0,
                to: 0,
                diff: 0
            },
            3: {
                from: 0,
                to: 0,
                diff: 0
            },
            4: {
                from: 0,
                to: 0,
                diff: 0
            },
            5: {
                from: 0,
                to: 0,
                diff: 0
            },
            6: {
                from: 0,
                to: 0,
                diff: 0
            },
            7: {
                from: 0,
                to: 0,
                diff: 0
            }
        }
    },
    bankHolidays: []
};

export const adminWorkingHours = (state = {
        isLoading: false,
        isDirty: false,
        data: defaultData,
        selectedCountryGroup: '',
    }, action) => {
        switch (action.type) {
            case ADMIN_WORKING_HOURS_FETCH_PENDING:
                return Object.assign({}, state, {isLoading: true, data: defaultData});
            case ADMIN_WORKING_HOURS_SAVE_PENDING: {
                return Object.assign({}, state, {isLoading: true});
            }
            case ADMIN_WORKING_HOURS_RESET: {
                return Object.assign({}, state, {isDirty: false, data: cloneDeep(state.originalData)});
            }
            case ADMIN_WORKING_HOURS_SELECT_GROUP: {
                return Object.assign({}, state, {selectedCountryGroup: action.payload});
            }
            case aseWebsocketCommands.ASE_WORKING_HOURS_SEND: {
                const {correlationId} = action.payload;
                if (correlationId < state.correlationId) {
                    return state;
                }
                if (!get(action, 'payload.workingHours', {default: false})) {
                    return Object.assign({}, state, {
                        isLoading: false,
                        isDirty: false,
                        originalData: defaultData,
                        data: defaultData,
                        correlationId
                    });
                }
                const {workingHours = {}, bankHolidays = []} = action.payload;
                const {days = {}} = workingHours;
                const convertedDays = Object.keys(days).map(day => {
                    const {from, to, diff} = days[day];
                    return {
                        day,
                        from: !from ? 0 : from,
                        to: !to ? 0 : to,
                        diff: !diff ? 0 : diff,
                    }
                }).reduce((acc, curr) => ({
                    ...acc,
                    [curr.day]: {
                        from: curr.from,
                        to: curr.to,
                        diff: curr.diff,
                    },
                }), {});
                const data = {
                    workingHours: {
                        ...workingHours,
                        days: convertedDays,
                    },
                    bankHolidays,
                };
                return Object.assign({}, state, {
                    isLoading: false,
                    isDirty: false,
                    originalData: data,
                    data,
                    correlationId
                });
            }
            case aseWebsocketCommands.ASE_WORKING_HOURS_ERROR: {
                const {correlationId} = action.payload;
                if (correlationId < state.correlationId) {
                    return state;
                }
                return Object.assign({}, state, {isLoading: false, data: cloneDeep(state.originalData), correlationId});
            }
            case aseWebsocketCommands.ASE_WORKING_HOURS_SEND_ERROR: {
                const {correlationId} = action.payload;
                if (correlationId < state.correlationId) {
                    return state;
                }
                return Object.assign({}, state, {
                    isLoading: false,
                    data: defaultData,
                    originalData: defaultData,
                    correlationId
                });
            }
            case ADMIN_WORKING_HOURS_UPDATE: {
                const {day, values} = action.payload;
                const days = state.data.workingHours.days;
                const diff = values[1] - values[0];
                const changedObject = {
                    from: values[0],
                    to: values[1],
                    diff: diff,
                };
                const newState = {
                    workingHours: {
                        days: Object.assign({}, days, {[day]: changedObject}),
                    },
                    bankHolidays: state.data.bankHolidays,
                };

                return Object.assign({}, state, {data: newState}, {isDirty: true});
            }
            default:
                return state;
        }
    }
;

