import React, {Component} from 'react';
import PageHeader from '../../common/PageHeader';
import HelpLang from './HelpLang';
import {FILE, VIDEO, YOUTUBE_VIDEO} from './HelpConst';
import HelpModal from './HelpModal';
import {connect} from 'react-redux';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {domains} from '../../../constants/Utils';
import FileIconWithName from '../common/FileIconWithName';
import { downloadFile } from "../../../actions/actionFile";

class HelpPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: this.props.language === 'fr' ? 'fr' : 'en',
            modalIsOpened: false,
            selectedVideo: null,
        }
    }

    changeLanguage = (newLang) => {
        if (newLang !== this.state.lang) {
            this.setState({lang: newLang});
        }
    }

    openLink = (e, link) => {
        if (link.type === VIDEO || link.type === YOUTUBE_VIDEO) {
            e.preventDefault();
            this.switchModal();
            this.selectVideo(link);
        }
    }

    selectVideo = (link) => this.setState({selectedVideo: link})

    switchModal = () => this.setState((prevState) => ({modalIsOpened: !prevState.modalIsOpened}))

    render() {
        const titleHeader = this.state.lang === 'fr'
            ? 'Bienvenue sur l’aide en ligne de Dialog: Trace'
            : 'Welcome to Dialog: Trace on-line help';
        const GlossaryTitle = 'Solution groups glossary'

        return (
            <>
                <div className="container-fluid">
                    <HelpLang lang={this.state.lang} changeLanguage={this.changeLanguage}/>
                    <PageHeader title={titleHeader}/>
                    <h3>{GlossaryTitle}</h3>
                    <ul style={{listStyleType: 'none'}}>
                        <li>
                            <FileIconWithName
                                file={{name: "SG-glossary-03-23.pdf", type: "application/pdf"}}
                                showIcon
                                showName
                                onClick={() => this.props.downloadFile(domains.ADMINISTRATION, {uuid: "sg-glossary-03-23.pdf", name: "sg-glossary-03-23"})}
                            />
                        </li>
                    </ul>
                </div>
                <HelpModal isOpen={this.state.modalIsOpened}
                           switchModal={this.switchModal}
                           video={this.state.selectedVideo}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    language: state.intl.language,
});

export default checkRoles(connect(mapStateToProps, { downloadFile })(HelpPage), ['AS_POWER_USER', 'AS_SG_ADMIN', 'AS_EDITOR', 'AS_SG_EDITOR']);
