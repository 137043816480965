import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const adminSolutionGroup = (state = {
    correlationId: null,
    isSolutionGroupLoading: false,
    isSolutionGroupCreating: false,
    solutionGroupSubmittedAndShouldRedirect: false,
    initWithOldData: false,
    created: false,
    solutionGroup: {},
    availableName: true,
}, action) => {
    switch (action.type) {
        case aseWebsocketCommands.ASE_SOLUTION_GROUP_REDIRECT_RESET: {
            return Object.assign({}, state, {
                solutionGroupSubmittedAndShouldRedirect: false,
            });
        }
        case aseWebsocketCommands.ASE_SOLUTION_GROUP_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isSolutionGroupLoading: false,
                isSolutionGroupCreating: false,
                solutionGroupSubmittedAndShouldRedirect: false,
                initWithOldData: false,
                created: false,
                solutionGroup: {},
                availableName: true
            });
        }
        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_UPDATE_PENDING:
        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_CREATE_PENDING: {
            return Object.assign({}, state, {
                isSolutionGroupCreating: true,
                isSolutionGroupLoading: true,
                solutionGroupSubmittedAndShouldRedirect: true,
            });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_ACCEPTED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isSolutionGroupCreating: false,
                created: true,
                isSolutionGroupLoading: true,
                correlationId
            });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_SEND: {
            const { correlationId, solutionGroup, error } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isSolutionGroupLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isSolutionGroupLoading: false,
                correlationId,
                solutionGroup
            });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isSolutionGroupCreating: false,
                isSolutionGroupLoading: false,
                correlationId,
            });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isSolutionGroupCreating: false,
                created: false,
                isSolutionGroupLoading: false,
                solutionGroupSubmittedAndShouldRedirect: false,
                initWithOldData: true,
                correlationId,
            });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUPS_DETAIL_FETCH_PENDING: {
            return Object.assign({}, state, { isSolutionGroupLoading: true });
        }

        case aseWebsocketCommands.ASE_SOLUTION_GROUP_SAVE_NEW_ERROR:
        case aseWebsocketCommands.ASE_SOLUTION_GROUP_SAVE_UPDATE_ERROR: {
            return Object.assign({}, state, { isSolutionGroupLoading: false });
        }

        default:
            return state;
    }
};
