import axios from 'axios';

export const uploadAseAttachment = (url, fileUrl, name, contentType) => {
    const config = {
        headers: {'Content-Type': contentType}
    };
    //First download blob from the local url and then pass it to the axios
    return fetch(fileUrl).then(r => r.blob()).then(response => {
        return axios.create().put(url, response, config);
    });
};
