import get from 'get-value';
import {
    MAIN_MESSAGE_FETCH_PENDING,
    MAIN_MESSAGE_SAVE_PENDING,
    MAIN_MESSAGES_SELECT_GROUP,
    ASE_MAIN_MESSAGES_FETCH_PENDING,
} from '../../actions/admin/actionAdminMainMessage';
import {domains} from '../../constants/Utils';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const adminMainMessage = (state = {
    isLoading: false,
    isLoadingAse: false,
    mainMessage: {},
    mainMessages: {},
    selectedCountryGroup: ''
}, action) => {
    switch (action.type) {
        case MAIN_MESSAGES_SELECT_GROUP: {
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        }
        case MAIN_MESSAGE_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true, mainMessage: {}});
        }
        case MAIN_MESSAGE_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true, mainMessage: {}});
        }
        case aseWebsocketCommands.ASE_MAIN_MESSAGES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                mainMessage: get(action, 'payload.mainMessage', {default: {}}),
                correlationId
            });
        }
        case aseWebsocketCommands.ASE_MAIN_MESSAGES_ERROR:
        case aseWebsocketCommands.ASE_MAIN_MESSAGES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, mainMessage: {}, correlationId});
        }

        case ASE_MAIN_MESSAGES_FETCH_PENDING: {
            return Object.assign({}, state, {isLoadingAse: true});
        }
        case aseWebsocketCommands.ASE_GROUPS_MAIN_MESSAGES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.asCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoadingAse: false,
                mainMessages: {
                    ...state.mainMessages,
                    [domains.ASE]: get(action, 'payload.mainMessages', {default: []})
                },
                asCorrelationId: correlationId
            });
        }
        case aseWebsocketCommands.ASE_GROUPS_MAIN_MESSAGES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.asCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoadingAse: false,
                mainMessages: {
                    ...state.mainMessages,
                    [domains.ASE]: []
                },
                asCorrelationId: correlationId
            });
        }
        default: {
            return state;
        }
    }
};
