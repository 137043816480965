//ASE export
export const ASE_EXPORT_FORM_FIELD_PFX_NUMBER = 'pfxNumber';
export const ASE_EXPORT_FORM_FIELD_SFX_NUMBER = 'sfxNumber';
export const ASE_EXPORT_FORM_FIELD_FROM = 'from';
export const ASE_EXPORT_FORM_FIELD_TO = 'to';
export const ASE_EXPORT_FORM_FIELD_ASSIGNED = 'assigned';
export const ASE_EXPORT_FORM_FIELD_STATUS = 'status';
export const ASE_EXPORT_FORM_FIELD_FORM_TYPE = 'formType';
export const ASE_EXPORT_FORM_FIELD_VEHICLE_FAMILY = 'vehicleFamily';
export const ASE_EXPORT_FORM_FIELD_DEALER_NUMBER = 'dealerNumber';
export const ASE_EXPORT_FORM_FIELD_DEALER_NAME = 'dealerName';
export const ASE_EXPORT_FORM_FIELD_TYPE = 'type';
export const ASE_EXPORT_FORM_FIELD_CHARSET = 'charset';
export const ASE_EXPORT_FORM_FIELD_SOLUTION_GROUP = 'solutionGroup';

export const ASE_EXPORT_FORM_LABEL_TICKET_NUMBER = 'TICKET_NUMBER';
export const ASE_EXPORT_FORM_LABEL_FROM = 'DATE_FROM';
export const ASE_EXPORT_FORM_LABEL_TO = 'DATE_TO';
export const ASE_EXPORT_FORM_LABEL_ASSIGNED = 'ASSIGNED';
export const ASE_EXPORT_FORM_LABEL_STATUS = 'STATUS';
export const ASE_EXPORT_FORM_LABEL_FORM_TYPE = 'FORMTYPE';
export const ASE_EXPORT_FORM_LABEL_VEHICLE_FAMILY = 'VEHICLE_FAMILY';
export const ASE_EXPORT_FORM_LABEL_DEALER_NUMBER = 'DEALER_NUMBER';
export const ASE_EXPORT_FORM_LABEL_DEALER_NAME = 'DEALER_NAME';
export const ASE_EXPORT_FORM_LABEL_TYPE = 'TYPE';
export const ASE_EXPORT_FORM_LABEL_CHARSET = 'CHARSET';
export const ASE_EXPORT_FORM_LABEL_SOLUTION_GROUP = 'SOLUTION_GROUP';

//ASE internal export
export const ASE_EXPORT_INTERNAL_FORM_FIELD_FROM = 'from';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_TO = 'to';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_STATUS = 'status';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_IS_ASSIGNED_TO = 'isAssignedTo';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_HAS_BEEN_ASSIGNED_TO = 'hasBeenAssignedTo';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_REQUEST_TYPE = 'requestType';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_PARTNER = 'partner';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_VEHICLE_FAMILY = 'vehicleFamily';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_ENGINE_TYPE = 'engineType';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_GEARBOX_TYPE = 'gearboxType';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_NOD_ID = 'nodId';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_DOCUMENT_ID = 'documentId';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_ROOT_CAUSE = 'rootCause';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_DOCUMENTATION_UPDATE_NEEDED = 'docUpdateNeeded';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_TYPE = 'type';
export const ASE_EXPORT_INTERNAL_FORM_FIELD_CHARSET = 'charset';

export const ASE_EXPORT_INTERNAL_FORM_LABEL_FROM = 'DATE_FROM';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_TO = 'DATE_TO';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_STATUS = 'STATUS';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_IS_ASSIGNED_TO = 'IS_ASSIGNED_TO';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_HAS_BEEN_ASSIGNED_TO = 'HAS_BEEN_ASSIGNED_TO';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_REQUEST_TYPE = 'REQUEST_TYPE';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_PARTNER = 'PARTNER';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_VEHICLE_FAMILY = 'VEHICLE_FAMILY';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_ENGINE_TYPE = 'ENGINE_TYPE';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_GEARBOX_TYPE = 'GEARBOX_TYPE';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_NOD_ID = 'NOD_ID';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_DOCUMENT_ID = 'DOCUMENT_ID';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_ROOT_CAUSE = 'ROOT_CAUSE';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_DOCUMENTATION_UPDATE_NEEDED = 'DOCUMENTATION_UPDATE_NEEDED';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_TYPE = 'TYPE';
export const ASE_EXPORT_INTERNAL_FORM_LABEL_CHARSET = 'CHARSET';

//export
export const XLS = 'XLS';
export const CSV = 'CSV';

//charset
export const WINDOWS_1252 = 'WINDOWS_1252';
export const ISO8859_2 = 'ISO8859_2';
export const UTF_8_BOM = 'UTF_8_BOM';
export const UTF_8 = 'UTF_8';

export const ticketExportTypes = [
    XLS,
    CSV,
];

export const ticketCharsetTypes = [
    WINDOWS_1252,
    ISO8859_2,
    UTF_8,
    UTF_8_BOM,
];
