import {defineMessages} from 'react-intl';

//TODO
export const messages = defineMessages({
    TEST: {
        id: 'ase.textBox.option.test.text',
        defaultMessage: 'Hello,\n' +
            'We have forwarded your request for opening this part for sale. We will let you know the reply as soon as possible.\n' +
            'In case of problems once the opening for sale has been confirmed, please contact your corresondant in Cergy.\n' +
            'Thank you.\n' +
            'Best regards.'
    }
});

export default messages;
