import get from 'get-value';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const aseFullTextSearchPage = (state = {
    correlationId: null,
    isLoading: false,
    isTableLoading: false,
    fullTextData: [],
    pages: 0,
    isNextFromPivot: true,
    isNextPage: false,
    isLoadingLastEditors: false,
    lastEditors: []
}, action) => {
    switch (action.type) {
        case aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_GET: {
            return Object.assign({}, state, {isTableLoading: true, fullTextData: []});
        }
        case aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    fullTextData: get(action, 'payload.result.data', {default: []}),
                    pages: get(action, 'payload.result.pages', {default: 0}),
                    isNextFromPivot: get(action, 'payload.result.isNextFromPivot', false),
                    isNextPage: get(action, 'payload.result.isNextPage', false),
                    correlationId
                });
            }
        }
        case aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {isTableLoading: false, fullTextData: [], correlationId});
            }
        }
        case aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_LAST_EDITORS_GET: {
            return Object.assign({}, state, {isLoadingLastEditors: true, lastEditors: []});
        }
        case aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_LAST_EDITORS_SEND: {
            return Object.assign({}, state, {
                isLoadingLastEditors: false,
                lastEditors: get(action, 'payload.editors', {default: []}),
            });
        }
        case aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_LAST_EDITORS_SEND_ERROR: {
            return Object.assign({}, state, {isLoadingLastEditors: false, lastEditors: []});
        }
        default:
            return state;
    }
};
