import React, {Component} from 'react';
import {
    change,
    clearFields,
    Field,
    formValueSelector,
    getFormInitialValues,
} from 'redux-form'
import {FormattedMessage} from "react-intl";
import {connect} from 'react-redux';
import {
    CHECKBOX_LABEL_BVM_CONNECTION,
    FORM_CHECKBOX_BVM_CONNECTION,
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_FIELD_MANUFACTURE_DATE,
    FORM_FIELD_REGISTRATION_NO,
    FORM_FIELD_CAR_MODEL,
    FORM_FIELD_VIN,
    FORM_LABEL_MANUFACTURE_DATE,
    FORM_LABEL_REGISTRATION_NO,
    FORM_LABEL_VEHICLE_MODEL,
    FORM_LABEL_VIN,
    FORM_VEHICLE_DETAIL_SECTION,
    FORM_LABEL_MORE_DETAILS,
    FORM_FIELD_MORE_DETAILS,
    FORM_LABEL_ENGINE_TYPE,
    FORM_LABEL_ENGINE_SERIAL_NUMBER,
    FORM_LABEL_GEARBOX_TYPE,
    FORM_LABEL_GEARBOX_SERIAL_NUMBER,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from "../../../../../common/InputFieldWithValidation";
import buttonMessages from "../../../../../../intl/common/buttonMessages";
import {PrimaryButton} from "../../../../../common/Button";
import DatePickerComponent from "../../../../../common/datePicker/DatePickerComponent";
import Loader from "../../../../../Loader";
import get from 'get-value';
import {moduleRoles} from "../../../../../../utils/roles";
import {required, validVinLength} from "../../../../../common/validationCommons";
import formMessages from "../../../../../../intl/afterSales/formMessages";
import ValidFormattedMessage from "../../../../../common/ValidFormattedMessage";
import {bindActionCreators} from "redux";
import {
    bvmDataResetActionCreator,
    bvmDataSetActionCreator,
    fetchBvmInformationActionCreator
} from "../../../../../../actions/orders/bvmData";
import * as moment from "moment";
import ConfirmationDialog from "../../../../../common/modal/ConfirmationDialog";
import BorderRadius from '../../../../../common/styled/BorderRadius';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';

class VehicleDetailSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenManualRewriteDialog: false,
            isOpenBvmRewriteDialog: false
        }
    }

    componentWillUnmount() {
        this.props.bvmDataResetActionCreator();
    }

    handleBvmLoad = (e) => {
        const {vin} = this.props;
        e.preventDefault();
        this.props.fetchBvmInformationActionCreator(vin);
    };

    closeManualRewriteModal = () => {
        this.setState({isOpenManualRewriteDialog: false,});
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`, false);
    };

    closeBvmRewriteModal = () => {
        this.setState({isOpenBvmRewriteDialog: false,});
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`, true);
    };

    saveManualRewriteModal = () => {
        this.setState({isOpenManualRewriteDialog: false,});
        this.props.clearFields(FORM_FIELD_MANUFACTURE_DATE,
            FORM_FIELD_CAR_MODEL,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
        );
        this.props.bvmDataResetActionCreator();
    };

    saveBvmRewriteModal = () => {
        this.setState({isOpenBvmRewriteDialog: false,});
        this.props.bvmDataResetActionCreator();
    };

    clearBvmFields = (e) => {
        const {bvmLoaded} = this.props;
        if (e.target.checked) {
            this.setState({isOpenManualRewriteDialog: true});
        } else if (!e.target.checked && bvmLoaded) {
            this.setState({isOpenBvmRewriteDialog: true});
        }
    };

    fillVehicleFromBvm = () => {
        const {bvm} = this.props;
        this.props.changeVehDetail(FORM_FIELD_MANUFACTURE_DATE, get(bvm, 'bvmData.manufacturingDate', '') ? moment(bvm.bvmData.manufacturingDate).format("YYYY-MM-DD") : '');
        this.props.changeVehDetail(FORM_FIELD_CAR_MODEL, get(bvm, 'bvmData.model', ''));
        this.props.changeVehDetail(FORM_FIELD_ENGINE_TYPE, get(bvm, 'bvmData.engineType', ''));
        this.props.changeVehDetail(FORM_FIELD_ENGINE_SERIAL_NUMBER, get(bvm, 'bvmData.engineNumber', ''));
        this.props.changeVehDetail(FORM_FIELD_GEARBOX_TYPE, get(bvm, 'bvmData.gearboxType', ''));
        this.props.changeVehDetail(FORM_FIELD_GEARBOX_SERIAL_NUMBER, get(bvm, 'bvmData.gearboxNumber', ''));

        this.props.bvmDataSetActionCreator();
    };


    render() {
        const {bvmConnection, readOnly, bvm, userRights, vehicleDetailsRawData, roles, initialValues, reqFields, bvmLoaded, form} = this.props;
        if (bvm.bvmSet) {
            this.fillVehicleFromBvm();
        }

        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.VEHICLE_DETAIL_SECTION}/>
                </h3>
                <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
                    <div className="form-row pb-2">
                        <div className="col-12 pb-2">
                            <div className="form-row align-items-end">
                                <div className="col-md-12 col-lg-8 col-xl-4">
                                    <InputFieldWithValidation label={formMessages[FORM_LABEL_VIN]}
                                                              field={FORM_FIELD_VIN}
                                                              maxLength={17}
                                                              isDisabled={ !userRights.canEditBvm || !userRights.canEditVehDetails || readOnly || bvmLoaded || get(vehicleDetailsRawData, 'bvmLoaded', {default: false})}
                                                              isRequired={reqFields.includes(FORM_FIELD_VIN)}
                                                              validations={[validVinLength]}
                                                              isSmall/>
                                </div>
                                <label className="pl-2 pt-1">
                                    <Field
                                        name={FORM_CHECKBOX_BVM_CONNECTION}
                                        component="input"
                                        type="checkbox"
                                        disabled={!userRights.canEditBvm || !userRights.canEditVehDetails || readOnly}
                                        onChange={this.clearBvmFields}
                                    />{' '}
                                    <ValidFormattedMessage message={CHECKBOX_LABEL_BVM_CONNECTION}
                                                           intlMessages={formMessages}/>
                                </label>
                                {userRights.canEditBvm && !readOnly && userRights.canEditVehDetails &&
                                <>
                                    <div className="pl-2 pt-1">
                                        <PrimaryButton type="button" className="btn"
                                                       onClick={this.handleBvmLoad}
                                                       disabled={!bvmConnection || bvmLoaded}>
                                            <FormattedMessage {...buttonMessages.BVM_LOAD}/>
                                        </PrimaryButton>
                                    </div>
                                    <div className="pl-2 py-auto">
                                        {bvm.isBvmLoading &&
                                        <Loader isSmall/>
                                        }
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <Field name={FORM_FIELD_MANUFACTURE_DATE} component={DatePickerComponent}
                                   label={formMessages[FORM_LABEL_MANUFACTURE_DATE]}
                                   validate={reqFields.includes(FORM_FIELD_MANUFACTURE_DATE) ? [required] : undefined}
                                   isRequired={reqFields.includes(FORM_FIELD_MANUFACTURE_DATE)}
                                   isDisabled={!!bvmConnection || !userRights.canEditBvm || !userRights.canEditVehDetails || readOnly}
                                   parse={(e)=> e ? moment(e).format("YYYY-MM-DD") : null}
                            />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_VEHICLE_MODEL]}
                                                      field={FORM_FIELD_CAR_MODEL}
                                                      maxLength={30}
                                                      isRequired={reqFields.includes(FORM_FIELD_CAR_MODEL)}
                                                      isDisabled={!!bvmConnection || !userRights.canEditBvm || !userRights.canEditVehDetails || readOnly}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_REGISTRATION_NO]}
                                                      field={FORM_FIELD_REGISTRATION_NO}
                                                      maxLength={10}
                                                      isDisabled={!moduleRoles.isDealerAse(roles) || !userRights.canEditVehDetails || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_REGISTRATION_NO)}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_ENGINE_TYPE]}
                                                      field={FORM_FIELD_ENGINE_TYPE}
                                                      isDisabled={!!bvmConnection || !userRights.canEditBvm || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_ENGINE_TYPE)}
                                                      isSmall

                            />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_ENGINE_SERIAL_NUMBER]}
                                                      field={FORM_FIELD_ENGINE_SERIAL_NUMBER}
                                                      isDisabled={!!bvmConnection || !userRights.canEditBvm || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_ENGINE_SERIAL_NUMBER)}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_GEARBOX_TYPE]}
                                                      field={FORM_FIELD_GEARBOX_TYPE}
                                                      isDisabled={!!bvmConnection || !userRights.canEditBvm || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_GEARBOX_TYPE)}
                                                      isSmall
                            />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_GEARBOX_SERIAL_NUMBER]}
                                                      field={FORM_FIELD_GEARBOX_SERIAL_NUMBER}
                                                      isDisabled={!!bvmConnection || !userRights.canEditBvm || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_GEARBOX_SERIAL_NUMBER)}
                                                      isSmall
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 pr-0 pb-1">
                            <TextareaFieldWithValidation label={formMessages[FORM_LABEL_MORE_DETAILS]}
                                                         field={FORM_FIELD_MORE_DETAILS}
                                                         isDisabled={!userRights.canEditVehDetails || readOnly}
                                                         isRequired={reqFields.includes(FORM_FIELD_MORE_DETAILS)}
                                                         rows={6}/>
                        </div>
                    </div>
                </BorderRadius>
                <ConfirmationDialog show={this.state.isOpenManualRewriteDialog}
                                    closeModal={this.closeManualRewriteModal}
                                    saveModal={this.saveManualRewriteModal}
                                    actionIdentifier="rewrite the form by the data from BVM"/>
                <ConfirmationDialog show={this.state.isOpenBvmRewriteDialog}
                                    closeModal={this.closeBvmRewriteModal}
                                    saveModal={this.saveBvmRewriteModal}
                                    actionIdentifier="manually rewrite data loaded from BVM"/>
            </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    roles: state.profile.userDetail.roles,
    initialValues: getFormInitialValues(props.form)(state),
    vin: formValueSelector(props.form)(state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_VIN}`),
    bvmConnection: formValueSelector(props.form)(state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`),
    vehicleDetailsRawData: state.aseTicket.vehicleDetail,
    bvmLoaded: state.bvmData.bvmLoaded,
    bvm: state.bvmData,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            bvmDataSetActionCreator,
            fetchBvmInformationActionCreator,
            bvmDataResetActionCreator
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
    changeVehDetail: (name, value) => dispatch(change(props.form, FORM_VEHICLE_DETAIL_SECTION + "." + name, value)),
    clearFields: (...fields) => dispatch(clearFields(props.form, false, false, ...fields.map(e => FORM_VEHICLE_DETAIL_SECTION + "." + e))),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailSection);
