import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    REQUIRED: {
        id: 'validations.required',
        defaultMessage: 'Required'
    },
    MUST_BE_10_CHARS: {
        id: 'validations.mustBe10Characters',
        defaultMessage: 'Must be 10 characters'
    },
    MUST_BE_2_CHARS: {
        id: 'validations.mustBe2Characters',
        defaultMessage: 'Must be 2 characters'
    },
    MUST_BE_3_CHARS: {
        id: 'validations.mustBe3Characters',
        defaultMessage: 'Must be 3 characters'
    },
    MUST_BE_4_CHARS: {
        id: 'validations.mustBe4Characters',
        defaultMessage: 'Must be 4 characters'
    },
    MUST_BE_5_CHARS: {
        id: 'validations.mustBe5Characters',
        defaultMessage: 'Must be 5 characters'
    },
    MUST_BE_6_CHARS: {
        id: 'validations.mustBe6Characters',
        defaultMessage: 'Must be 6 characters'
    },
    MUST_BE_6_TO_10_CHARS: {
        id: 'validations.mustBe6To10Characters',
        defaultMessage: 'Must be 6 to 10 characters'
    },
    MUST_BE_5_TO_8_CHARS: {
        id: 'validations.mustBe5To8Characters',
        defaultMessage: 'Must be 5 to 8 characters'
    },
    MUST_BE_7_CHARS: {
        id: 'validations.mustBe7Characters',
        defaultMessage: 'Must be 7 characters'
    },
    MUST_BE_17_CHARS: {
        id: 'validations.mustBe17Characters',
        defaultMessage: 'Must be 17 characters'
    },
    INVALID_LENGTH: {
        id: 'validations.invalidLength',
        defaultMessage: 'Invalid length'
    },
    POSITIVE_NUMBER: {
        id: 'validations.positiveNumber',
        defaultMessage: 'Must be a positive number'
    },
    ALPHANUMERIC: {
        id: 'validations.alphanumeric',
        defaultMessage: 'Must be alphanumeric'
    },
    INVALID_IPN: {
        id: 'validations.invalidIpn',
        defaultMessage: 'Invalid IPN'
    },
    INVALID_INPUT: {
        id: 'validations.invalidInput',
        defaultMessage: 'Invalid input'
    },
    INVALID_DATE: {
        id: 'validations.invalidDate',
        defaultMessage: 'Invalid date dd.mm.yyyy'
    },
    DIGITS_ONLY: {
        id: 'validations.digitsOnly',
        defaultMessage: 'Digits only'
    },
    MAXIMUM_FILES: {
        id: 'validations.maximumFiles',
        defaultMessage: 'Maximum files exceeded'
    },
    MAXIMUM_FILE_SIZE: {
        id: 'validations.maximumFileSize',
        defaultMessage: 'Maximum file size exceeded'
    },
    INVALID_UNIQUE_NAME: {
        id: 'validations.invalidUniqueName',
        defaultMessage: 'Name already exists'
    },
    INTERNAL_TICKET_CREATION_ESCALATION: {
        id: 'validations.internalTicket.escalationSchemaRequired',
        defaultMessage: 'Solution group with valid escalation schema required for ticket creation!'
    },
});

export default messages;
