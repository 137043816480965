import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const fetchAseFullTextSearchPageData = (pageSize, sorted, cursor, isNextFromPivot, searchOption, filtered) => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_GET,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_GET,
        payload: {
            lastCommand: Date.now(),
            pageSize: pageSize,
            sorted: sorted,
            cursor,
            isNextFromPivot,
            searchOption,
            filtered: filtered
        },
    });
};

export const fetchAseLastEditor = () => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
        payload: {lastCommand: Date.now()},
    });
};
