import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    NONE: {
        id: 'ase.customerRisk.option.none',
        defaultMessage: 'None'
    },
    SAFETY: {
        id: 'ase.customerRisk.option.safety',
        defaultMessage: 'Safety'
    },
    QUALITY: {
        id: 'ase.customerRisk.option.quality',
        defaultMessage: 'Quality'
    }
});

export default messages;
