import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    CLIENT_IDS_FETCH_ERROR: {
        id: 'error.clientIds.fetch',
        defaultMessage: 'Cannot fetch Client IDs'
    },
    NO_DEALER_CODE: {
        id: 'error.noDealerCode',
        defaultMessage: 'You don\'t have a dealer code!'
    },
    DEALER_ROLE_SET: {
        id: 'error.dealerRoleSet',
        defaultMessage: 'Cannot grant editor access for this user! Reason: User has already dealer profile'
    },
    EDITOR_ROLE_SET: {
        id: 'error.editorRoleSet',
        defaultMessage: 'Cannot grant dealer access for this user! Reason: User has already editor profile'
    },
    DEALER_ROLE_NO_BIR: {
        id: 'error.dealerRoleNoBir',
        defaultMessage: 'Cannot grant dealer access for this user! Reason: User has no BIR number'
    },
    AS_TICKET_ERR002: {
        id: 'error.ase.ticket.updateFailed',
        defaultMessage: 'Ticket update failed. Ticket was not locked or someone else locked it during the time'
    },
    AS_TICKET_ERR003: {
        id: 'error.ase.ticket.ticketNotFound',
        defaultMessage: 'Ticket not found'
    },
    BVM_ERR001: {
        id: 'error.bvm.cannotLoadData',
        defaultMessage: 'Cannot load data from BVM'
    },
    AS_LIST_ERR001: {
        id: 'error.ase.list.loadListFailed',
        defaultMessage: 'Failed to load a list of After Sales Engineering tickets'
    },
    AS_HISTORY_ERR001: {
        id: 'error.ase.history.loadHistoryFailed',
        defaultMessage: 'Failed to load a history of After Sales Engineering tickets'
    },
    AS_REC_PROB_ERR001: {
        id: 'error.ase.recurrentProblems.limit10Reached',
        defaultMessage: 'Limit of 10 recurrent problems for country {country} has been reached'
    },
});

export default messages;
