import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {cloneDeep} from 'lodash';
import {FormattedMessage} from 'react-intl';
import {
    clearAdminRightsManagementPageData,
    fetchAdminRightsManagementPageData,
    saveAdminRightsManagementPageData,
    setAdminCountriesChanged,
    setInitialAdminCountryOptions,
    setManagedUser,
    setSelectedAdminCountryOptions
} from '../../../actions/admin/actionAdminRightsManagement';
import SelectUser from './SelectUser';
import Loader from '../../Loader';
import SelectAdminCountries from './SelectAdminCountries';
import {countryGroups} from '../../../constants/Utils';
import {clearAlerts} from '../../../actions/alertsActions';
import {handleFetchUsersError} from '../../../actions/actionRole';
import {handleUserSearch} from '../../../utils/userUtils';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import PageHeader from '../../common/PageHeader';
import ImportersTable from '../../common/ImportersTable';

class AdminRightsManagementPage extends Component {
    componentDidMount() {
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setAdminCountriesChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleCountrySelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedAdminCountryOptions(newOptions);
    };

    handleAllCountriesClick = () => {
        const selectedCountries = {};

        Object.keys(this.props.adminData || {}).forEach(
            countryIso => selectedCountries[countryIso] = true
        );

        const selectedOptions = this.setAdminDataAsCountryOptions(selectedCountries);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedCountries = {};
        Object.keys(countryGroups).forEach(
            groupKey => selectedCountries[countryGroups[groupKey]] = false
        );

        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({value}) => {
                    selectedCountries[value] = true;
                }
            )
        }

        this.props.saveAdminRightsManagementPageData(this.props.managedUser, this.props.domain, selectedCountries);
    };

    handleCancelClick = () => {
        this.props.setSelectedAdminCountryOptions(cloneDeep(this.props.initialOptions));
        this.props.setAdminCountriesChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearAdminRightsManagementPageData();
        this.props.fetchAdminRightsManagementPageData(user, this.props.domain);
    };

    setAdminDataAsCountryOptions = adminData => {
        const result = Object.keys(adminData || {}).map(countryIso => ({value: countryIso}));
        this.props.setSelectedAdminCountryOptions(cloneDeep(result));
        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setAdminCountriesChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(newOptions.sort((option1, option2) => option1.value.localeCompare(option2.value)))
    );

    render() {
        const {isLoading, managedUser, adminData, domain, selectedOptions} = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="admin.title.auth.mng.admin"
                                      defaultMessage="Authorization Management - {domain} - Administrator"
                                      values={{
                                          domain: <FormattedMessage {...formTypeMessages[domain]}/>
                                      }}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {
                    Object.keys(adminData).length > 0 && managedUser && managedUser.ipn &&
                    <SelectAdminCountries handleCountrySelectionChange={this.handleCountrySelectionChange}
                                          handleAllCountriesClick={this.handleAllCountriesClick}
                                          handleSaveClick={this.handleSaveClick}
                                          handleCancelClick={this.handleCancelClick}/>
                }
                {selectedOptions && selectedOptions.filter(country => ['IMP', 'IAG', 'IEU', 'IIN'].includes(country.value)).length > 0 &&
                    <div className="container-fluid">
                        <ImportersTable importerGroups={selectedOptions.filter(country => ['IMP', 'IAG', 'IEU', 'IIN'].includes(country.value)).map(country => country.value)}/>
                    </div>
                }
            </div>
        );
    }
}

AdminRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedAdminCountryOptions: PropTypes.func.isRequired,
    adminData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveAdminRightsManagementPageData: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setAdminCountriesChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearAdminRightsManagementPageData: PropTypes.func.isRequired,
    fetchAdminRightsManagementPageData: PropTypes.func.isRequired,
    setInitialAdminCountryOptions: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    adminData: state.adminRightsManagement.adminData,
    initialOptions: state.adminRightsManagement.initialAdminOptions,
    selectedOptions: state.adminRightsManagement.selectedAdminOptions
});

export default connect(mapStateToProps, {
    setManagedUser,
    fetchAdminRightsManagementPageData,
    setSelectedAdminCountryOptions,
    setAdminCountriesChanged,
    setInitialAdminCountryOptions,
    saveAdminRightsManagementPageData,
    clearAdminRightsManagementPageData,
    clearAlerts,
    handleFetchUsersError
})(AdminRightsManagementPage);
