import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const fetchInternalAseSearch = (pageSize, sorted, cursor, isNextFromPivot, searchOption, filtered) => (dispatch) => {
    dispatch({
        type: aseWebsocketCommands.ASE_INTERNAL_TICKET_SEARCH_GET,
        aseWebsocket: true,
        command: aseWebsocketCommands.ASE_INTERNAL_TICKET_SEARCH_GET,
        payload: {
            lastCommand: Date.now(),
            pageSize,
            sorted,
            cursor,
            isNextFromPivot,
            searchOption,
            filtered
        },
    });
};

// export const fetchTechDocLastEditor = () => (dispatch) => {
//     dispatch({
//         type: techDocWebsocketCommands.TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
//         techDocWebsocket: true,
//         command: techDocWebsocketCommands.TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
//         payload: {lastCommand: Date.now()},
//     });
// };
