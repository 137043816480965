import {
    CONNECT_ADMIN_WEBSOCKET_ERROR,
    CONNECT_ADMIN_WEBSOCKET_PENDING,
    CONNECT_ADMIN_WEBSOCKET_SUCCESS,
    CONNECT_BVM_WEBSOCKET_ERROR,
    CONNECT_BVM_WEBSOCKET_PENDING,
    CONNECT_BVM_WEBSOCKET_SUCCESS,
    CONNECT_ASE_WEBSOCKET_PENDING,
    CONNECT_ASE_WEBSOCKET_SUCCESS,
    CONNECT_ASE_WEBSOCKET_ERROR
} from '../actions/actionWebsocket';
import {cloneDeep} from 'lodash';
import {modules} from '../constants/Utils';

export const websocket = (state = {
    isConnected: {},
}, action) => {
    switch (action.type) {
        case CONNECT_ASE_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ASE] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ASE_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ASE] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ASE_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ASE] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ADMIN_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ADMINISTRATION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ADMIN_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ADMINISTRATION] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ADMIN_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ADMINISTRATION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_BVM_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.BVM] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_BVM_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.BVM] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_BVM_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.BVM] = false;
            return Object.assign({}, state, {isConnected});
        }
        default:
            return state;
    }
};
