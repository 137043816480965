import {countryGroups, domains, getFormTypes} from '../../constants/Utils';
import Joi from 'joi';
import get from 'get-value';

const validateCountry = roles => {
    let newRoles = roles;
    if (roles) {
        if (roles.ASE) {
            if (roles.ASE.admin) {
                if (roles.ASE.admin.groupPermission) {
                    Object.keys(countryGroups).map(e => Object.keys(roles.ASE.admin.groupPermission)
                        .filter(i => roles.ASE.admin.groupPermission[i] === true).includes(e) ?
                        newRoles.ASE.admin.groupPermission[countryGroups[e]] = true :
                        newRoles.ASE.admin.groupPermission[countryGroups[e]] = false)
                }
            }
            if (roles.ASE.editor) {
                if (roles.ASE.editor.groupPermission) {
                    Object.keys(countryGroups).map(e => Object.keys(roles.ASE.editor.groupPermission)
                        .filter(i => roles.ASE.editor.groupPermission[i] === true).includes(e) ?
                        newRoles.ASE.editor.groupPermission[countryGroups[e]] = true :
                        newRoles.ASE.editor.groupPermission[countryGroups[e]] = false)
                }
            }
        }
    }
    return newRoles;
}

const validateCountryRights = data => {
    let newData = data;
    Object.keys(countryGroups).map(e => Object.keys(data).filter(i => data[i] === true).includes(e) ?
        newData[countryGroups[e]] = true :
        newData[countryGroups[e]] = false)
    return newData;
}

export function validateUserDetail(userDetail) {
    if (userDetail) validateCountry(get(userDetail, 'roles', {}))
    return Joi.validate(
        userDetail,
        Joi.object().required().keys({
            ipn: Joi.string().required(),
            name: Joi.string().required(),
            roles: Joi.object().required().keys(getRolesSchema()),
            settings: Joi.object().keys(getSettingsSchema()),
            dealerName: Joi.any(),
            dealerNumber: Joi.any(),
            telNo: Joi.any(),
            group: Joi.any(),
            mail: Joi.string().required(),
            charSets: Joi.array().required()
        })
    )
}

export function validateAdminRoles(adminRoles) {
    if (adminRoles) validateCountryRights(adminRoles)
    return Joi.validate(
        adminRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateEditorData(editorRoles) {
    if (editorRoles) validateCountryRights(editorRoles)
    return Joi.validate(
        editorRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateSolutionGroupEditorData(solutionGroupEditorRoles) {
    return Joi.validate(
        solutionGroupEditorRoles,
        Joi.array()
    );
}

export function validateDealerRolesForAse(dealerRoles) {
    return Joi.validate(
        dealerRoles,
        Joi.object().required().keys(getAseFormsSchema())
    );
}

export const validateDealerRoles = (domain, dealerData) => {
    if (domain === domains.ASE) {
        return validateDealerRolesForAse(dealerData);
    }
    return {error: 'unknown domain'};
}

function getRolesSchema() {
    const result = {};
    result[domains.ASE] = Joi.object().keys({
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        dealer: Joi.object().keys({
            formsPermission: Joi.object().keys(getAseFormsSchema())
        }),
        editor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        sgAdmin: Joi.object().keys({
            sgPermission: Joi.array(),
        }),
        sgEditor: Joi.object().keys({
            sgPermission: Joi.array(),
        }),
        powerUser: Joi.object().max(0)
    })
    return result;
}

function getAseFormsSchema() {
    const result = {};
    const formTypes = getFormTypes(domains.ASE);
    formTypes.forEach(formType => result[formType] = Joi.boolean().required());
    return result;
}

function getCountryGroupsSchema() {
    const result = {};
    Object.keys(countryGroups).forEach(countryGroup => result[countryGroups[countryGroup]] = Joi.boolean().required());
    return result;
}

export function getSettingsSchema() {
    return {
        language: Joi.string(),
        locale: Joi.string(),
    };
}
