export const DEFAULT_DEBOUNCE_TIMEOUT = 500;
export const DEFAULT_FIELD_MAX_LENGTH = 50;
export const CLIENT_ID_FIELD_MAX_LENGTH = 6;
export const LIC_PROD_FIELD_MAX_LENGTH = 3;
export const LIC_ORD_FIELD_MAX_LENGTH = 3;
export const LIC_OAN_FIELD_MAX_LENGTH = 2;
export const LIC_LIG_FIELD_MAX_LENGTH = 4;
export const LIC_IAN_FIELD_MAX_LENGTH = 2;
export const VIN_FIELD_LENGTH = 17;
export const BIN_FIELD_LENGTH = 17;
export const FILE_MAX_SIZE = 10485760;
export const NOT_SELECTED_OPTION = '---';
export const DEFAULT_NOTE_FIELD_MAX_LENGTH = 3000;
export const IPN_MAX_LENGTH = 7;
export const IPN_VALIDATION_REGEX = /^[a-zA-Z]{1}([a-zA-Z]|\d){5}\d{1}$/;
export const DATE_VALIDATION_REGEX = /(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.([12]\d{3})/;
export const DIGIT_STRING_VALIDATION_REGEX = /^[0-9]*$/;
export const ALPHANUMERIC_VALIDATION_REGEX = /^[a-zA-Z0-9]*$/;
// export const COMMON_INPUT_VALIDATION_REGEX = /^[^'&}{\]["$`]*$/;
export const COMMON_INPUT_VALIDATION_REGEX = /^[^¦]*$/;

