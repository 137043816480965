import {
    ADMIN_TICKET_SETTINGS_FETCH_PENDING,
    ADMIN_TICKET_SETTINGS_SELECT_GROUP,
    ADMIN_TICKET_SETTINGS_UPDATE_PENDING
} from '../../actions/admin/actionAdminTicketSettings';
import get from 'get-value';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';

export const adminTicketSettings = (state = {
    isLoading: true,
    ticketSettings: {},
    selectedCountryGroup: ''
}, action) => {
    switch (action.type) {
        case ADMIN_TICKET_SETTINGS_SELECT_GROUP:
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        case ADMIN_TICKET_SETTINGS_FETCH_PENDING:
            return Object.assign({}, state, {isLoading: true, ticketSettings: {}});
        case ADMIN_TICKET_SETTINGS_UPDATE_PENDING:
            return Object.assign({}, state, {isLoading: true, ticketSettings: {}});
        case aseWebsocketCommands.ASE_TICKET_SETTINGS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                ticketSettings: get(action, 'payload.settings', {default: {}}),
                correlationId
            });
        }
        case aseWebsocketCommands.ASE_TICKET_SETTINGS_ERROR:
        case aseWebsocketCommands.ASE_TICKET_SETTINGS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, ticketSettings: {}, correlationId});
        }
        default:
            return state;
    }
};

