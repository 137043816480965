import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'ase.fulltextSearch.title',
        defaultMessage: 'Fulltext Search'
    },
    VISIBLE_COLUMNS: {
        id: 'ase.fulltextSearch.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    TABLE_TICKET_NUMBER: {
        id: 'ase.fulltextSearch.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_ORDER_CREATION_TIME: {
        id: 'ase.fulltextSearch.table.creationDate',
        defaultMessage: 'Creation Date'
    },
    TABLE_CLIENT_ID: {
        id: 'ase.fulltextSearch.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_DEALER_NUMBER: {
        id: 'ase.fulltextSearch.table.dealerNumber',
        defaultMessage: 'Dealer Number'
    },
    TABLE_ISSUE_ORIGIN: {
        id: 'ase.fulltextSearch.table.issueOrigin',
        defaultMessage: 'Issue Origin'
    },
    TABLE_LAST_STATUS_DATE: {
        id: 'ase.fulltextSearch.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TABLE_REG_DIR: {
        id: 'ase.fulltextSearch.table.regDir',
        defaultMessage: 'Reg. Dir.'
    },
    TABLE_STATUS: {
        id: 'ase.fulltextSearch.table.status',
        defaultMessage: 'Ticket Status'
    },
    TABLE_SOLUTION_GROUP: {
        id: 'ase.fulltextSearch.table.solutionGroup',
        defaultMessage: 'Solution Gr.'
    },
    TABLE_LAST_EDITOR: {
        id: 'ase.fulltextSearch.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TABLE_CREATED_BY: {
        id: 'ase.fulltextSearch.table.createdBy',
        defaultMessage: 'Created by (IPN)'
    },
    TICKET_SECTION: {
        id: 'ase.fulltextSearch.header.ticketInformation',
        defaultMessage: 'Ticket information'
    },
    DEALER_SECTION: {
        id: 'ase.fulltextSearch.header.dealerInformation',
        defaultMessage: 'Dealer Information'
    },
    DEALER_NAME: {
        id: 'ase.fulltextSearch.dealerName',
        defaultMessage: 'Dealer Name'
    },
    DEALER_NUMBER: {
        id: 'ase.fulltextSearch.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    NAME: {
        id: 'ase.fulltextSearch.name',
        defaultMessage: 'Creator Name'
    },
    CLIENT_ID: {
        id: 'ase.fulltextSearch.clientId',
        defaultMessage: 'Client ID'
    },
    REGION: {
        id: 'ase.fulltextSearch.region',
        defaultMessage: 'Region'
    },
    TICKET_NUMBER: {
        id: 'ase.fulltextSearch.ticketNumber',
        defaultMessage: 'Ticket number'
    },
    FORM_TYPE: {
        id: 'ase.fulltextSearch.formType',
        defaultMessage: 'Type of Form'
    },
    SOLUTION_GROUP: {
        id: 'ase.fulltextSearch.solutionGroup',
        defaultMessage: 'Solution Gr.'
    },
    STATUS: {
        id: 'ase.fulltextSearch.status',
        defaultMessage: 'Ticket Status'
    },
    FROM: {
        id: 'ase.fulltextSearch.from',
        defaultMessage: 'Date From'
    },
    TO: {
        id: 'ase.fulltextSearch.to',
        defaultMessage: 'Date To'
    },
    EDITOR_IPN: {
        id: 'ase.fulltextSearch.lastEditor',
        defaultMessage: 'Last Editor'
    },
    DOCUMENTATION_UPDATE_SECTION: {
        id: 'ase.fulltextSearch.header.documentationUpdateSection',
        defaultMessage: 'Documentation Update'
    },
    DOC_UPDATE_NEEDED: {
        id: 'ase.fulltextSearch.docUpdateNeeded',
        defaultMessage: 'Documentation update needed?'
    },
    ROOT_CAUSE: {
        id: 'ase.fulltextSearch.rootCause',
        defaultMessage: 'Root Cause'
    },
    ESTIMATED_DATE_OF_MODIFICATION: {
        id: 'ase.fulltextSearch.estimatedDateOfModification',
        defaultMessage: 'Estimated Date Of Modification'
    },
    LUP_QS_ASSOCIATED: {
        id: 'ase.fulltextSearch.lupQsAssociated',
        defaultMessage: 'Client risk'
    },
    VEHICLE_DETAIL_SECTION: {
        id: 'ase.fulltextSearch.header.vehicleDetail',
        defaultMessage: 'Vehicle Details'
    },
    MANUFACTURE_DATE: {
        id: 'ase.fulltextSearch.manufactureDate',
        defaultMessage: 'Manufacturing Date'
    },
    CAR_MODEL: {
        id: 'ase.fulltextSearch.carModel',
        defaultMessage: 'Car Model'
    },
    VEHICLE_MODEL: {
        id: 'ase.fulltextSearch.vehicleModel',
        defaultMessage: 'Car Model'
    },
    VIN: {
        id: 'ase.fulltextSearch.vin',
        defaultMessage: 'VIN'
    },
    REGISTRATION_NUMBER: {
        id: 'ase.fulltextSearch.registrationNumber',
        defaultMessage: 'Registration number'
    },
    ENGINE_TYPE: {
        id: 'ase.fulltextSearch.engineTypeAndSuffix',
        defaultMessage: 'Engine Type & Suffix'
    },
    ENGINE_SERIAL_NUMBER: {
        id: 'ase.fulltextSearch.engineSerialNumber',
        defaultMessage: 'Engine serial number'
    },
    GEARBOX_TYPE: {
        id: 'ase.fulltextSearch.gearboxType',
        defaultMessage: 'Gearbox Type & Suffix'
    },
    GEARBOX_SERIAL_NUMBER: {
        id: 'ase.fulltextSearch.gearboxSerialNumber',
        defaultMessage: 'Gearbox serial number'
    },
    SEARCH_OPTION_SECTION: {
        id: 'ase.fulltextSearch.header.searchOption',
        defaultMessage: 'Searching option'
    },
    FULLTEXT_SEARCH: {
        id: 'ase.fulltextSearch.fullText',
        defaultMessage: 'Fulltext'
    },
    INTERNATIONAL: {
        id: 'ase.fulltextSearch.international',
        defaultMessage: 'International Search'
    },
    PROBLEM_DESCRIPTION_SECTION: {
        id: 'ase.fulltextSearch.header.problemDescriptionSection',
        defaultMessage: 'Problem Description'
    },
    ISSUE_ORIGIN: {
        id: 'ase.fulltextSearch.issueOrigin',
        defaultMessage: 'Issue origin'
    },
    PART_REFERENCE_ASSOCIATED: {
        id: 'ase.fulltextSearch.partReferenceAssociatedToTheIssue',
        defaultMessage: 'Part reference associated to the issue'
    },
    FIC_CIR_ASSOCIATED: {
        id: 'ase.fulltextSearch.ficCirAssociated',
        defaultMessage: 'FIC/CIR associated'
    },
    NOD_ID: {
        id: 'ase.fulltextSearch.nodId',
        defaultMessage: 'Nod ID'
    },
    DOCUMENT_ID: {
        id: 'ase.fulltextSearch.repairMethodOrLaborTime',
        defaultMessage: 'Repair Method/Labor time number'
    },
});

export default messages;
