import { errorAlert } from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';
import {domains} from '../../constants/Utils';
import {
    validateEscalationSchemaCreateEvent,
    validateEscalationSchemaUpdateEvent
} from '../../api/validation/escalationSchema';
import {aseWebsocketCommands} from '../../constants/aseWebsocketCommands';


export const updateEscalationSchema = (domain, data) => dispatch => {
    const { error } = validateEscalationSchemaUpdateEvent(data);
    if (error) {
        switch (domain) {
            case domains.ASE:
                dispatch(errorAlert(alertMessages.ESCALATION_SCHEMA_UPDATE_SEND_ERROR, [error.toString()]));
                dispatch({type: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_SAVE_UPDATE_ERROR});
                break;

        }
    } else {
        switch (domain) {
            case domains.ASE: {
                return dispatch({
                    type: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_UPDATE_PENDING,
                    aseWebsocket: true,
                    command: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_UPDATE,
                    payload: {
                        payload: {
                            ...data
                        }
                    }
                });
            }
        }
    }
};

export const saveEscalationSchema = (domain, data) => dispatch => {
    let shouldContinue = true;
    data.forEach(item => {
        const {error} = validateEscalationSchemaCreateEvent(item);
        if (error && domain === domains.ASE) {
            dispatch(errorAlert(alertMessages.ESCALATION_SCHEMA_NEW_SEND_ERROR, [error.toString()]));
            dispatch({type: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_SAVE_NEW_ERROR});
            shouldContinue = false;
        }
    });
    if (shouldContinue && domain === domains.ASE) {
        data.forEach(item => {
            dispatch({
                type: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_CREATE_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_CREATE,
                payload: {
                    payload: {
                        ...item,
                    }
                }
            })
        });
    }

};

export const fetchEscalationSchema = (domain, id) => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_DETAIL_FETCH_PENDING,
                aseWebsocket: true,
                command: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_DETAIL,
                payload: {
                    escalationSchema: {
                        id
                    },
                }
            });
        }
    }
};

export const resetEscalationSchema = domain => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_RESET,
            });
        }
    }
};

export const redirectReset = domain => dispatch => {
    switch (domain) {
        case domains.ASE: {
            return dispatch({
                type: aseWebsocketCommands.ASE_ESCALATION_SCHEMA_REDIRECT_RESET,
            });
        }
    }
};
