import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {clearAlerts} from '../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../Loader';
import {
    ASE_TICKET_STATUS_CLOSED,
    FORM_BUTTON_EDIT,
    FORM_BUTTON_REOPEN,
    FORM_BUTTON_SG_TAKE_BACK,
    MR_TM_ASE_FORM,
} from '../../../constants/formConstants';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {moduleRoles} from '../../../utils/roles';
import {formatTicketNumber} from '../../../utils/utils';
import {editRights} from '../../../constants/aseTicketRights';
import {
    fetchAseTicket,
    resetAseTicket,
    updateAseTicket
} from '../../../actions/ase/actionAseTicket';
import {initLoadedAseTicket, transformUpdateAseTicketForSave} from '../Utils';
import formMessages from '../../../intl/afterSales/formMessages';
import {lockAseTicket, resetRedirectAfterAseLockTicket} from '../../../actions/ase/actionAseLockTicket';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import checkRoles from '../../common/roleChecker/RoleChecker';
import MethodRepairLaborTimeMasterForm from './forms/MethodRepairLaborTimeMasterForm';

class ViewAseTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToEdit: false,
            ticketSubmitted: false,
            storedTicketData: {},
            fetchedNewTicketData: false,
            redirectToEditImmediately: false
        };
    }

    componentDidMount() {
        if (!this.props.location.redirectedAfterUpdate) {
            this.setState({...this.state, fetchedNewTicketData: true});
            this.props.fetchAseTicket(this.props.match.params.ticketUuid);
        }
    }

    componentWillUnmount() {
        this.props.resetAseTicket();
        this.props.resetRedirectAfterAseLockTicket();
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const {ticketData} = this.props;

        if (submitBy === FORM_BUTTON_REOPEN) {
            this.props.lockAseTicket(this.props.match.params.ticketUuid);
            this.setState({redirectToEdit: true, ticketSubmitted: true, storedTicketData: transformUpdateAseTicketForSave(values)});
        }
        if (submitBy === FORM_BUTTON_EDIT) {
            this.setState({redirectToEditImmediately: true, redirectToTicket: ticketData.ticket.uuid});
            this.props.resetAseTicket();
        }
        if (submitBy === FORM_BUTTON_SG_TAKE_BACK) {
            this.props.lockAseTicket(this.props.match.params.ticketUuid);
            this.setState({redirectToEdit: true, ticketSubmitted: true, storedTicketData: transformUpdateAseTicketForSave(values)});
        }

    };

    render() {
        const {
            ticketData,
            loggedUser,
        } = this.props;

        let formType = false;
        if (get(ticketData, 'ticket.formType', {default: false})) {
            formType = ticketData.ticket.formType;
        }

        if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted && this.state.redirectToEdit) {
            this.props.updateAseTicket(this.state.storedTicketData);
            return <Redirect to={{
                pathname: `/ase/edit/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        } else if (this.state.redirectToEditImmediately) {
            return <Redirect to={{
                pathname: `/ase/edit/${this.state.redirectToTicket}`,
            }}/>;
        } else if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted) {
            this.props.updateAseTicket(this.state.storedTicketData);
            this.setState({ticketSubmitted: false, storedTicketData: {}});
        }

        const initializationValue = initLoadedAseTicket(ticketData);
        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !formType
        ) {
            return <Loader/>;
        }

        if ( this.props.location.redirectedFromInternal && !ticketData.isTicketLoading && this.state.fetchedNewTicketData
            && get(ticketData, 'solutionGroupInfo.canForwardToDealer', false) && ticketData.status !== ASE_TICKET_STATUS_CLOSED) {
            return <Redirect to={`/ase/edit/${ticketData.ticket.uuid}`}/>;
        }



        const isEditor = moduleRoles.isEditorAse(loggedUser) || moduleRoles.isSGEditorAse(loggedUser);
        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === MR_TM_ASE_FORM &&
                    <MethodRepairLaborTimeMasterForm form={formType}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  userRoles={this.props.loggedUser}
                                                  enableReinitialize={true}
                                                  handleSubmit={this.handleSubmit}
                                                  reqFields={[]}
                                                  readOnly/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.aseTicket,
    lockTicket: state.aseLockTicketCheck,
});

ViewAseTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchAseTicket: PropTypes.func.isRequired,
    resetAseTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchAseTicket,
    resetAseTicket,
    lockAseTicket,
    updateAseTicket,
    resetRedirectAfterAseLockTicket,
    clearAlerts,
})(injectIntl(ViewAseTicketPage))), ['AS_DEALER', 'AS_EDITOR', 'AS_SG_EDITOR']);

