import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    DEFAULT: {
        id: 'ase.internalTicketRequestType.default',
        defaultMessage: 'Default internal type'
    },
});

export default messages;
